import { REQUIRED_ERROR } from "utils/errorMessages";

export const dietsFields = [
  // {
  //   props: {
  //     label: "Common Diet Name",
  //     id: "dietId",
  //     name: "dietId",
  //   },
  //   validationProps: {
  //     required: REQUIRED_ERROR("Common Diet Name"),
  //   },
  //   type: FORM_TYPES.SelectAPI,
  //   endpoint: "availableFacilityDietSelectList",
  //   size: 7,
  // },

  {
    props: {
      name: "dietName",
      id: "dietName",
      label: "Diet Name",
      placeholder: "Diet Name ",
    },
    validationProps: {
      required: REQUIRED_ERROR("Diet Name"),
    },
    size: 7,
  },
  {
    props: {
      name: "sortOrder",
      id: "sortOrder",
      label: "Sort Order",
      placeholder: "Sort Order",
    },
    validationProps: {
      required: REQUIRED_ERROR("Sort Order"),
      maxLength: {
        value: 3,
        message: "please use 3 char or less",
      },
    },

    size: 7,
  },
];
