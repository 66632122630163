import React, { useEffect } from "react";
import {
  useGetCorporateTagByIdQuery,
  useAddCorporateTagMutation,
  useUpdateCorporateTagMutation,
} from "../../../../../../../store/apis/CorporateGroupTagApis";
import { useGetTagsSelectListQuery } from "../../../../../../../store/apis/TagsApi";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import Grid from "@mui/material/Grid2";
import { Box, Button, Divider, Typography } from "@mui/material";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";
import BaseInput from "../../../../../../shared/baseForm/BaseInput";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { ICONS_LIST } from "./addTag.constants";

export default function AddCorporateTag() {
  const [addTag, { isError: addError, isLoading: addLoading }] =
    useAddCorporateTagMutation();

  const [updateTag, { isError: updateError, isLoading: updateLoading }] =
    useUpdateCorporateTagMutation();

  const isError = addError || updateError;
  const isSubmitting = addLoading || updateLoading;
  const [searchParams] = useSearchParams();

  const corporateGroupId = searchParams.get("corporateGroupId");
  const corporateGroupTagId = searchParams.get("id");

  const isEdit = !!corporateGroupTagId;

  const {
    data: corporateTagData,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetCorporateTagByIdQuery(corporateGroupTagId, {
    skip: !isEdit,
  });

  const isEditLoading = isEdit && currentFetching;

  const iconList = ICONS_LIST;

  const { data: tagList, isLoading: loadingTags } =
    useGetTagsSelectListQuery(1);

  const navigate = useNavigate();
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (currentSuccess && !currentFetching && corporateTagData) {
      reset(corporateTagData);
    }
  }, [corporateTagData]);

  const onSubmit = (data) => {
    if (isEdit) {
      updateTag(data);
    } else {
      addTag({
        ...data,
        corporateGroupId: corporateGroupId,
      });
    }
    if (!isError) {
      navigate(-1);
    }
  };

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <div className="leftActions">
            <Button
              component={Link}
              color={"primary"}
              className="baseButton"
              variant="contained"
              onClick={() => navigate(-1)}
              startIcon={<KeyboardArrowLeft />}
            >
              {"Back to Corporate Tag List"}
            </Button>
          </div>
          <Divider sx={{ marginBottom: "15px", border: "none" }} />
          <FormProvider {...methods}>
            <form>
              <Grid container spacing={2}>
                <Grid size={5}>
                  <BaseSelect
                    label="Choose Tag"
                    name="tagId"
                    id="tagId"
                    options={tagList || []}
                    loading={loadingTags}
                    validationProps={{
                      required: REQUIRED_ERROR("Tag"),
                    }}
                  />
                </Grid>
                <Grid size={8} />

                <Grid size={5}>
                  <BaseSelect
                    label="Choose Icon"
                    name="iconUrl"
                    id="iconUrl"
                    options={iconList || []}
                    validationProps={{
                      required: REQUIRED_ERROR("IconURL"),
                    }}
                  />
                </Grid>
                <Grid size={8} />

                <Grid size={12}>
                  <BaseInput
                    label={"Description"}
                    name={"iconDescription"}
                    placeholder="Description"
                    validationProps={{
                      required: REQUIRED_ERROR("Description"),
                    }}
                  />
                </Grid>

                <Grid size={5}>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <BaseSubmitButton
                      fullWidth
                      onClick={handleSubmit(onSubmit)}
                      isSubmitting={isSubmitting}
                      text={isEdit ? "Submit" : "Create"}
                    />
                  </Box>
                  {isError ? (
                    <Typography
                      sx={{
                        color: "error",
                      }}
                    >
                      There was an error submitting the form, please try again.
                    </Typography>
                  ) : undefined}
                </Grid>
                <Grid size={8} />
              </Grid>
            </form>
          </FormProvider>
        </>
      )}{" "}
    </>
  );
}
