import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useAddcopyToThickenedLiquidMutation,
  useGetFacilityThickenedLiquidQuery,
  useGetFacilityByIdQuery,
} from "../../../../../../store/apis/FacilityApis";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { EditButton } from "components/shared/EditButton";
import { BaseButton } from "components/shared/BaseButton";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { CopyAll } from "@mui/icons-material";

export default function CommunityLiquidConsistency() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [openCopyModal, setOpenCopyModal] = useState(false);

  const { data: community, isSuccess: communitySuccess } =
    useGetFacilityByIdQuery(id, { skip: !id });

  const {
    data: liquidConsistency,
    isError: hasError,
    isLoading: loading,
  } = useGetFacilityThickenedLiquidQuery(id, { skip: !id });

  const liquidColumns = [
    {
      field: "thickenedLiquidName",
      headerName: "Liquid Consistency Name",
      flex: 1,
    },
  ];

  return (
    <>
      <FlexBox justifyContent={"end"} my={"8px"}>
        <EditButton
          data={liquidConsistency}
          To={`edit?id=${id}`}
          // styles={{ bg: "#575757", color: "white" }}
        />
        <BaseButton
          text={"Copy to Corporate Group"}
          endIcon={<CopyAll />}
          onClick={() => setOpenCopyModal(true)}
          colors="error"
        />
      </FlexBox>

      {openCopyModal && (
        <CopyToCorporateGroupModal
          open={openCopyModal}
          close={() => setOpenCopyModal(false)}
          cgName={communitySuccess && community?.corporateGroup}
          facilityName={communitySuccess && community?.name}
          id={id}
        />
      )}

      <BaseDataGrid
        rows={liquidConsistency}
        columns={liquidColumns}
        error={hasError}
        loading={loading}
      />
    </>
  );
}

function CopyToCorporateGroupModal({ facilityName, id, open, close }) {
  const [startCopy, { isLoading, isSuccess }] =
    useAddcopyToThickenedLiquidMutation();

  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [isSuccess]);

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`copy-to-corporate-group-modal-title-${id}`}
      aria-describedby={`copy-to-corporate-group-modal-description-${id}`}
    >
      <Fade in={open}>
        <Box className={"deleteModal"}>
          <Box>
            <Typography
              id={`copy-to-corporate-group-modal-title-${id}`}
              variant="h6"
              component="h2"
              className="modalTitle"
              sx={{
                textAlign: "center",
              }}
            >
              {`Liquid Consistency for  "${facilityName}"`}
            </Typography>
            <Typography
              id={`copy-to-corporate-group-modal-description-${id}`}
              sx={{
                textAlign: "center",
                mt: 2,
              }}
            >
              {`This will remove the Liquid Consistency for all Communities that are linked to the 'Individual' Corporate Group, and replace it with the list of Liquid Consistency that is currently setup for this Community. Are you sure you want to do this?`}
            </Typography>
          </Box>

          <FlexBox padding={2}>
            <Button
              variant="contained"
              onClick={() => {
                startCopy({ facilityId: id });
              }}
              startIcon={
                isLoading ? <CircularProgress color="white" size={18} /> : ""
              }
              color={"warning"}
            >
              Yes
            </Button>
            <Button variant="contained" onClick={close} color={"error"}>
              No
            </Button>
          </FlexBox>
        </Box>
      </Fade>
    </Modal>
  );
}
