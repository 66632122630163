import React from "react";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import MealObservationAccordionDetails from "./MealObservationAccordionDetails";
import { useFieldArray, useFormContext } from "react-hook-form";

export default function MealObservationAccordion({
  section,
  sectionIndex,
  visitReportTypeId,
}) {
  const { control } = useFormContext();
  const { fields: configs } = useFieldArray({
    control: control,
    name: `sections.${sectionIndex}.items`,
  });

  return (
    <>
      {/* {data?.map((section, index) => ( */}
      <Box
        key={section.id}
        className={
          visitReportTypeId === 1 || visitReportTypeId === 6
            ? "consultDataBox"
            : "dataBox"
        }
      >
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={
              <ExpandMore
                sx={{ color: "white !important", fontSize: "2rem" }}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{
              background: "#707070",
              padding: "2rem",
            }}
            className="itemBox"
          >
            <Typography
              component="h2"
              sx={{
                marginY: "10px",
                fontWeight: "900",
                color: "white !important",
                fontSize: "1.2rem",
              }}
            >
              {section.section}
            </Typography>
          </AccordionSummary>

          {/* Accordion Details to hold section items */}
          <AccordionDetails sx={{ padding: "1rem 0px" }}>
            {configs.map((config, index) => (
              <MealObservationAccordionDetails
                config={config}
                key={config.id}
                sectionIndex={sectionIndex}
                configIndex={index}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
      {/* ))} */}
    </>
  );
}
