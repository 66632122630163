import { Box, Grid2 } from "@mui/material";
import HeaderBox from "components/consultant/shared/HeaderBox";
import { BackButton } from "components/shared/BackButton";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PCPFAX_FIELDS, pcpFax_ScreenNames } from "./constants";
import { BaseButton } from "components/shared/BaseButton";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { FormProvider, useForm } from "react-hook-form";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { useCreateMDFAXMutation } from "store/apis/NutritionalCareAPIs";
import {
  useGetFacilityByIdQuery,
  useGetVisitDetailsQuery,
} from "store/apis/ConsultantApis";
// import { DownloadLinkHelper } from "components/shared/utils/helpers";

const PcpFax = () => {
  // local States
  const [queries, setQueries] = useState([
    {
      name: "doctorId",
      skip: true,
    },
  ]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const visitPlanId = searchParams.get("visitPlanId");
  const reportId = searchParams.get("id");
  const visitReportId = searchParams.get("visitReportId");
  // const [shouldDownload, setShouldDownload] = useState(false);

  const { data: visitData } = useGetVisitDetailsQuery(visitPlanId);
  const { facilityId } = visitData || [];

  // useForm Hook
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  // destructuring of Method
  const { handleSubmit } = methods;

  // APi Section
  const [createFax, { isLoading, isSuccess }] = useCreateMDFAXMutation();
  const {
    data: currentFacility,
    // isFetching: loadingFacility,
    // isSuccess: currentFacilitySuccess,
    // isError: currentFacilityError,
  } = useGetFacilityByIdQuery(facilityId, {
    skip: !facilityId,
  });
  // OnSubmit Section
  const onSubmit = (data) => {
    const dataSent = {
      reportId: reportId,
      visitReportId: visitReportId,
      facilityId: facilityId,
      office: "",
      doctor: "",
      toFAXNumber: "",
      saveFAXReport: true,

      ...data,
    };
    createFax(dataSent);
  };

  // UseEffect Section
  // useEffect(() => {
  //   if (isSuccess && shouldDownload) {
  //     DownloadLinkHelper(data);
  //     reset({});
  //     setShouldDownload(false);
  //   }
  // }, [shouldDownload, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  useEffect(() => {
    setQueries([
      ...queries.filter((x) => x.name != "doctorId"),
      {
        name: "doctorId",
        query: facilityId,
        skip: !facilityId,
      },
    ]);
  }, []);

  return (
    <>
      <Box
        sx={{
          padding: "8px",
        }}
      >
        <BackButton To={-1} iconDisabled={true} sx={{ marginBottom: "10px" }} />
        <HeaderBox
          visitPlanId={visitPlanId}
          ScreensArray={pcpFax_ScreenNames}
        />
        <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BaseButton
            marginLeft={"0px !important"}
            To={`/Consultant/MyRosters/VisitDetails/FacilityDoctors?visitPlanId=${visitPlanId}&visitReportId=${visitReportId}&id=${reportId}`}
            text={"Manage Doctor"}
          />
          <Box>
            <BaseSubmitButton
              // sx={{ marginRight: "16px" }}
              isSubmitting={isLoading}
              onClick={handleSubmit(onSubmit)}
              text="Save Fax"
            />
            {/*<BaseSubmitButton*/}
            {/*  isSubmitting={isLoading}*/}
            {/*  onClick={() => setShouldDownload(true)}*/}
            {/*  text="Download Fax"*/}
            {/*/>*/}
          </Box>
        </Box>
        <FormProvider {...methods}>
          <Grid2
            container
            spacing={2}
            sx={{
              marginTop: "10px",
              width: "50%",
            }}
          >
            <GeneratedForm
              oldGrid={false}
              list={PCPFAX_FIELDS}
              queries={queries}
              defaultValues={[
                { name: "fromFAXNumber", value: currentFacility?.faxnumber },
              ]}
            />
          </Grid2>
        </FormProvider>
      </Box>
    </>
  );
};

export default PcpFax;
