import { Box, Grid2, Typography } from "@mui/material";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { isEqual, isNull, isUndefined, toString } from "lodash";
import React, { useEffect, useState } from "react";
import { TextFields } from "../consultantDetails/consultantDetails/formConstants";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  useAddLogRecordMutation,
  useGetLoggedRecordQuery,
} from "store/apis/ConsultantApis";
import { useSearchParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { setErrorsForProperties } from "components/shared/utils/helpers";
import BaseActionBox from "components/shared/BaseActionBox";
// import { DMStyles } from "styles/Styles";
import CreateBaseColumns from "components/shared/CreateBaseColumn";
import { useFacilityConsultingTypesQuery } from "store/apis/FacilityApis";

export const LogHoursForm = ({ dateOfVisit, setIsLogsChange }) => {
  const [addLogHiddenList, setAddLogHiddenList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState();
  const [queries, setQueries] = useState([
    {
      name: "consultingType",
      query: {},
      skip: true,
    },
  ]);
  const [searchParams] = useSearchParams();
  const VISIT_PLAN_ID_QUERY = "visitPlanId";
  const currentPlanId = searchParams.get(VISIT_PLAN_ID_QUERY);
  const visitFacilityId = useSelector(
    (state) => state.facilityId.visitFacilityId
  );

  //   UseForm
  const addLogMethods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  // destructing of useform
  const {
    trigger: addLogTrigger,
    handleSubmit: addLogHandleSubmit,
    reset: addLogReset,
    watch: addLogWatch,
    setError: setAddError,
    formState: { isDirty },
  } = addLogMethods;

  // veriables
  const consultingTypeId = addLogWatch("consultingType");
  const watchTimeIn = addLogWatch("timeIn");
  const watchTimeOut = addLogWatch("timeOut");

  // Get Api section

  const {
    data: loggedData,
    isLoading,
    isError,
    isSuccess: isLoggedSuccess,
  } = useGetLoggedRecordQuery(currentPlanId, {
    refetchOnMountOrArgChange: true,
  });

  const { data: consultingTypesData } = useFacilityConsultingTypesQuery(
    visitFacilityId?.facilityId,
    { skip: !visitFacilityId?.facilityId }
  );

  const rateTypeId = consultingTypesData?.find(
    (data) => data.consultingTypeId === consultingTypeId
  )?.rateTypeId;

  // Mutation
  // Api to add log record into list
  const [
    addLog,
    { isLoading: isAddLogLoading, isSuccess: isAddSuccess, error: addError },
  ] = useAddLogRecordMutation();

  const isGetError = isError && !isLoggedSuccess;

  //   useEffect Section
  useEffect(() => {
    setIsLogsChange(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (watchTimeIn && watchTimeOut) {
      addLogTrigger("timeIn");
      addLogTrigger("timeOut");
    }
  }, [watchTimeIn, watchTimeOut]);

  useEffect(() => {
    isEqual(rateTypeId, 1)
      ? setAddLogHiddenList([
          { name: "timeIn" },
          { name: "timeOut" },
          { name: "greaterThanTenOffset" },
          { name: "greaterThanTen" },
        ])
      : dayjs(watchTimeOut).diff(dayjs(watchTimeIn), "minutes") > 600
      ? setAddLogHiddenList([{ name: "mileage" }])
      : setAddLogHiddenList([
          { name: "greaterThanTenOffset" },
          { name: "greaterThanTen" },
          { name: "mileage" },
        ]);
  }, [rateTypeId, edit, editId, watchTimeIn, watchTimeOut]);

  useEffect(() => {
    if (edit) {
      const [data] = loggedData.filter((data) => data.id === editId);
      if (data) {
        const newObject = {
          ...data,
          mileage: data.quantity,
          selectedConsultingTypeRateType: toString(rateTypeId),
          consultingType: data.consultingTypeId,
          timeIn:
            data.timeIn === " "
              ? dayjs("11:00 PM", "hh:mm A").format("HH:mm")
              : dayjs(data.timeIn, "h:mm A").format("HH:mm"),
          timeOut:
            data.timeOut === " "
              ? dayjs("12:00 PM", "hh:mm A").format("HH:mm")
              : dayjs(data.timeOut, "h:mm A").format("HH:mm"),
        };
        addLogReset(newObject);
      }
    }
  }, [edit, editId]);

  useEffect(() => {
    if (visitFacilityId?.facilityId) {
      setQueries([
        ...queries.filter((x) => x.name != "consultingType"),
        {
          name: "consultingType",
          query: visitFacilityId?.facilityId,
        },
      ]);
    }
  }, [visitFacilityId]);

  useEffect(() => {
    if (isAddSuccess && !isAddLogLoading) {
      enqueueSnackbar(` Logs ${edit ? "Updated" : "Added"} Successfully`, {
        variant: "success",
      });
      addLogReset({
        timeIn: "",
        timeOut: "",
      });
      setIsLogsChange(false);
      setEdit(false);
    }
  }, [isAddSuccess, isAddLogLoading]);

  // used to show error on the basis of inputField
  useEffect(() => {
    if (addError) {
      setErrorsForProperties({
        setError: setAddError,
        responseError: addError,
      });
    }
  }, [addError]);

  // function used to add log
  const onSubmit = (data) => {
    const newData = {
      visitPlanId: currentPlanId,
      visitHoursId: edit ? editId : 0,
      selectedConsultingType: `${data?.consultingType}`,
      selectedConsultingTypeRateType: toString(rateTypeId),
      timeIn: data?.timeIn
        ? dayjs(data?.timeIn, "HH:mm").format("hh:mm A")
        : "10:00 PM",
      timeOut: data?.timeOut
        ? dayjs(data?.timeOut, "HH:mm").format("hh:mm A")
        : "11:00 PM",
      mileage: data?.mileage || 0,
      note: data?.notes || "",
      planDate: new Date(dateOfVisit),
    };
    addLog(newData);
  };

  // column for dataGrid
  const viewReportColumns = [
    {
      field: "consultingTypeName",
      minWidth: 200,
      flex: 1,
      headerName: "Type",
    },
    {
      field: "timeIn",
      minWidth: 100,
      flex: 1,
      headerName: "Start Time",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.timeIn === " " ? "--" : params.row.timeIn;
      },
    },
    {
      field: "timeOut",
      minWidth: 100,
      flex: 1,
      headerName: "End Time",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return params.row.timeOut === " " ? "--" : params.row.timeOut;
      },
    },
    {
      field: "quantity",
      minWidth: 100,
      flex: 1,
      headerName: "Mileage",
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      renderCell: (params) => {
        return isNull(params.row.quantity) ? "--" : params.row.quantity;
      },
    },
    {
      field: "notes",
      minWidth: 200,
      flex: 1,
      headerName: "Notes",
      // cellClassName: "cellCenterAlign",
      // headerAlign: "center",
      renderCell: (params) => {
        return params.row.notes === ""
          ? "--"
          : params.row.notes?.length > 30
          ? params.row.notes?.slice(0, 30) + "..."
          : params.row.notes;
      },
    },

    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={loggedData}
            hasEdit={true}
            editProps={{
              onClick: (e) => {
                e.preventDefault();
                setEditId(params.row?.id);
                setEdit(true);
              },
              title: "Logged Hours",
            }}
            hasDelete={true}
            deleteProps={{
              entityName: params.row?.consultingTypeName,
              apiPath: "deleteVisitHours",
              title: "Logged Hours",
            }}
          />
        );
      },
    },
  ];

  //   UI Section
  return (
    <Box
      sx={{
        border: "1px solid #fff",
        boxShadow: "0px 4px 4px 0px #00000040",
        paddingX: 0.8,
        paddingBottom: 1,
        borderRadius: "4px !important",
        marginTop: 2.4,
      }}
    >
      <Box
        sx={{
          backgroundColor: "#C4E1E2 !important",
          marginX: -1,
          padding: 0.5,
          borderTopLeftRadius: "4px !important",
          borderTopRightRadius: "4px !important",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            marginLeft: 1,
          }}
        >
          Log Hours And Mileage
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          marginY: "10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Grid2
            container
            sx={{
              columnGap: 3,
            }}
          >
            <FormProvider {...addLogMethods}>
              <GeneratedForm
                oldGrid={false}
                list={TextFields}
                hiddenList={addLogHiddenList}
                queries={queries}
              />
            </FormProvider>
          </Grid2>
        </Box>
        <Box
          sx={{
            width: "9%",
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
          }}
        >
          <BaseSubmitButton
            text={edit ? "Update" : "Add"}
            variant="outlined"
            isSubmitting={isAddLogLoading}
            onClick={addLogHandleSubmit(onSubmit)}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        {!isUndefined(loggedData) ? (
          <BaseDataGrid
            rows={isGetError ? [] : loggedData || []}
            columns={CreateBaseColumns(viewReportColumns)}
            loading={isLoading}
            error={isError}
            height={"100%"}
            getRowHeight={() => "auto"}
            getRowId={(row) => row.id}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
            }}
          />
        ) : null}
      </Box>
    </Box>
  );
};
