export const MenuOptionsList = [
  {
    id: 1,
    optionName: "Edit Menu Name",
    Link: "/plateFul/Menus/AddMenus",
    isActive: false,
  },
  // {
  //   id: 2,
  //   optionName: "Meals/Days",
  //   Link: "/plateFul/Menus/MenuDays",
  //   isActive: false,
  // },
  // {
  //   id: 3,
  //   optionName: "Copy",
  // },
  {
    id: 4,
    optionName: "Menu Planner",
    Link: "/plateFul/Menus/MenuPlanner",
    IsActive: false,
  },
  {
    id: 5,
    optionName: "Change History",
    Link: "/plateFul/Menus/ChangeHistory",
    IsActive: false,
  },
  // {
  //   id: 6,
  //   optionName: "Share Menu",
  //   Link: "/plateFul/Menus/ShareMenu",
  //   isActive: false,
  // },
  // {
  //   id: 7,
  //   optionName: "Corporate Group Access",
  //   Link: "/plateFul/Menus/corporateGroup",
  //   isActive: false,
  // },
  // {
  //   id: 8,
  //   optionName: "Sign Menu",
  // },
  // {
  //   id: 9,
  //   optionName: "Missing Vendor Items",
  //   Link: "/plateFul/Menus/MissingVendor",
  //   isActive: false,
  // },
  // {
  //   id: 10,
  //   optionName: "Recipe Reprint List",
  //   Link: "/plateFul/Menus/recipeReprint",
  //   isActive: false,
  // },
  // {
  //   id: 11,
  //   optionName: "Customize Posted Menu",
  //   Link: "/plateFul/Menus/CustomizePostedMenu",
  //   isActive: false,
  // },
  // {
  //   id: 12,
  //   optionName: "Bulk Product Entry",
  //   Link: "/plateFul/Menus/MenuBulkProductEntry",
  //   isActive: false,
  // },
  // {
  //   id: 13,
  //   optionName: "Order Transmission",
  //   Link: "/plateFul/Menus/orderTransmission",
  //   isActive: false,
  // },
  // {
  //   id: 14,
  //   optionName: "Copy Other Menu Items",
  //   Link: "/plateFul/Menus/copyOtherMenuItems",
  //   isActive: false,
  // },
];

export const MenuOptionsList2 = [
  // {
  //   id: 1,
  //   optionName: "Edit Menu Name",
  //   Link: "/plateFul/Menus/AddMenus",
  //   isActive: false,
  // },
  {
    id: 2,
    optionName: "Meals/Days",
    Link: "/plateFul/Menus/MenuDays",
    isActive: false,
  },
  // {
  //   id: 3,
  //   optionName: "Copy",
  // },
  // {
  //   id: 4,
  //   optionName: "Menu Planner",
  //   Link: "/plateFul/Menus/MenuPlanner",
  //   IsActive: false,
  // },
  // {
  //   id: 5,
  //   optionName: "Change History",
  //   Link: "/plateFul/Menus/ChangeHistory",
  //   IsActive: false,
  // },
  // {
  //   id: 6,
  //   optionName: "Share Menu",
  //   Link: "/plateFul/Menus/ShareMenu",
  //   isActive: false,
  // },
  {
    id: 7,
    optionName: "Corporate Group Access",
    Link: "/plateFul/Menus/corporateGroup",
    isActive: false,
  },
  // {
  //   id: 8,
  //   optionName: "Sign Menu",
  // },
  {
    id: 9,
    optionName: "Missing Vendor Items",
    Link: "/plateFul/Menus/MissingVendor",
    isActive: false,
  },
  // {
  //   id: 10,
  //   optionName: "Recipe Reprint List",
  //   Link: "/plateFul/Menus/recipeReprint",
  //   isActive: false,
  // },
  // {
  //   id: 11,
  //   optionName: "Customize Posted Menu",
  //   Link: "/plateFul/Menus/CustomizePostedMenu",
  //   isActive: false,
  // },
  // {
  //   id: 12,
  //   optionName: "Bulk Product Entry",
  //   Link: "/plateFul/Menus/MenuBulkProductEntry",
  //   isActive: false,
  // },
  // {
  //   id: 13,
  //   optionName: "Order Transmission",
  //   Link: "/plateFul/Menus/orderTransmission",
  //   isActive: false,
  // },
];

export const Labels = {
  EMPTY_STRING: "",
  EMPTY_ARRAY: [],
};
