import {
  MAX_LENGTH_VALIDATION,
  MAX_VALUE_VALIDATION,
  MIN_VALUE_VALIDATION,
  POSITIVE_INTEGER_NUMBER,
  REQUIRED_ERROR,
} from "utils/errorMessages";

export const ResidentInformationFields = {
  formTextFields: [
    {
      props: {
        label: "First Name",
        id: "firstName",
        name: "firstName",
        defaultValue: "",
        checkEMREdit: true,
      },
      validationProps: {
        required: REQUIRED_ERROR("First Name"),
        maxLength: {
          value: 20,
          message: "Please enter 20 characters or less",
        },
      },
    },
    {
      props: {
        label: "Last Name",
        id: "lastName",
        name: "lastName",
        defaultValue: "",
        checkEMREdit: true,
      },
      validationProps: {
        required: REQUIRED_ERROR("Last Name"),
        maxLength: {
          value: 20,
          message: "Please enter 20 characters or less",
        },
      },
    },
    {
      props: {
        label: "Title",
        id: "nameTitle",
        name: "nameTitle",
        defaultValue: "",
        checkEMREdit: false,
      },
      validationProps: {
        maxLength: {
          value: 10,
          message: "Please enter 10 characters or less",
        },
      },
    },
    {
      props: {
        label: "Nickname",
        id: "nickname",
        name: "nickname",
        defaultValue: "",
        checkEMREdit: false,
      },
      validationProps: {
        maxLength: {
          value: 20,
          message: "Please enter 20 characters or less",
        },
      },
    },
    {
      props: {
        label: "Physician",
        id: "physician",
        name: "physician",
        defaultValue: "",
        checkEMREdit: true,
      },
      validationProps: {
        maxLength: {
          value: 50,
          message: "Please enter 50 characters or less",
        },
      },
    },
    {
      props: { controlType: "skip" },
    },
    {
      props: {
        label: "Resident Id",
        id: "residentIdNumber",
        name: "residentIdNumber",
        defaultValue: "",
        checkEMREdit: true,
      },
      validationProps: {
        maxLength: MAX_LENGTH_VALIDATION("Resident Id", 20),
      },
    },
    {
      props: {
        label: "AHT/EHR identifier",
        id: "ahtpatientId",
        name: "ahtpatientId",
        defaultValue: "",
        checkEMREdit: true,
      },
      validationProps: {
        maxLength: {
          value: 80,
          message: "Please enter 80 characters or less",
        },
      },
    },
    {
      props: {
        label: "Date Of Birth",
        id: "dateOfBirth",
        name: "dateOfBirth",
        controlType: "date",
        defaultValue: null,
        checkEMREdit: true,
      },
      validationProps: {
        max: {
          value: new Date(),
          message: "Date of Birth must be before today",
        },
      },
    },
    {
      props: {
        label: "Admission Date",
        id: "admissionDate",
        name: "admissionDate",
        controlType: "date",
        defaultValue: null,
        checkEMREdit: true,
      },
    },
    {
      props: {
        label: "Building",
        id: "building",
        name: "building",
        defaultValue: "",
        checkEMREdit: true,
      },
      validationProps: {
        maxLength: MAX_LENGTH_VALIDATION("Building", 20),
      },
    },

    {
      props: {
        label: "Wing",
        id: "wing",
        name: "wing",
        defaultValue: "",
        checkEMREdit: true,
      },
    },
    {
      props: {
        label: "Room Number",
        id: "roomNumber",
        name: "roomNumber",
        defaultValue: "",
        checkEMREdit: true,
      },
    },
    {
      props: {
        label: "Bed",
        id: "bed",
        name: "bed",
        defaultValue: "",
        checkEMREdit: true,
      },
      validationProps: {
        maxLength: MAX_LENGTH_VALIDATION("Bed", 5),
      },
    },
    // {
    //   props: {
    //     label: "Body Weight",
    //     id: "usualBodyWeight",
    //     name: "usualBodyWeight",
    //     type: "number",
    //     defaultValue: 0,
    //     checkEMREdit: false,
    //   },
    // },
  ],
  formTextFields3: [
    {
      props: {
        label: "Body Weight",
        id: "usualBodyWeight",
        name: "usualBodyWeight",
        type: "number",
        defaultValue: 0,
        checkEMREdit: false,
      },
      validationProps: {
        maxLength: MAX_LENGTH_VALIDATION("Body Weight", 3),
      },
    },
  ],
  formSelectProps2: {
    props: {
      label: "Body Weight Source",
      id: "usualBodyWeightSource",
      name: "usualBodyWeightSource",
      defaultValue: 0,
    },
  },
  formTextFields2: [
    {
      props: {
        label: "Height feet",
        id: "heightFeet",
        name: "heightFeet",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        pattern: POSITIVE_INTEGER_NUMBER(),
        max: MAX_VALUE_VALIDATION("Height feet", 10),
      },
    },

    {
      props: {
        label: "Height inches",
        id: "heightInches",
        name: "heightInches",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        pattern: POSITIVE_INTEGER_NUMBER(),
        max: MAX_VALUE_VALIDATION("Height inches", 12),
      },
    },
  ],
  formTextFields4: [
    {
      props: {
        label: "Assessment Due Date",
        id: "assessmentDueDate",
        name: "assessmentDueDate",
        controlType: "date",
        defaultValue: null,
      },
    },
    {
      props: {
        label: "Care Plan Due Date",
        id: "carePlanDueDate",
        name: "carePlanDueDate",
        controlType: "date",
        defaultValue: null,
      },
    },
  ],
  formSelectProps: {
    props: {
      label: "Gender",
      id: "gender",
      name: "gender",
      defaultValue: null,
      checkEMREdit: true,
      validationProps: {
        required: REQUIRED_ERROR("Gender"),
      },
    },
  },
};

export const DietFields = {
  formSelectProps: [
    {
      props: {
        label: "Diet",
        id: "dietId",
        name: "dietId",
        defaultValue: null,
      },
    },
    {
      props: {
        label: "Diet Texture",
        id: "dietTextureId",
        name: "dietTextureId",
        defaultValue: null,
      },
    },
  ],
  formTextFields: [
    {
      props: {
        label: "Diet Other",
        id: "dietOther",
        name: "dietOther",
        defaultValue: "",
      },
    },
  ],
  formSelectProps2: [
    {
      props: {
        label: "Liquid Consistency",
        id: "thickenedLiquidId",
        name: "thickenedLiquidId",
        defaultValue: null,
      },
    },
  ],
  formTextFields2: [
    {
      props: {
        label: "Fluid Restriction Quantity",
        id: "restrictQty",
        name: "restrictQty",
        defaultValue: "",
      },
      validationProps: {
        maxLength: MAX_LENGTH_VALIDATION("Fluid Restriction Quantity", 20),
      },
    },
  ],
  DietCheckBoxes: [
    {
      props: {
        label: "NPO?",
        name: "isNpo",
        defaultValue: false,
      },
    },
    {
      props: {
        label: "Tube Feeding?",
        name: "isTubeFeeding",
        defaultValue: false,
      },
    },
    {
      props: {
        label: "Also Food/Fluids by Mouth?",
        name: "isTfandFoodFluids",
        defaultValue: true,
      },
    },
    {
      props: {
        label: "Isolation?",
        name: "isIsolation",
        defaultValue: false,
      },
    },
    {
      props: {
        label: "RoomTray?",
        name: "isRoomTray",
        defaultValue: false,
      },
    },
    {
      props: {
        label: "Unlimited Meals?",
        name: "hasUnlimitedMeals",
        defaultValue: false,
      },
    },
    {
      props: {
        label: "Fluid Restrict?",
        name: "hasFluidRestrict",
        defaultValue: false,
      },
    },
  ],
};

export const DiningFields = {
  formTextFields: [
    {
      props: {
        label: "Monthly TableSide Budget",
        id: "monthlyTableSideBudget",
        name: "monthlyTableSideBudget",
        type: "number",
        defaultValue: 0,
      },
      validationProps: {
        max: {
          value: 999999999999999,
          message: `Monthly TableSide Budget should be less than 15 characters long.`,
        },
        pattern: {
          value: /^\d{1,15}(\.\d{1,3})?$/,
          message:
            "Monthly TableSide Budget should be less than 15 characters long and upto 3 decimal  ",
        },
      },
    },

    {
      props: {
        label: "Declining Balance Start Day",
        id: "tableSideBudgetStartDay",
        name: "tableSideBudgetStartDay",
        type: "number",
      },
      validationProps: {
        min: MIN_VALUE_VALIDATION("Declining Balance Start Day", 1),
        max: MAX_VALUE_VALIDATION("Declining Balance Start Day", 28),
        required: REQUIRED_ERROR("Declining Balance Start Day"),
        pattern: POSITIVE_INTEGER_NUMBER(),
      },
    },
  ],
};

export const residentDefaultValue = {
  firstName: "",
  lastName: "",
  nameTitle: "",
  nickname: "",
  physician: "",
  residentIdNumber: "",
  ahtpatientId: "",
  dateOfBirth: null,
  admissionDate: null,
  building: "",
  wing: "",
  roomNumber: "",
  bed: "",
  usualBodyWeight: 0,
  heightFeet: 0,
  heightInches: 0,
  assessmentDueDate: null,
  carePlanDueDate: null,
  gender: null,

  dietId: null,
  dietTextureId: null,
  dietOther: "",
  thickenedLiquidId: null,
  restrictQty: "",
  isNpo: false,
  isTubeFeeding: false,
  isTfandFoodFluids: true,
  isIsolation: false,
  isRoomTray: false,
  hasUnlimitedMeals: false,
  hasFluidRestrict: false,

  monthlyTableSideBudget: 0,
  tableSideBudgetStartDay: null,
};
