import React, { useEffect, useState, useCallback } from "react";
import { Controller } from "react-hook-form";
import { Autocomplete, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import useSafeFormContext from "hooks/useSafeFormContext";
import { getNestedProperty } from "utils/helperFunctions";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseAutoComplete.md)
 */
export default function BaseAutoComplete({
  control,
  errors,
  // Control and Errors should be avoided for future cases, enclose the GenerateForm inside a FormProvider instead
  options,
  defaultOptions,
  loading,
  formSelectProps,
  handleSearch,
  placeholder,
  filterOptions,
  isLabelBold,
  resetSearch,
  variant = "outlined",
  bootstrap = false,
  setValue,
  onClearFunction = null,
}) {
  const {
    control: controlMethod,
    errors: formErrors,
    setValue: setValueMethod,
  } = useSafeFormContext({
    control,
    errors,
    setValue,
  });

  const error = getNestedProperty(formErrors, formSelectProps.props?.name);

  const isBootstrapStyle = bootstrap && variant === "standard";
  const defaultValue = defaultOptions?.[0];

  const [selectedOption, setSelectedOption] = useState(
    defaultValue !== undefined ? defaultValue : { id: null, label: "" }
  );

  useEffect(() => {
    setSelectedOption(defaultValue || { id: null, label: "" });
  }, [defaultValue, resetSearch]);

  const handleInputChange = useCallback(
    (event) => {
      handleSearch(event.target.value);
    },
    [handleSearch]
  );

  const handleClear = () => {
    if (onClearFunction) {
      onClearFunction();
    } else if (setValueMethod)
      setValueMethod(formSelectProps.props?.name, null);
    setSelectedOption({ id: null, label: "" });
  };

  return (
    <Controller
      control={controlMethod}
      name={formSelectProps.props?.name}
      rules={{ ...formSelectProps.validationProps }}
      defaultValue={defaultValue}
      size={formSelectProps?.props?.size}
      render={({ field: { onChange } }) => {
        return (
          <Autocomplete
            freeSolo={true}
            options={options}
            filterOptions={
              filterOptions ||
              ((options, params) => {
                const filters = params.inputValue
                  .split(" ")
                  .filter((value) => value);
                let filtered = options;

                filters.forEach((filter) => {
                  filtered = filtered.filter((option) =>
                    option.label.toLowerCase().includes(filter.toLowerCase())
                  );
                });

                return filtered;
              })
            }
            loading={loading}
            size={formSelectProps?.props?.size}
            name={formSelectProps.props.name}
            getOptionLabel={(option) => option?.label || ""}
            value={selectedOption}
            onChange={(event, item) => {
              if (item === null) {
                handleClear();
              } else {
                setSelectedOption(item);
                onChange(item?.id);
              }
            }}
            renderInput={(params) => (
              <TextField
                variant={variant}
                bootstrap={bootstrap.toString()}
                size={formSelectProps?.props?.size}
                {...params}
                onChange={handleInputChange}
                label={formSelectProps.props?.label}
                error={!!error}
                helperText={!!error?.message ?? ""}
                slotProps={{
                  input: {
                    disableUnderline: isBootstrapStyle,
                    ...params.InputProps,
                  },
                  inputLabel: isBootstrapStyle
                    ? {
                        shrink: true,
                        variant: variant,
                      }
                    : { variant: variant },
                }}
                placeholder={
                  placeholder ||
                  `Select a ${
                    formSelectProps.props?.label || formSelectProps.props?.name
                  }`
                }
              />
            )}
            renderOption={(props, option, index) => {
              const key = `AutoComplete-${option?.id}-${index}`;
              return (
                <li {...props} key={key}>
                  <Grid container direction={"column"}>
                    <Grid
                      sx={{
                        wordWrap: "break-word",
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{
                          fontWeight:
                            option?.description || isLabelBold
                              ? "bold"
                              : "regular",
                        }}
                      >
                        {option?.label}
                      </Typography>
                      {option?.description && (
                        <Typography
                          variant="body2"
                          sx={{
                            color: "text.secondary",
                          }}
                        >
                          {option?.description}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
        );
      }}
    />
  );
}
