import { Box, Grid2, Typography, useTheme } from "@mui/material";
import React from "react";
import FieldGroup from "./FieldGroup";
import BaseCheckBox from "components/shared/baseForm/BaseCheckBox";
import { useFormContext } from "react-hook-form";

export default function Section({ section }) {
  const theme = useTheme();
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const {
    id,
    name,
    size,
    hasIsApplicableCheck,
    hasSelectAllCompletedCheck,
    fieldGroups,
  } = section;

  if (name !== "-") {
    return (
      <Grid2 size={size}>
        <Box width={"100%"}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              p: "16px",
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.primary.dark,
              borderRadius: 1,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }}>
            <Typography
              variant={"h6"}
              sx={{
                fontWeight: "600",
                color: theme.palette.primary.contrastText,
              }}
              color={theme.palette.primary.contrastText}>
              {name}
            </Typography>
            <Box>
              {hasIsApplicableCheck ? (
                <BaseCheckBox
                  size={"small"}
                  name={`notApplicable-${id}`}
                  label={"Not Applicable"}
                  color={"white"}
                  control={control}
                  errors={errors}
                />
              ) : null}
              {hasSelectAllCompletedCheck ? (
                <BaseCheckBox
                  size={"small"}
                  name={`allCompleted-${id}`}
                  label={"Mark All As Completed"}
                  control={control}
                  errors={errors}
                />
              ) : null}
            </Box>
          </Box>

          <Box
            p={1}
            borderRadius={1}
            borderColor={"grey"}
            border={"2px solid"}
            sx={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
            borderTop={"unset"}>
            <Grid2 container spacing={1}>
              {fieldGroups?.map((fieldGroup) => (
                <FieldGroup
                  isNotApplicable={watch(`notApplicable-${id}`)}
                  isAllCompleted={watch(`allCompleted-${id}`)}
                  key={fieldGroup.id}
                  fieldGroup={fieldGroup}
                />
              ))}
            </Grid2>
          </Box>
        </Box>
      </Grid2>
    );
  }

  return (
    <Grid2 size={size}>
      <Grid2 container spacing={1}>
        {fieldGroups?.map((fieldGroup) => (
          <FieldGroup key={fieldGroup.id} fieldGroup={fieldGroup} />
        ))}
      </Grid2>
    </Grid2>
  );
}
