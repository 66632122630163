import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useFacilityContractsQuery } from "../../../../../../store/apis/FacilityApis";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import { PostAdd } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function CommunityContracts() {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const {
    data: contracts,
    isLoading,
    isError,
  } = useFacilityContractsQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });

  const communityColumns = [
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      minWidth: 100,
      renderCell: (params) => {
        return !params.row.secureFileName ? (
          <span>{params.row.description}</span>
        ) : (
          // Need to transfer this storage url to env file
          <Link
            target="_blank"
            rel="noreferrer"
            // eslint-disable-next-line no-undef
            to={`${process.env.REACT_APP_STORAGE_URL}/${params.row.container}/${params.row.secureFileName}`}
          >
            {params.row.description}
          </Link>
        );
      },
    },
    {
      field: "documentType",
      minWidth: 50,
      flex: 1,
      headerName: "Type",
    },
    {
      field: "contractStartDate",
      minWidth: 50,
      flex: 1,
      headerName: "Start Date",
      valueGetter: (value) => dayjs(value).toDate(),
      valueFormatter: (value) => {
        const isDateValid = dayjs(value).isValid();
        return isDateValid
          ? dayjs(value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "contractEndDate",
      minWidth: 50,
      flex: 1,
      headerName: "End Date",
      valueGetter: (value) => dayjs(value).toDate(),
      valueFormatter: (value) => {
        const isDateValid = dayjs(value).isValid();
        return isDateValid
          ? dayjs(value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "dateUploaded",
      minWidth: 50,
      flex: 1,
      headerName: "Date Uploaded",
      valueGetter: (value) => dayjs(value).toDate(),
      valueFormatter: (value) => {
        const isDateValid = dayjs(value).isValid();
        return isDateValid
          ? dayjs(value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },

    {
      field: "uploadedBy",
      minWidth: 50,
      flex: 1,
      headerName: "Uploaded By",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={contracts}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `addcommunitycontract?id=${id}&contractId=${params.row.id}`,
              title: "Community Contract/Agreement",
            }}
            deleteProps={{
              hintText: `Are you sure you want to delete "${params.row?.description}"?`,
              apiPath: "deleteFacilityContract",
              entityName: params.row?.description,
              title: "Community Contract/Agreement",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox justifyContent={"start"} my={1}>
        <BaseButton
          sx={{ width: { xs: "100%", sm: "auto" } }}
          text={"Add Community Contract/Agreement"}
          endIcon={<PostAdd />}
          To={`addcommunitycontract?id=${id}`}
        />
      </FlexBox>
      <BaseDataGrid
        rows={contracts}
        columns={communityColumns}
        error={isError}
        loading={isLoading}
      />
    </>
  );
}
