import React from "react";

export default function Logo() {
  return (
    <svg
      width="180"
      height="107"
      viewBox="0 0 303 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3202_17716)">
        <path
          d="M46.3 96.9008C71.8708 96.9008 92.6 76.1716 92.6 50.6008C92.6 25.03 71.8708 4.30078 46.3 4.30078C20.7292 4.30078 0 25.03 0 50.6008C0 76.1716 20.7292 96.9008 46.3 96.9008Z"
          fill="#FEC800"
        />
        <path
          d="M46.3 92.9996C22.9 92.9996 3.8 73.9996 3.8 50.4996C3.8 26.9996 22.9 8.09961 46.3 8.09961C69.7 8.09961 88.8 27.0996 88.8 50.5996C88.8 74.0996 69.7 93.0996 46.3 93.0996V92.9996ZM46.3 10.1996C24.1 10.1996 6 28.2996 6 50.4996C6 72.6996 24.1 90.7996 46.3 90.7996C68.5 90.7996 86.6 72.6996 86.6 50.4996C86.6 28.2996 68.5 10.1996 46.3 10.1996Z"
          fill="white"
        />
        <path
          d="M117.6 100.401V89.3008H121C122 89.3008 122.8 89.5008 123.6 90.0008C124.4 90.4008 125 91.1008 125.4 91.9008C125.8 92.7008 126.1 93.6008 126.1 94.7008V95.2008C126.1 96.2008 125.9 97.1008 125.5 98.0008C125.1 98.8008 124.5 99.4008 123.7 99.9008C122.9 100.301 122.1 100.601 121.1 100.601H117.7L117.6 100.401ZM119.8 91.2008V98.6008H120.9C121.8 98.6008 122.5 98.3008 123 97.7008C123.5 97.1008 123.7 96.3008 123.7 95.2008V94.6008C123.7 93.5008 123.5 92.6008 123 92.0008C122.5 91.4008 121.8 91.1008 120.9 91.1008H119.8V91.2008Z"
          fill="#A6A4A4"
        />
        <path
          d="M127.5 90.1C127.5 89.8 127.6 89.5 127.8 89.3C128 89.1 128.3 89 128.7 89C129.1 89 129.4 89.1 129.6 89.3C129.8 89.5 129.9 89.8 129.9 90.1C129.9 90.4 129.8 90.7 129.6 90.9C129.4 91.1 129.1 91.2 128.7 91.2C128.3 91.2 128 91.1 127.8 90.9C127.6 90.7 127.5 90.4 127.5 90.1ZM129.9 100.4H127.7V92.2H129.9V100.4Z"
          fill="#A6A4A4"
        />
        <path
          d="M133.7 92.2V93.1C134.4 92.4 135.2 92 136.1 92C137 92 137.6 92.3 138 92.8C138.4 93.3 138.6 94.1 138.7 95.1V100.4H136.5V95.1C136.5 94.6 136.4 94.3 136.2 94.1C136 93.9 135.7 93.8 135.2 93.8C134.7 93.8 134.1 94.1 133.8 94.6V100.4H131.6V92.2H133.7Z"
          fill="#A6A4A4"
        />
        <path
          d="M140.4 90.1C140.4 89.8 140.5 89.5 140.7 89.3C140.9 89.1 141.2 89 141.6 89C142 89 142.3 89.1 142.5 89.3C142.7 89.5 142.8 89.8 142.8 90.1C142.8 90.4 142.7 90.7 142.5 90.9C142.3 91.1 142 91.2 141.6 91.2C141.2 91.2 140.9 91.1 140.7 90.9C140.5 90.7 140.4 90.4 140.4 90.1ZM142.7 100.4H140.5V92.2H142.7V100.4Z"
          fill="#A6A4A4"
        />
        <path
          d="M146.6 92.2V93.1C147.3 92.4 148.1 92 149 92C149.9 92 150.5 92.3 150.9 92.8C151.3 93.3 151.5 94.1 151.6 95.1V100.4H149.4V95.1C149.4 94.6 149.3 94.3 149.1 94.1C148.9 93.9 148.6 93.8 148.1 93.8C147.6 93.8 147 94.1 146.7 94.6V100.4H144.5V92.2H146.6Z"
          fill="#A6A4A4"
        />
        <path
          d="M153 96.3C153 95 153.3 94 153.9 93.2C154.5 92.4 155.3 92 156.3 92C157.3 92 157.9 92.3 158.4 92.9V92.1H160.5V100.1C160.5 100.8 160.3 101.4 160 102C159.7 102.6 159.2 102.9 158.6 103.2C158 103.5 157.3 103.6 156.5 103.6C155.7 103.6 155.3 103.5 154.7 103.2C154.1 103 153.7 102.6 153.4 102.3L154.4 101C155 101.6 155.6 101.9 156.4 101.9C157.2 101.9 157.4 101.7 157.8 101.4C158.2 101.1 158.3 100.6 158.3 100.1V99.7C157.8 100.3 157.1 100.6 156.3 100.6C155.5 100.6 154.5 100.2 153.9 99.4C153.3 98.6 153 97.6 153 96.3ZM155.2 96.4C155.2 97.2 155.3 97.7 155.6 98.2C155.9 98.7 156.3 98.8 156.8 98.8C157.3 98.8 157.9 98.6 158.2 98V94.5C157.9 94 157.4 93.7 156.8 93.7C156.2 93.7 155.9 93.9 155.6 94.4C155.3 94.9 155.2 95.5 155.2 96.3V96.4Z"
          fill="#A6A4A4"
        />
        <path
          d="M166.4 96.4004H164.6V100.5H162.3V89.4004H166.4C167.7 89.4004 168.7 89.7004 169.4 90.3004C170.1 90.9004 170.5 91.7004 170.5 92.8004C170.5 93.9004 170.3 94.2004 170 94.7004C169.7 95.2004 169.2 95.6004 168.5 95.9004L170.9 100.4C170.9 100.5 168.5 100.5 168.5 100.5L166.4 96.4004ZM164.6 94.5004H166.4C167 94.5004 167.4 94.3004 167.7 94.1004C168 93.9004 168.2 93.4004 168.2 92.9004C168.2 92.4004 168.1 92.0004 167.8 91.7004C167.5 91.4004 167 91.3004 166.4 91.3004H164.6V94.6004V94.5004Z"
          fill="#A6A4A4"
        />
        <path
          d="M172.3 100.401V89.3008H175.7C176.7 89.3008 177.6 89.5008 178.3 90.0008C179.1 90.4008 179.7 91.1008 180.1 91.9008C180.5 92.7008 180.7 93.6008 180.7 94.7008V95.2008C180.7 96.2008 180.5 97.1008 180.1 98.0008C179.7 98.8008 179.1 99.4008 178.3 99.9008C177.5 100.301 176.7 100.601 175.7 100.601H172.3V100.401ZM174.6 91.2008V98.6008H175.7C176.6 98.6008 177.3 98.3008 177.8 97.7008C178.3 97.1008 178.5 96.3008 178.5 95.2008V94.6008C178.5 93.5008 178.3 92.6008 177.8 92.0008C177.3 91.4008 176.7 91.1008 175.7 91.1008H174.6V91.2008Z"
          fill="#A6A4A4"
        />
        <path
          d="M104.6 96.4C104.6 97.7 104.3 98.7 103.8 99.5C103.3 100.3 102.5 100.6 101.4 100.6C100.3 100.6 99.8 100.3 99.3 99.6V100.4H97.2V89H99.4V92.9C99.9 92.3 100.6 92 101.4 92C102.2 92 103.2 92.4 103.7 93.2C104.3 94 104.6 95 104.6 96.3C104.6 96.4 104.6 96.4 104.6 96.4ZM100.8 93.9C100.1 93.9 99.6 94.2 99.4 94.7V98.1C99.7 98.6 100.1 98.9 100.8 98.9C101.5 98.9 101.7 98.7 102 98.3C102.3 97.9 102.4 97.3 102.4 96.5C102.4 94.8 101.9 93.9 100.8 93.9Z"
          fill="#A6A4A4"
        />
        <path
          d="M108.8 97.2996L110.3 92.1996H112.7L109.4 101.7L109.2 102.1C108.7 103.2 107.9 103.7 106.8 103.7C105.7 103.7 106.2 103.7 105.8 103.6V101.9H106.1C106.5 101.9 106.8 101.9 107 101.7C107.2 101.6 107.4 101.4 107.5 101.1L107.8 100.4L104.9 92.0996H107.3L108.8 97.1996V97.2996Z"
          fill="#A6A4A4"
        />
        <path
          d="M196.3 93.4992C199.006 93.4992 201.2 91.3054 201.2 88.5992C201.2 85.893 199.006 83.6992 196.3 83.6992C193.594 83.6992 191.4 85.893 191.4 88.5992C191.4 91.3054 193.594 93.4992 196.3 93.4992Z"
          fill="#F1C73F"
        />
        <path
          d="M190.4 99.4992C193.106 99.4992 195.3 97.3054 195.3 94.5992C195.3 91.893 193.106 89.6992 190.4 89.6992C187.694 89.6992 185.5 91.893 185.5 94.5992C185.5 97.3054 187.694 99.4992 190.4 99.4992Z"
          fill="#EE422D"
        />
        <path
          d="M196.4 106.101C199.106 106.101 201.3 103.907 201.3 101.201C201.3 98.4946 199.106 96.3008 196.4 96.3008C193.694 96.3008 191.5 98.4946 191.5 101.201C191.5 103.907 193.694 106.101 196.4 106.101Z"
          fill="#43B9BD"
        />
        <path d="M136.2 12.8008H128.6V35.6008H136.2V12.8008Z" fill="#231F20" />
        <path
          d="M101.5 42L94.4 77.8H103.3L107.5 53.5L114.4 77.7H122L128.9 53.5L133.1 77.8H141.9L134.9 42H125.7L118.2 67L110.7 42H101.5Z"
          fill="#231F20"
        />
        <path
          d="M155.9 12.0996C151.1 12.0996 148.8 14.7996 148.2 15.5996V12.6996H140.6V35.4996H148.2V22.3996C148.6 21.2996 149.9 18.3996 152.7 18.3996C155.5 18.3996 156.4 21.9996 156.4 21.9996V35.5996H164V20.2996C164.2 17.5996 161.5 12.1996 155.8 12.1996L155.9 12.0996Z"
          fill="#231F20"
        />
        <path
          d="M194.7 12.0996C189.9 12.0996 187.6 14.7996 187 15.5996V12.6996H179.4V35.4996H187V22.3996C187.4 21.2996 188.7 18.3996 191.5 18.3996C194.3 18.3996 195.2 21.9996 195.2 21.9996V35.5996H202.8V20.2996C203 17.5996 200.3 12.1996 194.6 12.1996L194.7 12.0996Z"
          fill="#231F20"
        />
        <path
          d="M188 54.1992C183.2 54.1992 180.9 56.8992 180.3 57.6992V54.7992H172.7V77.5992H180.3V64.4992C180.7 63.3992 182 60.4992 184.8 60.4992C187.6 60.4992 188.5 64.0992 188.5 64.0992V77.6992H196.1V62.3992C196.3 59.6992 193.6 54.2992 187.9 54.2992L188 54.1992Z"
          fill="#231F20"
        />
        <path
          d="M132.4 9.2C134.941 9.2 137 7.14051 137 4.6C137 2.05949 134.941 0 132.4 0C129.859 0 127.8 2.05949 127.8 4.6C127.8 7.14051 129.859 9.2 132.4 9.2Z"
          fill="#231F20"
        />
        <path d="M175.2 12.8008H167.6V35.6008H175.2V12.8008Z" fill="#231F20" />
        <path
          d="M171.4 9.2C173.941 9.2 176 7.14051 176 4.6C176 2.05949 173.941 0 171.4 0C168.859 0 166.8 2.05949 166.8 4.6C166.8 7.14051 168.859 9.2 171.4 9.2Z"
          fill="#231F20"
        />
        <path
          d="M160.9 54.9996V57.2996C158.8 55.2996 156.1 54.0996 153.1 54.0996C146.7 54.0996 141.5 59.4996 141.5 66.2996C141.5 73.0996 146.7 78.4996 153.1 78.4996C159.5 78.4996 158.9 77.2996 160.9 75.2996V77.7996H168.5V54.9996H160.9ZM155.3 72.0996C152.2 72.0996 149.7 69.4996 149.7 66.1996C149.7 62.8996 152.2 60.2996 155.3 60.2996C158.4 60.2996 160.9 62.8996 160.9 66.1996C160.9 69.4996 158.4 72.0996 155.3 72.0996Z"
          fill="#231F20"
        />
        <path
          d="M217.4 54.9996V57.2996C215.3 55.2996 212.6 54.0996 209.6 54.0996C203.2 54.0996 198 59.4996 198 66.2996C198 73.0996 203.2 78.4996 209.6 78.4996C216 78.4996 215.4 77.2996 217.4 75.2996V77.7996H225V54.9996H217.4ZM211.7 72.0996C208.6 72.0996 206.1 69.4996 206.1 66.1996C206.1 62.8996 208.6 60.2996 211.7 60.2996C214.8 60.2996 217.3 62.8996 217.3 66.1996C217.3 69.4996 214.8 72.0996 211.7 72.0996Z"
          fill="#231F20"
        />
        <path
          d="M247.1 54.9996V57.2996C245 55.2996 242.3 54.0996 239.3 54.0996C232.9 54.0996 227.7 59.4996 227.7 66.2996C227.7 73.0996 232.9 78.4996 239.3 78.4996C245.7 78.4996 245.1 77.2996 247.1 75.2996V77.7996C247.1 80.6996 245.5 85.1996 241.3 85.1996C237.1 85.1996 235.9 80.8996 235.9 80.8996H228.2C228.2 80.8996 228.5 90.7996 241.4 90.7996C254.3 90.7996 254.7 77.7996 254.7 77.7996V54.9996H247.1ZM241.4 72.0996C238.3 72.0996 235.8 69.4996 235.8 66.1996C235.8 62.8996 238.3 60.2996 241.4 60.2996C244.5 60.2996 247 62.8996 247 66.1996C247 69.4996 244.5 72.0996 241.4 72.0996Z"
          fill="#231F20"
        />
        <path
          d="M224.2 12.5992V14.8992C222.1 12.8992 219.4 11.6992 216.4 11.6992C210 11.6992 204.8 17.0992 204.8 23.7992C204.8 30.4992 210 35.9992 216.4 35.9992C222.8 35.9992 222.2 34.7992 224.2 32.7992V35.2992C224.2 38.1992 222.6 42.6992 218.4 42.6992C214.2 42.6992 213 38.3992 213 38.3992H205.3C205.3 38.3992 205.6 48.2992 218.5 48.2992C231.4 48.2992 231.8 35.2992 231.8 35.2992V12.5992H224.2ZM218.5 29.6992C215.4 29.6992 212.9 27.0992 212.9 23.7992C212.9 20.4992 215.4 17.8992 218.5 17.8992C221.6 17.8992 224.1 20.4992 224.1 23.7992C224.1 27.0992 221.6 29.6992 218.5 29.6992Z"
          fill="#231F20"
        />
        <path
          d="M302.5 61.7004V54.4004C302.3 54.4004 301.8 54.4004 301.5 54.4004C296.7 54.4004 294.4 57.1004 293.8 57.9004V55.0004H286.2V77.8004H293.8V64.7004C294.2 63.6004 295.5 60.7004 298.3 60.7004C301.1 60.7004 301.3 61.2004 301.9 61.8004H302.4L302.5 61.7004Z"
          fill="#231F20"
        />
        <path
          d="M109.5 0.300781H97.8V35.6008H109.5C118.6 35.6008 126 27.7008 126 18.0008C126 8.30078 118.6 0.300781 109.5 0.300781ZM108.1 28.8008H105.5V7.20078H108.1C114.1 7.20078 117.8 12.0008 117.8 18.0008C117.8 24.0008 114 28.8008 108.1 28.8008Z"
          fill="#231F20"
        />
        <path
          d="M275.9 69.2992C275 71.2992 273.2 72.6992 271.1 72.6992C269 72.6992 266.2 70.3992 265.7 67.3992H283.9C283.9 67.0992 283.9 66.6992 283.9 66.2992C283.9 59.5992 277.9 54.1992 270.6 54.1992C263.3 54.1992 257.3 59.5992 257.3 66.2992C257.3 72.9992 263.3 78.3992 270.6 78.3992C277.9 78.3992 282.1 74.4992 283.5 69.2992H275.8H275.9ZM271 58.9992C273.7 58.9992 276 60.9992 276.4 63.6992H265.7C266.1 61.0992 268.4 58.9992 271.1 58.9992H271Z"
          fill="#231F20"
        />
        <path
          d="M55.6 53.4004H53C53 53.4004 51.8 39.2004 51.8 34.6004C51.8 30.0004 52.8 15.1004 60.8 17.0004C61.6 17.2004 62.2 18.0004 62.2 18.8004L61.4 53.4004H60.8C60.8 53.4004 61.2 63.2004 61.8 69.3004C62.4 75.4004 65.1 85.4004 58.2 85.4004C51.3 85.4004 52.9 78.2004 53.5 76.5004C54.1 74.8004 55.7 71.0004 55.5 53.5004L55.6 53.4004Z"
          fill="white"
        />
        <path
          d="M38.9 48.4996C38.9 48.4996 38.5 40.6996 39.8 39.2996C41.1 37.8996 42.9 36.3996 42.8 32.0996C42.6 28.2996 42 19.1996 41.8 17.0996C41.8 16.7996 41.6 16.5996 41.3 16.5996H40.7V31.7996C40.7 32.2996 40.3 32.6996 39.8 32.6996C39.3 32.6996 38.9 32.2996 38.9 31.7996L38.6 16.6996H37.8V31.7996C37.8 32.2996 37.4 32.6996 36.9 32.6996C36.4 32.6996 36 32.2996 36 31.7996V16.6996H35.2L34.9 31.7996C34.9 32.2996 34.5 32.6996 34 32.6996C33.5 32.6996 33.1 32.2996 33.1 31.7996V16.6996H32.5C32.2 16.6996 32 16.8996 32 17.0996C31.8 19.1996 30.9 28.1996 30.8 32.3996C30.8 37.1996 32 36.9996 33.3 38.7996C34.5 40.6996 34.9 41.6996 34.6 48.5996C34.3 55.4996 33.4 80.3996 33.4 80.3996C33.4 80.3996 32.8 85.1996 36.4 85.1996C40 85.1996 39.8 81.1996 39.6 79.9996C39.4 78.7996 38.5 48.1996 38.5 48.1996L38.9 48.4996Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3202_17716">
          <rect width="302.5" height="106.1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
