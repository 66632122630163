// react imports
import React, { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
// custom css
import "../../../myRoster.css";
// MUI components
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Tooltip,
} from "@mui/material";
// MUI Icons
import ClearIcon from "@mui/icons-material/Clear";
// Shared Components
import GeneratedForm from "../../../../../../shared/generatedForm/GeneratedForm";
import HeaderBox from "../../../../../shared/HeaderBox";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
// form constants
import {
  KitchenFields,
  MealFields,
  observerFieldText,
  consultSummaryFields,
  MealObservation_ScreenNames,
  MEAL_OBSERVATION_DEFAULTS,
} from "./form.constants";
import { SCREEN_CONSTANT } from "../../../constants";
// api imports
import {
  useAddVisitReportMutation,
  useGetVisitReportsByIdQuery,
  useGetVisitReportsTaskQuery,
  useUpdateVisitReportMutation,
} from "../../../../../../../store/apis/ConsultantApis";
import size from "lodash/size";
import { BackButton } from "components/shared/BackButton";
import MealObservationAccordion from "./MealObservationAccordion";
import BaseBlockerModal2 from "components/shared/blockerModal/BaseBlockerModalv2";

// Component starts from here
function MealObservation() {
  // File state
  const [file, setFile] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [isErrors, setIsErrors] = useState(false);

  // Search Params from React Router dom
  const [searchParams] = useSearchParams();
  const visitPlanId = searchParams.get(SCREEN_CONSTANT.VISIT_PLAN_ID_QUERY);
  const visitId = searchParams.get("visitId");
  const visitReportTypeId = searchParams.get("visitReportTypeId");
  const id = searchParams.get("Id");
  const isEdit = !!id;
  const navigate = useNavigate();

  // unsaved changes functionality here
  const [isChange, setIsChange] = useState(false);

  // ObserverText Conditions
  const observerTextCheck =
    visitReportTypeId == 2
      ? observerFieldText.mealObservation
      : visitReportTypeId == 3
      ? observerFieldText.kitchenObservation
      : visitReportTypeId == 6
      ? observerFieldText.consultSummary
      : visitReportTypeId == 1
      ? observerFieldText.consultSummary
      : "";

  // GeneratedForm Conditions
  const generatedFormCheck =
    visitReportTypeId == 2
      ? MealFields
      : visitReportTypeId == 3
      ? KitchenFields
      : visitReportTypeId == 6
      ? consultSummaryFields
      : visitReportTypeId == 1
      ? consultSummaryFields
      : null;

  // API Call here
  const {
    data,
    isSuccess: tasksSuccess,
    isFetching: tasksFetching,
  } = useGetVisitReportsTaskQuery(visitReportTypeId);

  const {
    data: reportData,
    isFetching: reportFetching,
    error: reportError,
  } = useGetVisitReportsByIdQuery(id, {
    skip: !tasksSuccess || !id,
  });

  if (reportError?.status === 403) navigate("/forbidden");

  const isDataLoading = tasksFetching || reportFetching;

  const [
    addVisitReport,
    { isLoading: addLoading, isError: addError, isSuccess: addSuccess },
  ] = useAddVisitReportMutation();

  const [
    updateVisitReport,
    { error: updateError, isLoading: updateLoading, isSuccess: updateSuccess },
  ] = useUpdateVisitReportMutation();

  const isLoading = addLoading || updateLoading;
  const isSuccess = addSuccess || updateSuccess;
  const isError = addError || updateError;

  useEffect(() => {
    if (isSuccess) {
      reset();
      window.history.back();
      navigate(`/Consultant/MyRosters/VisitDetails?visitPlanId=${visitPlanId}`);
    }
  }, [isSuccess, navigate, isError]);

  // useForm Hooks destructuring
  const methods = useForm({
    mode: "all",
    shouldUnregister: false,
    defaultValues: MEAL_OBSERVATION_DEFAULTS,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = methods;

  const { fields: sections, replace: replaceSections } = useFieldArray({
    control: control,
    name: "sections",
  });

  useEffect(() => {
    if (data && reportData && isEdit) {
      const reportArray = reportData.configs;
      // Convert reportData to a Map for fast lookup by taskId
      const reportMap = new Map(reportArray.map((item) => [item.taskId, item]));

      // Merge arrays, prioritizing reportData's values
      const mergedArray = data.map((section) => {
        return {
          ...section,
          items: section.items.map((item) => {
            return {
              ...item,
              configs: item.configs.map((config) => {
                const reportItem = reportMap.get(config.taskId);
                return reportItem
                  ? { ...config, ...reportItem }
                  : { ...config };
              }),
            };
          }),
        };
      });

      // For fieldArray
      replaceSections(mergedArray);

      setFile(reportData.attachments);
      setAttachments(reportData.attachments);
      const newObject = {
        description: reportData.description,
        id: id,
        visitId: visitId,
        visitReportType: reportData.visitReportType,
        confidential: reportData.includeSignatureLine,
        sections: mergedArray,
      };

      // Resetting the values so default values are set
      reset((resetValues) => ({ ...resetValues, ...newObject }));
    } else if (data) {
      replaceSections(data);
      reset((resetValues) => ({ ...resetValues, sections: data }));
    }
  }, [isEdit, reportData, data]);

  function transformData(rawData) {
    const payload = {
      id: id || 0,
      visitReportTypeId,
      visitReportType: rawData?.visitReportType ?? "string",
      visitId,
      description: rawData.description,
      includeSignatureLine: rawData?.confidential ?? false,
      attachmentUrl: rawData?.attachmentUrl || "string",
      attachments: attachments ?? [],
      files: [],
    };

    const formData = new FormData();

    // Add payload
    for (const [key, value] of Object.entries(payload)) {
      switch (key) {
        case "files":
          break;
        case "attachments":
          // Handle array of attachments
          value?.forEach((item, index) => {
            for (const [subKey, subValue] of Object.entries(item)) {
              formData.append(`${key}[${index}][${subKey}]`, subValue);
            }
          });
          break;
        default:
          formData.append(key, value);
          break;
      }
    }

    // Handle file attachments
    file?.forEach((file) => {
      if (file.name) {
        formData.append("files", file);
      } else {
        console.warn(`Skipping non-file attachment.`);
      }
    });

    // Convert configs to FormData
    let configIndex = 0;

    rawData.sections.forEach((section) => {
      section.items.forEach((item) => {
        item.configs.forEach((config) => {
          formData.append(`configs[${configIndex}][taskId]`, config.taskId);
          if (config.id)
            formData.append(`configs[${configIndex}][id]`, config.id);
          formData.append(
            `configs[${configIndex}][comment]`,
            config.comment ?? ""
          );
          formData.append(
            `configs[${configIndex}][completeNextConsult]`,
            config.completeNextConsult ?? false
          );
          formData.append(
            `configs[${configIndex}][completed]`,
            config.completed ?? false
          );
          formData.append(
            `configs[${configIndex}][meetsStandard]`,
            config.meetsStandard ?? false
          );
          formData.append(
            `configs[${configIndex}][needsCorrection]`,
            config.needsCorrection ?? false
          );
          formData.append(
            `configs[${configIndex}][seeRecommendation]`,
            config.seeRecommendation ?? false
          );

          configIndex++;
        });
      });
    });

    return formData;
  }

  if (isDataLoading) {
    return (
      <Box className="loader">
        <CircularProgress />
      </Box>
    );
  }

  const handleFileUpload = (e) => {
    if (e.target.files) {
      const files = e.target.files;
      const maxLimitFiles = size(files) + size(file);
      maxLimitFiles > 10 ? setIsErrors(true) : setIsErrors(false);
      maxLimitFiles <= 10
        ? setFile((prev) => (prev ? [...prev, ...files] : [...files]))
        : null;
    }
  };
  const handleUndoFileSelection = (items) => {
    const deleteFile = file.filter((item) =>
      items.name ? item.name != items.name : item.id !== items.id
    );
    setFile(deleteFile);

    items?.attachmentUrl
      ? setAttachments((prevAttachments) =>
          prevAttachments.map((attachment) =>
            attachment.id === items.id
              ? { ...attachment, isToDelete: true }
              : attachment
          )
        )
      : null;
  };

  // On Submit function
  const onSubmit = (data) => {
    setIsChange(false);
    const dataToSend = transformData(data);
    if (!isEdit) {
      addVisitReport(dataToSend);
      return;
    }
    updateVisitReport(dataToSend);
    return;
  };

  return (
    <>
      <BaseBlockerModal2
        hasChange={isChange}
        setHasChange={setIsChange}
        isDirty={isDirty}
        dirtyFields={dirtyFields}
      />
      <Box
        sx={{
          padding: "5px",
        }}
      >
        <BackButton To={-1} iconDisabled={true} sx={{ marginBottom: "10px" }} />
        <HeaderBox
          visitPlanId={visitPlanId}
          ScreensArray={MealObservation_ScreenNames}
          observerTextCheck={observerTextCheck}
        />
        <FormProvider {...methods}>
          <Box
            sx={{
              maxWidth: "20vw",
              marginBottom: "8px",
            }}
          >
            <GeneratedForm
              loading={isDataLoading}
              oldGrid={false}
              list={generatedFormCheck}
            />
          </Box>

          {sections.map((section, index) => (
            <MealObservationAccordion
              section={section}
              key={section.id}
              sectionIndex={index}
              visitReportTypeId={visitReportTypeId}
            />
          ))}

          {isErrors ? (
            <Typography sx={{ color: "red", marginTop: "10px" }}>
              {" "}
              You can attach a maximum of 10 files{" "}
            </Typography>
          ) : null}
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip
              title={
                size(file) >= 10 ? "You can attach a maximum of 10 files" : null
              }
              placement="top"
            >
              <>
                <Button
                  component="label"
                  variant="outlined"
                  startIcon={<span className="material-icons">attachment</span>}
                  sx={{ marginRight: "1rem", textTransform: "none" }}
                  disabled={size(file) >= 10 ? true : false}
                >
                  {`Select File`}
                  <input
                    type="file"
                    accept=".pdf"
                    hidden
                    multiple
                    onChange={handleFileUpload}
                  />
                </Button>
              </>
            </Tooltip>

            <BaseSubmitButton
              onClick={handleSubmit(onSubmit)}
              isSubmitting={isLoading}
              text="Save"
            />
          </Box>

          {file?.map((file, index) => (
            <Box
              key={index}
              sx={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                component="label"
                variant="outlined"
                startIcon={<span className="material-icons">attachment</span>}
                sx={{ marginRight: "1rem", textTransform: "none" }}
              >
                {file.name ? file.name : file.attachmentUrl.split("/").pop()}
              </Button>
              <span style={{ cursor: "pointer" }}>
                {file && (
                  <ClearIcon
                    variant="outlined"
                    color="error"
                    onClick={() => handleUndoFileSelection(file)}
                  />
                )}
              </span>
            </Box>
          ))}
        </FormProvider>
      </Box>
    </>
  );
}

export default MealObservation;
