import React from "react";
import { Box, Skeleton, Typography } from "@mui/material";

export default function BaseBanner({
  title,
  subTitle,
  titleProps,
  subTitleProps,
  loading,
  bgColor,
  color,
  fontWeight,
  fontSize,
  subTitleLoading,
  marginBottom,
  width,
  alignItems,
  ...rest
}) {
  return (
    <>
      <Box
        sx={{
          marginBottom: marginBottom,
          display: "flex",
          width: width,
          justifyContent: "flex-end",
          alignItems: alignItems || "center",
          flexDirection: "column",
          p: "16px",
          color: color,
          bgcolor: bgColor,
          ...rest.sx,
        }}>
        {loading ? (
          <>
            <Skeleton variant="text" width={200} height={40} />
          </>
        ) : (
          <>
            <Typography
              variant={titleProps?.variant || "h6"}
              sx={{
                fontWeight,
                fontSize,
              }}
              {...titleProps}>
              {title}
            </Typography>
            <>
              {subTitle || subTitleLoading ? (
                <>
                  {subTitleLoading ? (
                    <Skeleton variant="text" width={200} height={40} />
                  ) : (
                    <Typography
                      variant={subTitleProps?.variant || "h6"}
                      sx={{
                        fontWeight,
                      }}
                      {...subTitleProps}>
                      {subTitle}
                    </Typography>
                  )}
                </>
              ) : undefined}
            </>
          </>
        )}
      </Box>
    </>
  );
}
