import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Typography, Grid, Divider } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useGetByIdDefaultOptionQuery } from "../../../../../../store/apis/GenericApis";
import {
  useAddSystemMessageMutation,
  useGetSystemMessageByIdQuery,
  useUpdateSystemMessageMutation,
} from "../../../../../../store/apis/SystemMessagesAPIs";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import BaseRichTextEditor from "../../../../../shared/baseForm/BaseRichTextEditor";
import { EditSkeleton } from "../../../../admin.overlays";
import { API_ENDPOINTS } from "../../../../../../store/store.constants";
import dayjs from "dayjs";
import { BaseButton } from "components/shared/BaseButton";
import GeneratedForm from "../../../../../shared/generatedForm/GeneratedForm";
import { SYSTEM_MESSAGE_FIELDS } from "./forms/forms.constants";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { isHtmlEmpty } from "utils/ValidationRegex";
const { CORPORATE_GROUPS, FACILITIES, USERS } = API_ENDPOINTS;

function AddSystemMessage() {
  // States Declaration

  const [hiddenList, setHiddenList] = useState([]);
  const [defaultOptionsList, setDefaultOptionsList] = useState([
    [],
    [],
    [],
    [],
  ]);

  // Hooks Declaration
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const currentSysMessageId = searchParams.get("id");

  // Mutation Section
  const [
    addSystemMessage,
    { isError: addError, isSuccess: addSuccess, isLoading: addLoading },
  ] = useAddSystemMessageMutation();
  const [
    updateSystemMessage,
    {
      isError: updateError,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
    },
  ] = useUpdateSystemMessageMutation();

  // veriables Declaration
  const isError = addError || updateError;
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitting = addLoading || updateLoading;
  const isEdit = !!currentSysMessageId;
  const {
    data: currentSystemMessage,
    isFetching: currentFetching,
    isSuccess: currentSuccess,
  } = useGetSystemMessageByIdQuery(currentSysMessageId, { skip: !isEdit });

  const { id: defaultOptionId, name: defaultTypeName } =
    getCorrectOption(currentSystemMessage);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: {
      type: currentSystemMessage?.type || 0,
    },
  });
  // Destructuring
  const {
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
    watch,
    getValues,
  } = methods;

  const type = watch("type") || "0";

  const corporateGroupId = watch("corporateGroupId");
  const facilityId = watch("facilityId");
  const userId = watch("userId");

  const isEditLoading = isEdit && currentFetching;

  useEffect(() => {
    showInputs();
  }, [userId, facilityId, corporateGroupId, type]);

  function showInputs() {
    // Hide Dropdowns Field if type is 0
    if (type == 1) {
      setHiddenList(() => [{ name: "facilityId" }, { name: "userId" }]);
    } else if (type == 2) {
      setHiddenList(() => [{ name: "corporateGroupId" }, { name: "userId" }]);
    } else if (type == 3) {
      setHiddenList(() => [
        { name: "corporateGroupId" },
        { name: "facilityId" },
      ]);
    } else {
      setHiddenList(() => [
        { name: "corporateGroupId" },
        { name: "facilityId" },
        { name: "userId" },
      ]);
    }
  }

  const { data: defaultOptions } = useGetByIdDefaultOptionQuery(
    { url: defaultTypeName, id: defaultOptionId },
    {
      skip: !defaultOptionId || !defaultTypeName || !isEdit,
      refetchOnMountOrArgChange: false,
    }
  );
  // UseEffect Section
  useEffect(() => {
    const data = [...defaultOptionsList];
    data[parseInt(type)] = defaultOptions || []; /* or data[index] = value */
    setDefaultOptionsList(data);
  }, [defaultOptions]);

  useEffect(() => {
    if (currentSuccess && !currentFetching && currentSystemMessage) {
      reset(currentSystemMessage);
    }
  }, [currentSystemMessage]);

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  function getCorrectOption(systemMessage) {
    if (systemMessage) {
      switch (parseInt(systemMessage.type)) {
        case 1:
          return {
            id: systemMessage.corporateGroupId,
            name: CORPORATE_GROUPS,
          };
        case 2:
          return { id: systemMessage.facilityId, name: FACILITIES };
        case 3:
          return { id: systemMessage.userId, name: USERS };
      }
    }
    return { id: null, name: null };
  }

  const onSubmit = (data) => {
    if (isEdit) {
      switch (currentSystemMessage.type) {
        case 1:
          data.corporateGroupId = getValues("corporateGroupId");
          break;
        case 2:
          data.facilityId = getValues("facilityId");
          break;
        case 3:
          data.userId = getValues("userId");
          break;
      }
      updateSystemMessage({
        ...data,
        effectiveDate: dayjs(data?.effectiveDate).isValid()
          ? dayjs(data?.effectiveDate).format("YYYY-MM-DD")
          : null,
        expirationDate: dayjs(data?.expirationDate).isValid()
          ? dayjs(data?.expirationDate).format("YYYY-MM-DD")
          : null,
      });
      return;
    }
    addSystemMessage({
      ...data,
      effectiveDate: dayjs(data?.effectiveDate).isValid()
        ? dayjs(data?.effectiveDate).format("YYYY-MM-DD")
        : null,
      expirationDate: dayjs(data?.expirationDate).isValid()
        ? dayjs(data?.expirationDate).format("YYYY-MM-DD")
        : null,
    });
  };
  //2094 is the branch for making changes to latest
  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <div className="leftActions">
            <BaseButton
              text="Back"
              startIcon={"keyboard_arrow_left"}
              onClick={() => navigate(-1)}
            />
          </div>
          <Divider sx={{ marginBottom: "15px", border: "none" }} />
          <Box
            sx={{
              padding: "8px",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <GeneratedForm
                control={control}
                setValue={setValue}
                list={SYSTEM_MESSAGE_FIELDS}
                hiddenList={hiddenList}
                errors={errors}
                defaultOption={defaultOptions}
              />
              <Grid item xs={12}>
                <BaseRichTextEditor
                  label={"Message"}
                  name={"message"}
                  placeholder=""
                  control={control}
                  validationProps={{
                    required: REQUIRED_ERROR("Message"),
                    pattern: {
                      value: isHtmlEmpty,
                      message: REQUIRED_ERROR("Message"),
                    },
                  }}
                  errors={errors}
                />
              </Grid>
              <Grid item md={12}>
                <BaseSubmitButton
                  fullWidth
                  onClick={handleSubmit(onSubmit)}
                  isSubmitting={isSubmitting}
                  text={isEdit ? "Submit" : "Create"}
                  disabled={
                    dayjs(watch("expirationDate")).format("YYYY-MM-DD") <
                    dayjs(watch("effectiveDate")).format("YYYY-MM-DD")
                  }
                />
              </Grid>
              {isError ? (
                <Typography
                  sx={{
                    color: "error",
                  }}
                >
                  There was an error submitting the form, please try again.
                </Typography>
              ) : undefined}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}

export default AddSystemMessage;
