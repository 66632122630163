import React, { useState } from "react";
import { useGetCorporateTagsQuery } from "../../../../../../store/apis/CorporateGroupTagApis";
import { Button } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../../shared/baseSearch/BaseSearch";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { LocalOffer, KeyboardArrowLeft } from "@mui/icons-material";
import "./Tags.css";

export default function CorporateTags() {
  const SEARCH_PARAM = "search";

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const corporateGroupId = searchParams.get("id");

  const {
    data: corporateGroups,
    isError: corporateGroupsError,
    isFetching: corporateGroupsLoading,
  } = useGetCorporateTagsQuery(
    { corporateGroupId: corporateGroupId, searchText: debouncedSearchValue || "" },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const corporateGroupColumns = [
    {
      field: "tagName",
      headerName: "Tag",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "iconUrl",
      minWidth: 50,
      flex: .5,
      headerName: "Icon",
      headerAlign: "center",
      align: "center",
      cellClassName: "icon-cell",
      renderCell: (params) => {
        return (
          (
            params?.row?.iconUrl.startsWith("http://") ||
            params?.row?.iconUrl.startsWith("https://")
          ) ? 
          (
            <img
              src={params?.row?.iconUrl}
              alt={params?.row?.iconDescription}
              style={{ width: "30px", height: "30px" }}
            />
          ) :
          (
            <span className="material-icons">{params.row.iconUrl}</span>
          )
        );        
      }
    },
    {
      field: "iconDescription",
      minWidth: 100,
      flex: 3,
      headerName: "Description",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={corporateGroups}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `AddTag?corporateGroupId=${corporateGroupId}&id=${params.row.id}`,
              title: "Tag",
            }}
            deleteProps={{
              entityName: params.row?.dietName,
              apiPath: "deleteCorporateTag",
              title: "Tag",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        justifyContent={"space-between"}
        my={"8px"}
        flexWrap={{ xs: "wrap", md: "nowrap" }}>
        <Button
          component={Link}
          to={"/admin/communities/corporateGroups"}
          color={"primary"}
          variant="contained"
          startIcon={<KeyboardArrowLeft />}>
          {"Back to Corporate Group List"}
        </Button>
        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          sx={{ ml: 1 }}
          SEARCH_PARAM={SEARCH_PARAM}
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedSearchValue}
          setSearchParams={setSearchParams}
          isId={true}
          Id={corporateGroupId}
        />
        <Button
          component={Link}
          to={`AddTag?corporateGroupId=${corporateGroupId}`}
          color={"primary"}
          className="baseButton"
          variant="contained"
          endIcon={<LocalOffer />}>
          Add Tag
        </Button>
      </FlexBox>

      <BaseDataGrid
        rows={corporateGroups}
        columns={corporateGroupColumns}
        error={corporateGroupsError}
        loading={corporateGroupsLoading}
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
      />
    </>
  );
}
