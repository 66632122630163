import { useFormContext } from "react-hook-form";

export default function useSafeFormContext({
  control,
  errors,
  setValue,
  reset,
}) {
  try {
    const context = useFormContext();
    return {
      control: context.control,
      errors: context.formState.errors,
      setValue: context.setValue,
      reset: context.reset,
    };
  } catch (error) {
    console.warn("This component must be used within a FormProvider");
    return {
      control: control,
      errors: errors,
      reset: reset,
      setValue: setValue,
    };
  }
}
