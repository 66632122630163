import Grid from "@mui/material/Grid2";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useFacilityConsultingTypesQuery } from "../../../../../../store/apis/FacilityApis";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import { PlaylistAdd } from "@mui/icons-material";

export default function CommunityConsulting() {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 3,
  });

  const {
    data: consultingTypes,
    isLoading,
    isError,
  } = useFacilityConsultingTypesQuery(id, { skip: !id });

  const consultingTypeColumns = [
    {
      field: "consultingTypeName",
      headerName: "Consulting Type",
      flex: 1,
      minWidth: 300,
    },
    {
      field: "rate",
      headerName: "Rate",
      headerAlign: "center",
      cellClassName: "cellCenterAlign",
      flex: 1,
      minWidth: 100,
      type: "number",
      valueFormatter: (value) => currencyFormatter.format(value),
    },
    {
      field: "rateType",
      headerName: "Rate Type",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={consultingTypes}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `addcommunityconsulting?id=${id}&consultingTypeId=${params.row.id}`,
              facilityId: id,
              title: "Consulting Type",
            }}
            deleteProps={{
              entityName: params.row?.consultingTypeName,
              apiPath: "deleteFacilityConsultingType",
              title: "Consulting Type",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <Grid
        sx={{
          my: 1,
        }}
      >
        <BaseButton
          text={"Add Consulting Type"}
          endIcon={<PlaylistAdd />}
          To={`addcommunityconsulting?id=${id}`}
        />
      </Grid>
      <BaseDataGrid
        rows={consultingTypes}
        columns={consultingTypeColumns}
        error={isError}
        loading={isLoading}
      />
    </>
  );
}
