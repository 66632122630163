import { Grid } from "@mui/material";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import React from "react";
import { useFormContext } from "react-hook-form";
import { recipeSettingForm } from "./forms.constants";

export const RecipeSettingsFormV2 = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Grid container spacing={2}>
      <GeneratedForm
        list={recipeSettingForm}
        control={control}
        errors={errors}
      />
    </Grid>
  );
};
