import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Modal, Fade, Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { FormProvider, useForm } from "react-hook-form";
import { BaseButton } from "components/shared/BaseButton";
// Shared Components
import HeaderBox from "../../../../../shared/HeaderBox";
import GeneratedForm, {
  FORM_TYPES,
} from "../../../../../../shared/generatedForm/GeneratedForm";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";

// constants
import { AddMemo_ScreenNames, EmailField, RadioGroup } from "./form.constants";
import { SCREEN_CONSTANT } from "../../../constants";
import { useNavigate, useSearchParams } from "react-router-dom";
// APIs
import {
  useVisitReportsMutation,
  useGetDietitianMemoReportByIdQuery,
  useUpdateDietitianMemoReportMutation,
} from "../../../../../../../store/apis/ConsultantApis";

import { REQUIRED_ERROR } from "utils/errorMessages";
import { theme } from "styles/theme";
import { BackButton } from "components/shared/BackButton";
import { isEqual } from "lodash";
import BaseBlockerModal2 from "components/shared/blockerModal/BaseBlockerModalv2";

function MemoReport() {
  // constants declartions

  const [count, setCount] = useState(1);
  const [deletedFields, setDeletedFields] = useState([]);
  const [actionType, setActionType] = useState({});
  const [fields, setFields] = useState([]);
  const [searchParams] = useSearchParams();
  const visitPlanId = searchParams.get(SCREEN_CONSTANT.VISIT_PLAN_ID_QUERY);
  const visitId = searchParams.get("visitId");
  const id = searchParams.get("id");
  const visitReportTypeId = searchParams.get("visitReportTypeId");

  const [openDelModal, setOpenDelModal] = useState(false);
  const [IDDel, setIDDel] = useState();
  const navigate = useNavigate();

  // unsaved changes functionality here
  const [isChange, setIsChange] = useState(false);

  // useForm Hook

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    reset,
    handleSubmit,
    formState: { isDirty, dirtyFields },
  } = methods;
  // isEdit able
  const isEdit = !!id;

  // APIs calls
  const [addMemoReport, { isLoading, isSuccess }] = useVisitReportsMutation();
  const { data: reportData } = useGetDietitianMemoReportByIdQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });
  const [
    updateMemoReport,
    { isLoading: updateLoading, isSuccess: updateSuccess },
  ] = useUpdateDietitianMemoReportMutation();

  useEffect(() => {
    if (isEdit && reportData) {
      const action = {};
      reportData?.itemList.forEach((item, index) => {
        action[`type_${index}`] = item?.actionType;
      });
      setActionType(action);
    }
  }, [isEdit, reportData]);

  const isLoadingTrue = isLoading || updateLoading;

  const isSuccessTrue = isSuccess || updateSuccess;

  useEffect(() => {
    if (isSuccessTrue) {
      navigate(`/Consultant/MyRosters/VisitDetails?visitPlanId=${visitPlanId}`);
    }
  }, [isSuccessTrue]);

  // generateForm List

  useEffect(() => {
    if (actionType.type_0 && isEdit) {
      const generatedFormlist = [
        {
          id: 0,
          formFields: [
            {
              props: {
                name: `addMemo_0`,
                label: `Add Memo`,
                multiline: true,
                minRows: 2.5,
              },
              validationProps: {
                required: REQUIRED_ERROR("Add Memo"),
              },

              size: 4,
            },
            {
              props: {
                name: `type_0`,
                defaultValue: actionType.type_0 || 1,
              },
              validationProps: {},
              options: RadioGroup,
              type: FORM_TYPES.Radio,
              size: 1,
            },
          ],
        },
      ];
      setFields(generatedFormlist);
    } else if (!isEdit) {
      const generatedFormlist = [
        {
          id: 0,
          formFields: [
            {
              props: {
                name: `addMemo_0`,
                label: `Add Memo`,
                multiline: true,
                minRows: 2.5,
              },
              validationProps: {
                required: REQUIRED_ERROR("Add Memo"),
              },
              size: 4,
            },
            {
              props: {
                name: `type_0`,
                defaultValue: 1,
              },
              validationProps: {},
              options: RadioGroup,
              type: FORM_TYPES.Radio,
              size: 1,
            },
          ],
        },
      ];
      setFields(generatedFormlist);
    }
  }, [actionType, isEdit]);

  useEffect(() => {
    if (isEdit && reportData?.itemList && actionType && actionType?.type_0) {
      const resultObject = {};
      reportData?.itemList.forEach((item, index) => {
        resultObject[`addMemo_${index}`] = item?.memo;
        resultObject[`dietitianMemoId_${index}`] = item?.dietitianMemoItemId;
      });
      const newObject = {
        isConfidential: reportData?.isConfidential,
        visitReportDescription: reportData?.visitReportDescription,
        memoTo: reportData?.memoTo,
        visitReportId: reportData?.visitReportId,
        dietitianMemoReportId: reportData?.dietitianMemoReportId,
        ...resultObject,
      };
      reset(newObject);

      // Initialize a new array to hold the new fields
      let newFields = [];

      for (let i = 1; i < reportData?.itemList.length; i++) {
        // Use the functional form of setCount
        setCount((prevCount) => {
          const newField = {
            id: prevCount,
            formFields: [
              {
                props: {
                  name: `addMemo_${prevCount}`,
                  label: `Add Memo`,
                  multiline: true,
                  minRows: 2.5,
                },

                size: 4,
              },
              {
                props: {
                  name: `type_${prevCount}`,
                  defaultValue: actionType?.[`type_${prevCount}`] || 1,
                },
                validationProps: {},
                options: RadioGroup,
                type: FORM_TYPES.Radio,
                size: 1,
              },
            ],
          };
          newFields.push(newField);

          return prevCount + 1;
        });
      }
      setFields((prevFields) => [...prevFields, ...newFields]);
    }
  }, [reportData, actionType]);

  // Add more fields
  const handleAddMore = () => {
    setCount((prevCount) => prevCount + 1);

    const newField = {
      id: count,
      formFields: [
        {
          props: {
            name: `addMemo_${count}`,
            label: `Add Memo`,
            multiline: true,
            minRows: 2.5,
          },

          size: 4,
        },
        {
          props: {
            name: `type_${count}`,
            defaultValue: actionType?.[`type_${count}`] || 1,
          },
          validationProps: {},
          options: RadioGroup,
          type: FORM_TYPES.Radio,
          size: 1,
        },
      ],
    };

    setFields((prevFields) => [...prevFields, newField]);
  };

  // handleDelete Function here
  const handleDelete = () => {
    setFields((prevFields) =>
      prevFields.filter((field) => {
        if (field.id === IDDel) {
          setDeletedFields((prevDeletedFields) => [
            ...prevDeletedFields,
            field.id,
          ]);
        }
        return field.id !== IDDel;
      })
    );
    setOpenDelModal(false);
  };

  // transform data
  const transformFormData = (rawData) => {
    const transformedData = {
      visitReportId: rawData?.visitReportId || 0,
      visitId: Number(visitId),
      visitReportDescription: rawData?.visitReportDescription,
      visitReportTypeId: Number(visitReportTypeId),
      dietitianMemoReportId: rawData?.dietitianMemoReportId || 0,
      memoTo: rawData.memoTo,
      isConfidential: rawData.isConfidential,
      itemList: [],
    };

    for (const key in rawData) {
      if (key.startsWith("addMemo")) {
        const index = key.split("_")[1];
        const actionTypeKey = `type_${index}`;
        const actionTypeValue = rawData[actionTypeKey];
        const dietitianMemoIdKey = `dietitianMemoId_${index}`;
        const dietitianMemoIdValue = rawData[dietitianMemoIdKey];

        const memoItem = {
          dietitianMemoItemId: dietitianMemoIdValue || 0,
          memo: rawData[key],
          actionType: Number(actionTypeValue) || 0,
        };

        transformedData.itemList.push(memoItem);
      }
    }

    return transformedData;
  };

  // onSubmit Function
  function onSubmit(data) {
    setIsChange(false);
    const filteredData = {};

    for (const key in data) {
      const indexMatch = key.match(/\d+$/);
      const indexNumber = indexMatch ? Number(indexMatch[0]) : null;
      if (indexNumber && deletedFields.includes(indexNumber)) {
        continue;
      }
      filteredData[key] = data[key];
    }
    const transformedData = transformFormData(filteredData);

    if (filteredData && !isEdit) {
      addMemoReport(transformedData);
    }
    if (filteredData && isEdit) {
      updateMemoReport(transformedData);
    }
  }

  function DeleteModal({ id, open, close }) {
    return (
      <Modal
        open={open}
        onClose={close}
        aria-labelledby={`copy-to-corporate-group-modal-title-${id}`}
        aria-describedby={`copy-to-corporate-group-modal-description-${id}`}
      >
        <Fade in={open}>
          <Box className={"deleteModal"}>
            <Box className="modalContent">
              <p>
                <span className="material-icons modalIcon" color={"warning"}>
                  report
                </span>
              </p>
              <Box>
                <Typography
                  id={`delete-modal-title-${id}`}
                  variant="h6"
                  component="h2"
                  className="modalTitle"
                >
                  {`Are you sure you want to delete this item"?`}
                </Typography>
                <Typography
                  id={`delete-modal-description-${id}`}
                  sx={{ mt: 2 }}
                >
                  {`This item will be deleted immediately.`}
                </Typography>
              </Box>
            </Box>
            <Divider sx={{ my: 0.5 }} />
            <Box
              className="deleteActions"
              sx={{
                flex: true,
              }}
            >
              <BaseButton text="Cancel" onClick={close} colors="warning" />
              <BaseButton
                text="Delete"
                marginLeft="10px"
                colors="error"
                onClick={() => {
                  handleDelete();
                }}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
    );
  }

  return (
    <>
      <BaseBlockerModal2
        hasChange={isChange}
        setHasChange={setIsChange}
        dirtyFields={dirtyFields}
        isDirty={isDirty}
      />
      <Box
        sx={{
          padding: "10px",
        }}
      >
        <FormProvider {...methods}>
          <BackButton
            To={`/Consultant/MyRosters/VisitDetails?visitPlanId=${visitPlanId}`}
            iconDisabled={true}
            sx={{ marginBottom: "10px" }}
          />
          <HeaderBox
            visitPlanId={visitPlanId}
            ScreensArray={AddMemo_ScreenNames}
            observerTextCheck={{
              name: "Consultant Memo",
              color: theme.palette.maroon.main,
            }}
          />
          <Box
            sx={{
              width: "100%",
              marginY: "10px",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <BaseSubmitButton
              text="Save Report"
              onClick={handleSubmit(onSubmit)}
              isSubmitting={isLoadingTrue}
            />
          </Box>
          <Grid
            container
            sx={{
              marginTop: "-3rem",
            }}
            spacing={1.5}
          >
            <GeneratedForm oldGrid={false} list={EmailField} />
          </Grid>
          <Box
            sx={{
              marginTop: "10px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "700",
              }}
            >
              Intervention Type
            </Typography>

            {fields.map((field, index) => (
              <Box
                sx={{
                  backgroundColor: "#ECECEC",
                  borderRadius: "5px",
                  marginBottom: "10px",
                  padding: 1,
                }}
                key={index}
              >
                <Grid
                  container
                  spacing={2}
                  md={11}
                  sx={{
                    margin: 1,
                  }}
                >
                  <GeneratedForm oldGrid={false} list={field?.formFields} />
                  <Grid item size={{ sm: 7 }}>
                    <Box
                      sx={{
                        float: "right",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: !isEqual(field?.id, 0)
                          ? "space-between"
                          : "flex-end",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      {!isEqual(field?.id, 0) ? (
                        <>
                          {openDelModal && (
                            <DeleteModal
                              open={openDelModal}
                              close={() => setOpenDelModal(false)}
                            />
                          )}
                          <Button
                            sx={{
                              backgroundColor: "#A80000",
                              borderRadius: 2,
                              width: "30px",
                              height: "30px",
                              minWidth: "30px",
                            }}
                            onClick={() => {
                              setOpenDelModal(true);
                              setIDDel(field?.id);
                            }}
                          >
                            <span
                              className="material-icons"
                              style={{
                                color: "#FFFFFF",
                                fontSize: "25px",
                                fontWeight: "bold",
                              }}
                            >
                              delete
                            </span>
                          </Button>
                        </>
                      ) : null}

                      <BaseButton
                        marginLeft={"10px"}
                        text={"Add"}
                        colors="white"
                        onClick={handleAddMore}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </Box>
        </FormProvider>
      </Box>
    </>
  );
}

export default MemoReport;
