import React from "react";
import Field from "./Field";
import { Box, Grid, Grid2, Typography } from "@mui/material";

export default function FieldGroup({
  fieldGroup,
  isAllCompleted,
  isNotApplicable,
}) {
  const { size, name, fields } = fieldGroup;

  if (name !== "-") {
    return (
      <Grid2 size={size}>
        <Typography fontWeight={"800"}>{name}</Typography>

        <Box p={1}>
          <Grid container spacing={1}>
            {fields?.map((field) => (
              <Field
                isAllCompleted={isAllCompleted}
                isNotApplicable={isNotApplicable}
                key={field.id}
                field={field}
              />
            ))}
          </Grid>
        </Box>
      </Grid2>
    );
  }

  return (
    <Grid2 size={size}>
      <Grid container spacing={1}>
        {fields?.map((field) => (
          <Field
            key={field.id}
            isAllCompleted={isAllCompleted}
            isNotApplicable={isNotApplicable}
            field={field}
          />
        ))}
      </Grid>
    </Grid2>
  );
}
