import React from "react";

import ProtectedRoute from "../ProtectedRoute";
import { PAGE_ROLES, Roles } from "components/shared/constants";

// Plateful/recipes
import PlateFul from "components/plateFul/recipes/PlateFul";
import ShareRecipe from "components/plateFul/recipes/shareRecipe/ShareRecipe";
import AddRecipe from "components/plateFul/recipes/addRecipe/AddRecipe";

import SubRecipe from "components/plateFul/recipes/optionsDetails/subRecipe/SubRecipe";
import MenuUsage from "components/plateFul/recipes/optionsDetails/menuUsage/MenuUsage";
import RecipeChangeHistory from "components/plateFul/recipes/optionsDetails/changeHistory/ChangeHistory";
import RecipeDiet from "components/plateFul/recipes/optionsDetails/recipeDiet/RecipeDiet";
import { RecipesIngredients } from "components/plateFul/recipes/optionsDetails/recipesIngredients/RecipesIngredients";
import { DislikesIntolerances } from "components/plateFul/recipes/optionsDetails/dislikesIntolerances/DislikesIntolerances";
import AddDislikesIntolerances from "components/plateFul/recipes/optionsDetails/dislikesIntolerances/addDislikesIntolerances/AddDislikesIntolerances";
import { RecipeTags } from "components/plateFul/recipes/optionsDetails/recipeTags/RecipeTags";
import { OptionsDetails } from "components/plateFul/recipes/optionsDetails/OptionsDetails";
import RecipeNutrientAnalysis from "components/plateFul/recipes/optionsDetails/recipeNutrientAnalysis/RecipeNutrientAnalysis";

import VendorCost from "components/plateFul/recipes/optionsDetails/vendorCost/VendorCost";
import { AddRecipesIngredients } from "components/plateFul/recipes/optionsDetails/recipesIngredients/addRecipesIngredients/AddRecipesIngredients";
import { AddRDiet } from "components/plateFul/recipes/optionsDetails/recipeDiet/addRDiet/AddRDiet";
import AddVendorCost from "components/plateFul/recipes/optionsDetails/vendorCost/addVendorCost/AddVendorCost";
import RecipeIngredientsReOrder from "components/plateFul/recipes/optionsDetails/recipesIngredients/recipeIngredientsReOrder/RecipeIngredientsReOrder";
import VendorRecipeProducts from "components/plateFul/recipes/optionsDetails/vendorCost/vendorRecipeProducts/VendorRecipeProducts";
import RecipeDetailCard from "components/plateFul/recipes/recipeDetailCard/RecipeDetailCard";
import RecipePrint from "components/plateFul/recipes/optionsDetails/recipePrint/RecipePrint";

// PlateFul/menus
import AddMenus from "components/plateFul/menus/addMenus/AddMenus";
import ShareMenu from "components/plateFul/menus/shareMenu/ShareMenu";
import Menus from "components/plateFul/menus/Menus";
import PrintItemMenus from "components/plateFul/menus/printItem/PrintItem";
import { MenuDays } from "components/plateFul/menus/menuDays/MenuDays";
import CoporateGroupAccess from "components/plateFul/menus/coporateGroupAccess/coporateGroupAccess";
import CopyOtherMenuItems from "components/plateFul/menus/copyOtherMenuItems/CopyOtherMenuItems";
import { ChangeHistoryMenu } from "components/plateFul/menus/changeHistory/ChangeHistoryMenu";
import MissingVendor from "components/plateFul/menus/missingVendor/MissingVendor";
import RecipeReprint from "components/plateFul/menus/recipeReprint/RecipeReprint";
import OrderTransmission from "components/plateFul/menus/orderTransmission/OrderTransmission";
import NewOrder from "components/plateFul/menus/orderTransmission/newOrder/NewOrder";
import { HeadingDetails } from "components/plateFul/menus/missingVendor/headingDetails/HeadingDetails";
import MenuBulkProductEntry from "components/plateFul/menus/menuBulkProductEntry/MenuBulkProductEntry";
import CustomizePostedMenu from "components/plateFul/menus/customizePostedMenu/CustomizePostedMenu";
import { MenuPlanner } from "components/plateFul/menus/menuPlanner/MenuPlanner";
import HepResults from "components/plateFul/menus/hep/HepResults";
import NutrientAnalysisMenuResults from "components/plateFul/menus/nutrientAnalysisMenu/NutrientAnalysisMenuResults";


// plateful recipe from admin routes
import AddIngredient from "components/admin/setupMenu/recipeSetup/Ingredients/addIngredient/AddIngredient";
import VendorIngredient from "components/admin/setupMenu/recipeSetup/Ingredients/vendorIngredient/VendorIngredient";
import AddVendorIngredient from "components/admin/setupMenu/recipeSetup/Ingredients/vendorIngredient/addVendorIngredient";

const { PLATEFUL, PLATEFUL_MENUS } = PAGE_ROLES;

const PlateFulMain =
  //plateful routing main page
  {
    path: "plateFul",
    element: (
      <ProtectedRoute allowedRoles={PLATEFUL}>
        <PlateFul />
      </ProtectedRoute>
    ),
  };
//plateful routing share
const PlateFulShare = {
  path: "plateFul/Recipes/share",
  element: (
    <ProtectedRoute allowedRoles={PLATEFUL}>
      <ShareRecipe />
    </ProtectedRoute>
  ),
};
//plateful routing new Recipe page
const PlateFulRecipesAdd = {
  path: "plateFul/Recipes/addRecipe",
  element: (
    <ProtectedRoute allowedRoles={PLATEFUL}>
      <AddRecipe />
    </ProtectedRoute>
  ),
};

//plateful recipe option list
const PlateFulRecipesList = {
  path: "plateFul/Recipes",
  element: (
    <ProtectedRoute allowedRoles={PLATEFUL}>
      <OptionsDetails />
    </ProtectedRoute>
  ),
  children: [
    { path: "subRecipe", element: <SubRecipe /> },
    { path: "history", element: <RecipeChangeHistory /> },
    { path: "menu", element: <MenuUsage /> },
    { path: "diet", element: <RecipeDiet /> },
    { path: "diet/addrdiet", element: <AddRDiet /> },
    { path: "nutrient", element: <RecipeNutrientAnalysis /> },
    { path: "dislikes", element: <DislikesIntolerances /> },
    {
      path: "dislikes/addDislikesIntolerances",
      element: <AddDislikesIntolerances />,
    },
    { path: "RecipeTags", element: <RecipeTags /> },
    { path: "cost", element: <VendorCost /> },
    { path: "cost/vendorProducts", element: <VendorRecipeProducts /> },
    { path: "cost/addVendorCost", element: <AddVendorCost /> },
    { path: "ingredients", element: <RecipesIngredients /> },
    { path: "edit", element: <AddRecipe /> },
    { path: "recipeDetail", element: <RecipeDetailCard /> },
    { path: "print", element: <RecipePrint /> },
    {
      path: "ingredients/addingredients",
      element: <AddRecipesIngredients />,
    },
    {
      path: "ingredients/reorder",
      element: <RecipeIngredientsReOrder />,
    },
  ],
};
// plateful menu routes start
const PlateFulMenus = {
  path: "plateFul/Menus",
  element: (
    <ProtectedRoute
      allowedRoles={PLATEFUL_MENUS}
      redirectToIfForbidden={"/plateFul"}>
      <Menus />
    </ProtectedRoute>
  ),
};
const PlateFulAddMenus = {
  path: "plateFul/Menus/AddMenus",
  element: (
    <ProtectedRoute allowedRoles={[...PLATEFUL, Roles.Advanced_User]}>
      <AddMenus />
    </ProtectedRoute>
  ),
};
const PlateFulMenusPrint = {
  path: "plateFul/Menus/print",
  element: (
    <ProtectedRoute allowedRoles={PLATEFUL}>
      <PrintItemMenus />
    </ProtectedRoute>
  ),
};
const PlateFulMenusDays = {
  path: "plateFul/Menus/MenuDays",
  element: (
    <ProtectedRoute allowedRoles={PLATEFUL}>
      <MenuDays />
    </ProtectedRoute>
  ),
};
const PlateFulMenusChangeHistory = {
  path: "plateFul/Menus/ChangeHistory",
  element: (
    <ProtectedRoute allowedRoles={[...PLATEFUL, Roles.Advanced_User]}>
      <ChangeHistoryMenu />
    </ProtectedRoute>
  ),
};
const PlateFulShareMenu = {
  path: "plateFul/Menus/ShareMenu",
  element: (
    <ProtectedRoute allowedRoles={[...PLATEFUL, Roles.Advanced_User]}>
      <ShareMenu />
    </ProtectedRoute>
  ),
};
const PlateFulMenuCorporate = {
  path: "plateFul/Menus/corporateGroup",
  element: (
    <ProtectedRoute allowedRoles={PLATEFUL}>
      <CoporateGroupAccess />
    </ProtectedRoute>
  ),
};
const PlateFulMenuCopy = {
  path: "plateFul/Menus/copyOtherMenuItems",
  element: (
    <ProtectedRoute allowedRoles={PLATEFUL}>
      <CopyOtherMenuItems />
    </ProtectedRoute>
  ),
};
const PlateFulMenuMissingVendor = {
  path: "plateFul/Menus/MissingVendor",
  element: (
    <ProtectedRoute allowedRoles={PLATEFUL}>
      <MissingVendor />
    </ProtectedRoute>
  ),
};
const PlateFulMenuRecipeReprint = {
  path: "plateFul/Menus/recipeReprint",
  element: (
    <ProtectedRoute allowedRoles={[...PLATEFUL, Roles.Advanced_User]}>
      <RecipeReprint />
    </ProtectedRoute>
  ),
};
const PlateFulMenuOrderTransmission = {
  path: "plateFul/Menus/orderTransmission",
  element: (
    <ProtectedRoute allowedRoles={PLATEFUL}>
      <OrderTransmission />
    </ProtectedRoute>
  ),
};
const PlateFulMenuIrderTransmissionNewOrder = {
  path: "plateFul/Menus/orderTransmission/newOrder",
  element: (
    <ProtectedRoute allowedRoles={PLATEFUL}>
      <NewOrder />
    </ProtectedRoute>
  ),
};
const PlateFulMenuPlanner = {
  path: "plateFul/Menus/MenuPlanner",
  element: (
    <ProtectedRoute allowedRoles={[...PLATEFUL, Roles.Advanced_User]}>
      <MenuPlanner />
    </ProtectedRoute>
  ),
};
const PlateFulHepResults = {
  path: "plateFul/hep/HepResults",
  element: (
    <ProtectedRoute allowedRoles={[...PLATEFUL, Roles.Advanced_User]}>
      <HepResults />
    </ProtectedRoute>
  ),
};
const PlateFulNutrientAnalysisMenuResults = {
  path: "plateFul/nutrientAnalysisMenu/NutrientAnalysisMenuResults",
  element: (
    <ProtectedRoute allowedRoles={[...PLATEFUL, Roles.Advanced_User]}>
      <NutrientAnalysisMenuResults />
    </ProtectedRoute>
  ),
};
const PlateFulMenuMissingVendorRoute = {
  path: "plateFul/Menus/MissingVendors",
  element: (
    <ProtectedRoute allowedRoles={PLATEFUL}>
      <HeadingDetails />
    </ProtectedRoute>
  ),
  children: [
    {
      path: "EditIngredient",
      element: <AddIngredient />,
    },
    {
      path: "VendorList",
      element: <VendorIngredient />,
    },
    {
      path: "AddVendorIngredients",
      element: <AddVendorIngredient />,
    },
  ],
};
const PlateFulMenuBulkEntry = {
  path: "plateFul/Menus/MenuBulkProductEntry",
  element: (
    <ProtectedRoute allowedRoles={[...PLATEFUL_MENUS, Roles.Advanced_User]}>
      <MenuBulkProductEntry />
    </ProtectedRoute>
  ),
};
const PlateFulMenuCustomizePostedMenu = {
  path: "plateFul/Menus/CustomizePostedMenu",
  element: (
    <ProtectedRoute allowedRoles={[...PLATEFUL_MENUS, Roles.Advanced_User]}>
      <CustomizePostedMenu />
    </ProtectedRoute>
  ),
};

export const PlateFulRoutes = {
  PlateFulMain,
  PlateFulShare,
  PlateFulShareMenu,
  PlateFulAddMenus,
  PlateFulMenuCopy,
  PlateFulMenuCorporate,
  PlateFulMenus,
  PlateFulMenusDays,
  PlateFulMenusChangeHistory,
  PlateFulRecipesAdd,
  PlateFulRecipesList,
  PlateFulMenuMissingVendor,
  PlateFulMenusPrint,
  PlateFulMenuRecipeReprint,
  PlateFulMenuOrderTransmission,
  PlateFulMenuIrderTransmissionNewOrder,
  PlateFulMenuPlanner,
  PlateFulHepResults,
  PlateFulNutrientAnalysisMenuResults,
  PlateFulMenuMissingVendorRoute,
  PlateFulMenuBulkEntry,
  PlateFulMenuCustomizePostedMenu,
};
