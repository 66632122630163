/* eslint-disable */
import React, { useMemo, useState } from "react";
import {
  useRecalculateNutrientAnalysisMutation,
  useRecipeNutrientAnalysisQuery,
} from "../../../../../store/apis/RecipeAPIs";
import { useSearchParams } from "react-router-dom";
import { EditSkeleton } from "../../../../admin/admin.overlays";
import { Card, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { BaseButton } from "components/shared/BaseButton";
import { DMStyles } from "styles/Styles";
import { useGetHepContributionsByRecipeQuery } from "store/apis/MenuApis";
import BaseDataGrid from "components/shared/baseDataGrid/BaseDataGrid";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

export default function RecipeNutrientAnalysis() {
  // eslint-disable-next-line no-undef
  const showHep = process.env.REACT_APP_SHOW_HEP === "true";

  const [searchParams] = useSearchParams();
  const recipeId = searchParams.get("recipeId");
  const [showMore, setShowMore] = useState(false);
  const {
    data: results,
    isFetching: loading,
    // isError,
  } = useRecipeNutrientAnalysisQuery(recipeId, {
    skip: !recipeId,
  });

  const { data: hepContributions, isLoading: hepLoading } =
    useGetHepContributionsByRecipeQuery(recipeId, {
      skip: !recipeId,
    });

  const load = loading || hepLoading;

  const [recalculate, { isLoading: isSubmitting }] =
    useRecalculateNutrientAnalysisMutation();

  const nutrients = results?.data?.nutrients?.map((item) => ({
    ...item,
    id: item?.nutrientId,
  }));

  const nutrientsColumn = useMemo(() => {
    if (results?.data?.nutrients) {
      let columns = [
        {
          field: "yield",
          headerName: "% Yield",
          minWidth: 65,
        },
        {
          field: "ingredient",
          headerName: "INGREDIENT",
          renderCell: (params) => {
            return (
              <Box display={"flex"} gap={0.5}>
                {params?.row?.problems && params.row.problems !== 0 ? (
                  <Tooltip title={params?.row?.problems}>
                    <span
                      style={{
                        marginRight: "-18px",
                        color: "#DE6D00",
                      }}
                      className="material-icons"
                    >
                      info_outlined
                    </span>
                  </Tooltip>
                ) : null}
                <Typography>{params?.row?.ingredient}</Typography>
              </Box>
            );
          },
          minWidth: 300,
        },
      ];
      const dynamicColumns = results?.data?.nutrients?.map((nutrient) => {
        return {
          field: `nutrient_${nutrient.nutrientId}`,
          headerName: (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              <div>
                {nutrient.nutrient.abbreviation ==
                nutrient.nutrient.unitOfMeasure ? (
                  nutrient.nutrient.abbreviation
                ) : (
                  <>
                    {nutrient.nutrient.abbreviation == "Carbohydrates"
                      ? "Carbs"
                      : nutrient.nutrient.abbreviation}
                    <div>{nutrient.nutrient.unitOfMeasure}</div>
                  </>
                )}
              </div>
            </Box>
          ),
          headerAlign: "center",
          align: "center",
          valueFormatter: (value) => {
            return value ? value.toFixed(2) : "0.00";
          },
          minWidth: 110,
        };
      });

      return [...columns, ...dynamicColumns];
    }
    return [
      {
        field: "yield",
        headerName: "% Yield",
        minWidth: 100,
      },
      {
        field: "ingredient",
        headerName: "INGREDIENT",
        minWidth: 100,
      },
    ];
  }, [results?.data?.nutrientDetails]);

  const ingredientNutrients = useMemo(() => {
    if (results?.data?.nutrientDetails && results?.data?.ingredients) {
      let ingredientData = [];

      results?.data?.ingredients?.forEach((ingredient) => {
        let ingredientObj = {
          id: ingredient.ingredientId,
          ingredient: ingredient.name,
          yield: `${ingredient.percentToIncludeInAnalysis || 0}`,
          sortOrder: ingredient.sortOrder,
          problems: 0,
        };
        results?.data?.nutrientDetails
          ?.filter((x) => x.ingredientId == ingredient.ingredientId)
          ?.forEach((detail) => {
            ingredientObj[`nutrient_${detail.nutrientId}`] =
              detail.nutrientQuantity;
          });
        results?.data?.nutrients
          ?.filter((x) => x.nutrientId == ingredient.ingredientId)
          ?.forEach((detail) => {
            ingredientObj[`nutrient_${detail.nutrientId}`] =
              detail.nutrientQuantity;
          });
        ingredientData.push(ingredientObj);
      });

      results?.data?.nutrients?.forEach((nutrient) => {
        ingredientData.forEach((ingredient) => {
          ingredient.problems = nutrient.problems;
        });
      });

      let totalsRow = {
        id: 0,
        nutrient: "",
        ingredient: <strong>Total</strong>,
        problems: 0,
      };
      results?.data?.nutrients?.forEach((nutrient) => {
        totalsRow[`nutrient_${nutrient.nutrientId}`] = nutrient.quantity;
      });

      ingredientData = ingredientData.sort((a, b) => b.sortOrder - a.sortOrder);

      ingredientData.push(totalsRow);

      return ingredientData;
    }

    return [];
  }, [results?.data?.nutrientDetails]);

  //  split array by half
  const splitNutrientsByMacroMicro = (array) => {
    const macros = array?.filter(
      (item) => item.nutrient?.isMacroNutrient === true
    );
    const micros = array?.filter(
      (item) => item.nutrient?.isMacroNutrient === false
    );
    const truncatedMicros = micros?.slice(0, 9);
    return [macros, showMore ? micros : truncatedMicros];
  };

  const splitNutrientDetailsByMacroMicro = (array) => {
    const macros = array?.filter((item) =>
      item.some((innerItem) => innerItem.isMacroNutrient === true)
    );
    const micros = array?.filter((item) =>
      item.some((innerItem) => innerItem.isMacroNutrient === false)
    );
    return [macros, micros];
  };

  const nutrientsData = splitNutrientsByMacroMicro(nutrients);

  const detailedNutrients = results?.data?.nutrientDetails?.map((item) => ({
    ...item,
    id: `${item?.nutrientId}_${item?.ingredientId}_${crypto.randomUUID()}`,
  }));
  // Group objects by sortOrder and store it in array
  const groupByNutrientId = (array) => {
    return array?.reduce((acc, item) => {
      const { sortOrder } = item;
      if (!acc[sortOrder]) {
        acc[sortOrder] = [];
      }
      acc[sortOrder].push(item);
      return acc;
    }, {});
  };
  const groupedNutrients = Object.values(
    groupByNutrientId(detailedNutrients) || []
  );

  const nutrientsDetailsData =
    splitNutrientDetailsByMacroMicro(groupedNutrients);

  const incompleteNutritionalAnalysisBanner = (
    <div
      style={{
        fontWeight: "800",
        fontSize: "20px",
        color: "#DE6D00",
        backgroundColor: "#DE6D001A",
        padding: "16px",
        margin: "8px",
        borderRadius: "4px",
      }}
    >
      Nutritional Analysis is incomplete due to missing information in some
      ingredients in this recipe.
    </div>
  );

  const hepColumns = [
    {
      field: "hepTextName",
      headerName: "HEP Category Name",
      flex: 1.8,
      minWidth: 150,
      headerAlign: "center",
      renderCell: (params) => (
        <Box display={"flex"} justifyContent={"left"}>
          <Box>
            <Typography variant="body1" fontWeight="bold">
              {params?.row?.hepTextName}
            </Typography>
            <Typography
              variant="body2"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {params?.row?.description}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: "hepFinalContributions",
      headerName: "HEP Equivalent",
      headerAlign: "center",
      valueFormatter: (value) => {
        return value?.toFixed(2);
      },
      flex: 1,
    },
  ];

  return (
    <>
      {load ? (
        <EditSkeleton />
      ) : (
        <Card
          sx={{
            height: { md: "90%", xl: "90%" },
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              padding: "5px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              {/* <Typography
                sx={{
                  fontSize: "32px",
                  fontWeight: "600",
                  lineHeight: "43.65px",
                }}
              >
                {results?.data?.recipeName}
              </Typography> */}
              {/* <WarningText
              margin={"5px"}
              sx={{ marginLeft: "0px !important" }}
              text={
                "Please see Menu Instruction Booklet for Menu/Recipe Nutrient Analysis limitations."
              }
              width={"60%"}
            /> */}
            </Box>
            {results?.data?.nutrients?.some((obj) => obj.problems !== 0)
              ? incompleteNutritionalAnalysisBanner
              : null}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignItems: "center",
                  paddingBottom: "10px",
                }}
              >
                {/* <Typography
                  sx={{
                    fontSize: "19px",
                    fontWeight: "600",
                    lineHeight: "27.28px",
                  }}
                >
                  {results?.data?.servingSize
                    .replace(/[()]/g, "") // Remove the parentheses
                    .replace("ServingSize", "Serving Size")}
                </Typography> */}
                <BaseButton
                  text={"Recalculate"}
                  isSubmitting={isSubmitting}
                  onClick={() => recalculate(recipeId)}
                  sx={{ backgroundColor: "#008385" }}
                />
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    marginY: "10px",
                    width: "90%",
                  }}
                >
                  {nutrientsData.map((data, ind) => (
                    <Box
                      key={ind}
                      sx={{
                        width: "49%",
                      }}
                    >
                      {data.map((item, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            borderBottom: "1px solid black",
                          }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "700",
                            }}
                          >
                            {item?.nutrient?.name}
                          </Typography>
                          <Typography
                            variant="span"
                            sx={{
                              fontSize: "14px",
                            }}
                          >
                            {item?.quantity +
                              " " +
                              item?.nutrient.unitOfMeasure}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
              </Box> */}
              {/* {nutrients.length !== 0 ? (
                <BaseButton
                  sx={{
                    height: "25px",
                    width: "225px",
                    backgroundColor: "#FFFFFF",
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginBottom: "15px",
                  }}
                  fontColor={"#000000"}
                  border={"1px solid #000000"}
                  onClick={() => setShowMore(!showMore)}
                  text={
                    showMore
                      ? "Show Fewer Micronutrients"
                      : "Show More Micronutrients"
                  }
                />
              ) : null} */}
            </Box>

            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "700",
                  color: DMStyles.Black,
                  borderRadius: "5px",
                  padding: "5px",
                  backgroundColor: "#C8DCE3",
                }}
              >
                Details By Nutrients
              </Typography>
              <Box paddingY={1}>
                <BaseDataGrid
                  rows={ingredientNutrients}
                  columns={CreateBaseColumns(nutrientsColumn)}
                  autoHeight={true}
                  pagination={false}
                  disableColumnSelector
                  disableDensitySelector
                  hideFooterPagination
                  hideFooterRowCount
                  hideFooterSelectedRowCount
                  initialState={{
                    pinnedColumns: { left: ["yield", "ingredient"] },
                  }}
                  pinnedRows={{
                    bottom: ingredientNutrients.find((x) => x.id == 0)
                      ? [ingredientNutrients.find((x) => x.id == 0)]
                      : [],
                  }}
                  getCellClassName={(params) => {
                    if (params.row.id === 0) {
                      return "font-weight-bold";
                    }
                    return "";
                  }}
                  sx={{
                    "& .font-weight-bold": {
                      fontWeight: "bold",
                      backgroundColor: "#C8DCE3",
                    },
                  }}
                />
              </Box>
              {showHep ? (
                <>
                  <Box paddingY={1}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "700",
                        color: DMStyles.Black,
                        borderRadius: "5px",
                        padding: "5px",
                        backgroundColor: "#C8DCE3",
                      }}
                    >
                      Healthy Eating Patterns
                    </Typography>
                  </Box>
                  <BaseDataGrid
                    rows={hepContributions || []}
                    columns={hepColumns}
                    isLoading={hepLoading}
                    autoHeight={false}
                    height={"40vh"}
                    getRowId={(row) => row.hepCategoryId}
                  />
                </>
              ) : null}

              {/* <Box
                sx={{
                  paddingX: "10px",
                  paddingTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {nutrientsDetailsData?.map((nutrient, i) => (
                  <Box
                    key={i}
                    sx={{
                      width: "49%",
                      border: "2px solid black",
                      borderRadius: "10px",
                      padding: "16px",
                    }}
                  >
                    <div style={{ fontSize: "24px", fontWeight: "700" }}>
                      {i === 0 ? "Macronutrients" : "Micronutrients"}
                    </div>
                    {nutrient.map((data, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          marginY: "5px",
                          borderBottom: " 1px solid black",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "30%",
                            fontSize: "18px",
                            fontWeight: "700",
                            lineHeight: "24px",
                          }}
                        >
                          {data[0]?.nutrient}
                        </Typography>
                        <Box
                          sx={{
                            width: "60%",
                          }}
                        >
                          {data.map((item, ind) => (
                            <Box
                              key={ind}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="p"
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  lineHeight: "19px",
                                  fontWeight: "500",
                                }}
                              >
                                {item.problems ? (
                                  <Tooltip title={item.problems}>
                                    <span
                                      style={{
                                        marginRight: "-18px",
                                        color: "#DE6D00",
                                      }}
                                      className="material-icons"
                                    >
                                      info_outlined
                                    </span>
                                  </Tooltip>
                                ) : (
                                  ""
                                )}
                                {item.ingredient}
                              </Typography>
                              <Typography
                                variant="span"
                                sx={{
                                  fontSize: "14px",
                                }}
                              >
                                {item?.nutrientQuantity.toFixed(2) + " "}
                                {item?.baseUnit === "gram"
                                  ? "g"
                                  : item?.baseUnit}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box> */}
            </Box>
          </Box>
        </Card>
      )}
    </>
  );
}
