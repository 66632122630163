import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

import "../../consultantVisit.css";
import {
  FormControlLabel,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "4px",
};

export default function VerifyHoursModal({
  isOpen,
  closeModal,
  visitHours,
  link,
  text,
  editLink,
  planDate,
}) {

  const [confirm, setConfirm] = React.useState(false);

  const toggleConfirm = () => {
    setConfirm(!confirm);
  };

  const calculatedTotalHours = visitHours?.reduce(
    (acc, curr) => curr.totalHours + acc,
    0
  );

  const calculatedTotalMileage = visitHours?.filter((visit) => visit.consultingRateType === 1 && visit.consultingTypeId === 1).reduce(
    (acc, curr) => curr.quantity + acc,
    0
  );

  const calculatedTotalOther = visitHours?.filter((visit) => visit.consultingRateType === 1 && visit.consultingTypeId !== 1).reduce(
    (acc, curr) => curr.quantity + acc,
    0
  );

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box
            sx={{
              background: "#D95100",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "1.4rem",
              borderRadius: "4px 4px 0 0",
            }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="heading">
              Verify hours before submitting
            </Typography>
            <CloseIcon
              onClick={closeModal}
              sx={{
                color: "white !important",
                fontSize: "2rem",
                cursor: "pointer",
              }}
            />
          </Box>

          <Box sx={{ p: 2 }}>
            <TableContainer>
              <Table
                size="small"
                sx={{
                  borderCollapse: "collapse",
                  "& .MuiTableCell-root": { border: "none !important" },
                  "& .MuiTableRow-root": {
                    borderBottom: "1px solid #bdbdbd !important",
                  },
                }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: "#F6F6F6" }}>
                    <TableCell sx={{ width: "30%" }}></TableCell>
                    <TableCell className="table-cell">Time In</TableCell>
                    <TableCell className="table-cell">Time Out</TableCell>
                    <TableCell
                      className="table-cell"
                      sx={{ whiteSpace: "nowrap" }}>
                      Totals
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  xs={{
                    borderBottom: "3px solid red !important",
                  }}>
                  {visitHours.map((visit) => (
                    <TableRow key={visit.id}>
                      <TableCell className="table-cell cell-bold">
                        {visit.consultingTypeName}
                      </TableCell>
                      <TableCell className="table-cell">
                        {visit.timeIn}
                      </TableCell>
                      <TableCell className="table-cell">
                        {visit.timeOut}
                      </TableCell>
                      <TableCell className="table-cell">
                        {visit.consultingRateType == 1
                          ? visit.quantity
                          : visit.totalHours}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer>
              <Table sx={{ tableLayout: "fixed", width: "100%" }}>
                <TableBody>
                  <TableRow>
                    <TableCell
                      sx={{ width: "80%", whiteSpace: "nowrap", textAlign: "right" }}
                      className="total-table-cell border-t border-r">
                      Total Hours
                    </TableCell>
                    <TableCell
                      sx={{ width: "20%", textAlign: "end !important" }}
                      className="total-table-cell border-t">
                      {calculatedTotalHours}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{ width: "80%", whiteSpace: "nowrap", textAlign: "right" }}
                      className="total-table-cell border-t border-r">
                      Total Mileage
                    </TableCell>
                    <TableCell
                      sx={{ width: "20%", textAlign: "end !important" }}
                      className="total-table-cell border-t">
                      {calculatedTotalMileage}
                    </TableCell>
                  </TableRow>
                  {calculatedTotalOther > 0 ? (
                    <TableRow>
                      <TableCell
                        sx={{ width: "80%", whiteSpace: "nowrap", textAlign: "right" }}
                        className="total-table-cell border-t border-r">
                        Total Dollars
                      </TableCell>
                      <TableCell
                        sx={{ width: "20%", textAlign: "end !important" }}
                        className="total-table-cell border-t">
                        {calculatedTotalOther}
                      </TableCell>
                    </TableRow>
                  ) : null }                  
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box sx={{ display: "flex", flexDirection: 'column', marginLeft: "30px", marginRight: "20px" }}>
            <FormControlLabel control={
              <Checkbox checked={confirm} onClick={() => { toggleConfirm(); }} />
              } 
              label="I am confirming that I have reviewed my hours and mileage for this consultant visit." />          
            <Typography variant="caption"
                        sx={{ fontWeight: 700, color: "red", fontStyle: "italic"}}>
              Once this visit is submitted to DiningRD, the report will be marked as Completed and you will not be able to make any more edits to your time/mileage.
            </Typography>
          </Box>          

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "1.4rem",
            }}>
            <Link to={editLink} className="edit-button"
            state={{
              dateOfConsultant: planDate,
            }}>
              Edit Details
            </Link>
            <Button to={link} className="send-to-button"
                disabled={!confirm}>
              {text}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
