import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";
import { FormControl, FormHelperText } from "@mui/material";
import useSafeFormContext from "hooks/useSafeFormContext";
import { getNestedProperty } from "utils/helperFunctions";

export default function BaseMultiSelectSearch({
  control,
  errors,
  // Control and Errors should be avoided for future cases, enclose the GenerateForm inside a FormProvider instead

  validationProps,
  options = [],
  defaultValue,
  ...rest
}) {
  const { control: controlMethod, errors: formErrors } = useSafeFormContext({
    control,
    errors,
  });

  const error = getNestedProperty(formErrors, rest.name);

  return (
    <FormControl fullWidth>
      <Controller
        control={controlMethod}
        name={rest.name}
        rules={{ ...validationProps }}
        defaultValue={defaultValue || []}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            multiple
            disablePortal
            options={options.filter((option) => !value?.includes(option.id))} // Filter selected values from options
            getOptionLabel={(option) => option.label || ""}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, value) => onChange(value.map((v) => v.id))}
            renderInput={(params) => (
              <TextField error={!!error} {...params} {...rest} />
            )}
          />
        )}
      />

      <FormHelperText error={!!error}>{error?.message ?? ""}</FormHelperText>
    </FormControl>
  );
}
