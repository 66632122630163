export const MealCardSetupMenuElements = [
  // {
  //   Name: "Back to Resident List",
  //   Link: "/MealCard",
  //   IsActive: false,
  // },
  {
    Name: "Change History",
    Link: "/MealCard/settings/changehistory",
    IsActive: false,
  },
  {
    Name: "Reserved List",
    Link: "/MealCard/settings/reservedList",
    IsActive: false,
  },
  {
    Name: "Dining Areas",
    Link: "/MealCard/settings/DiningArea",
    IsActive: false,
  },
  {
    Name: "Setup Highlights",
    Link: "/MealCard/settings/highlights",
    IsActive: false,
  },
  {
    Name: "Resident Weights",
    Link: "/MealCard/settings/bulkWeight",
    IsActive: false,
  },
  {
    Name: "Weight Alert",
    Link: "/MealCard/settings/weightAlert",
    IsActive: false,
  },
  {
    Name: "Meal Add-Ons",
    Link: "/MealCard/settings/mealExtrasSelection",
    IsActive: false,
  },
];
