import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  Slide,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import React, { useRef, useState } from "react";
import { DMStyles } from "styles/Styles";

export function AdminCard({ cardProperties }) {
  const containerRef = useRef(null);
  const [checked, setChecked] = useState(false);

  return (
    <Grid item xs={12} md={4}>
      <Card
        ref={containerRef}
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "80px",
          minWidth: "100%",
          maxHeight: "80px",
          overflow: "hidden",
          maxWidth: 345,
          background: theme.palette.primary.contrastText,
          color: theme.palette.primary.main,
          boxShadow: DMStyles.TealBoxShadow,
          borderRadius: DMStyles.BorderRadius,
          transition: "all 0.3s ease-in-out",

          "&:hover": {
            background: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
          },
          "&.active": {
            background: theme.palette.primary.active,
            color: theme.palette.primary.contrastText,
          },
          "&.active:hover": {
            background: theme.palette.primary.dark,
            color: theme.palette.primary.contrastText,
          },
        })}
        className={cardProperties.IsActive ? "adminCard active" : "adminCard"}
        onMouseEnter={() => setChecked(true)}
        onMouseLeave={() => setChecked(false)}
      >
        <Slide
          in={checked}
          unmountOnExit
          direction="down"
          container={containerRef.current}
        >
          <CardActions className="cardActionsRoot">
            {cardProperties.Children.map((data, index) => {
              return (
                <Grid
                  key={index}
                  component={NavLink}
                  to={data.Link}
                  item
                  style={{ textDecoration: "none" }}
                  xs
                  className={"cardActions"}
                >
                  {data.Name}
                </Grid>
              );
            })}
          </CardActions>
        </Slide>

        <CardContent className="adminCardContent">
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            className="adminCardTitle"
          >
            <cardProperties.Icon
              style={{ fontSize: "36px", marginRight: "8px" }}
            />
            {cardProperties.Name}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
