import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, useTheme } from "@mui/material";
import "react-chat-elements/dist/main.css";
import { MessageBox } from "react-chat-elements";
import { DMStyles } from "styles/Styles";

const ChatBody = ({ chatMessage, sendChatMessage }) => {
  const theme = useTheme();
  const aiSessionId = sessionStorage.getItem("aiSessionId");
  let subjectAreaId = 1;

  const handleSubjectAreaChange = (initialChat) => {
    if (initialChat === "Ingredients and Recipes") {
      subjectAreaId = 2;
    }

    handleSubmit(initialChat);
  };

  const handleSubmit = (initialChat) => {
    if (aiSessionId === null || aiSessionId === "") {
      sendChatMessage({
        sessionId: null,
        sender: "user",
        message: initialChat,
        subjectAreaId: subjectAreaId,
      });
    } else {
      sendChatMessage({
        sessionId: aiSessionId,
        sender: "user",
        message: initialChat,
        subjectAreaId: subjectAreaId,
      });
    }
  };

  function scrollMessagesIntoView() {
    document
      .getElementsByClassName("chatBodyDiv")[0]
      .scrollIntoView({ behavior: "smooth", block: "end" });
  }
  // useEffect Section

  useEffect(() => {
    if (chatMessage.length > 0) {
      scrollMessagesIntoView();
    }
  }, [chatMessage]);

  return (
    <>
      <div className="chatBodyDiv">
        {chatMessage.map((message, i) => {
          return (
            <div key={i}>
              <pre className="wrapWhiteSpace">
                {message.sender === "DMAI" ? (
                  <>
                    <span>
                      <MessageBox
                        position={"left"}
                        type={"text"}
                        styles={{
                          backgroundColor: message.bgColor,
                        }}
                        title={"Chat"}
                        titleColor={"rgba(0, 0, 0, 0.87)"}
                        text={message.message}
                        notch={false}
                      />
                    </span>
                    {chatMessage.length === 1 && (
                      <div className="initalButtons">
                        <Button
                          sx={{
                            backgroundColor: DMStyles.White,
                            color: "#000000",
                            border: "1px solid black",
                            "&:hover": {
                              backgroundColor: theme.palette.primary.light,
                            },
                          }}
                          className="baseButton"
                          color={"primary"}
                          variant="contained"
                          onClick={() =>
                            handleSubjectAreaChange(
                              "Therapeutic Diet Information"
                            )
                          }
                        >
                          Need Help With Therapeutic Diet Information?
                        </Button>
                        <Button
                          sx={{
                            backgroundColor: DMStyles.White,
                            color: "#000000",
                            border: "1px solid black",
                            "&:hover": {
                              backgroundColor: theme.palette.primary.light,
                            },
                          }}
                          className="baseButton"
                          color={"primary"}
                          variant="contained"
                          onClick={() =>
                            handleSubjectAreaChange("Diet Specific Recipe")
                          }
                        >
                          Need To Find A Diet Specific Recipe?
                        </Button>
                        <Button
                          sx={{
                            backgroundColor: DMStyles.White,
                            color: "#000000",
                            border: "1px solid black",
                            "&:hover": {
                              backgroundColor: theme.palette.primary.light,
                            },
                          }}
                          className="baseButton"
                          color={"primary"}
                          variant="contained"
                          onClick={() =>
                            handleSubjectAreaChange("Dietary Safety Guidelines")
                          }
                        >
                          Read About Dietary Safety Guidelines
                        </Button>
                        <Button
                          sx={{
                            backgroundColor: DMStyles.White,
                            color: "#000000",
                            border: "1px solid black",
                            "&:hover": {
                              backgroundColor: theme.palette.primary.light,
                            },
                          }}
                          className="baseButton"
                          color={"primary"}
                          variant="contained"
                          onClick={() =>
                            handleSubjectAreaChange("Ingredients and Recipes")
                          }
                        >
                          Ingredients and Recipe Inquiries?
                        </Button>
                      </div>
                    )}
                    {message.citations.filter(
                      (citation) =>
                        citation.recipeId !== null && citation.recipeId > 0
                    ).length > 0 && (
                      <p>
                        <br></br>
                        <span style={{ fontSize: "16px" }}>
                          Click to View Recipe in Plateful:
                        </span>
                      </p>
                    )}

                    {message.citations
                      .filter(
                        (citation) =>
                          citation.recipeId !== null && citation.recipeId > 0
                      )
                      .map((citations, j) => {
                        return (
                          <div key={j}>
                            <>
                              <span
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "800",
                                }}
                              >
                                <Link
                                  component={Link}
                                  color={"primary"}
                                  to={`/plateFul/Recipes/RecipeDetail?recipeId=${citations.recipeId}`}
                                  variant="contained"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {citations.filepath
                                    .toLowerCase()
                                    .indexOf("json") > 0
                                    ? citations.filepath.substring(
                                        0,
                                        citations.filepath.length - 5
                                      )
                                    : citations.filepath}
                                </Link>
                              </span>
                            </>
                          </div>
                        );
                      })}
                    {message.citations.filter(
                      (citation) =>
                        citation.recipeId !== null && citation.recipeId === 0
                    ).length > 0 && (
                      <p>
                        <br></br>
                        <span style={{ fontSize: "16px" }}>
                          Dining RD Sources Cited:
                        </span>
                      </p>
                    )}
                    {message.citations
                      .filter(
                        (citation) =>
                          citation.recipeId !== null && citation.recipeId === 0
                      )
                      .map((citations, j) => {
                        return (
                          <div
                            key={j}
                            style={{ fontSize: "16px", fontWeight: "700" }}
                          >
                            {citations.filepath === null
                              ? citations.metadata.chunking.substring(0, 26)
                              : citations.filepath}
                          </div>
                        );
                      })}
                  </>
                ) : (
                  <MessageBox
                    position={"right"}
                    type={"text"}
                    title={"You"}
                    titleColor={theme.palette.primary.dark}
                    text={message.message}
                    notch={false}
                  />
                )}
              </pre>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default ChatBody;
