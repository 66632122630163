import React, { useMemo, useState } from "react";
import BaseSelect from "../../../../../shared/baseForm/BaseSelect";
import { useForm } from "react-hook-form";
import {
  useCopyVendorProductsMutation,
  useGetVendorSelectBoxQuery,
} from "../../../../../../store/apis/VendorApis";
import { debounce } from "lodash";
import { Fade, Grid, Modal, Typography } from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";
import { BaseButton } from "../../../../../shared/BaseButton";
import { Box } from "@mui/system";
import { REQUIRED_ERROR } from "../../../../../../utils/errorMessages";
import FlexBox from "../../../../../shared/styledComponents/FlexBox";
import CloseIcon from "@mui/icons-material/Close";
import { BackButton } from "../../../../../shared/BackButton";

export default function VendorCopyProducts() {
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    watch,
    trigger,
    handleSubmit,
    formState: { errors },
  } = methods;
  const [searchParams] = useSearchParams();
  const currentVendorId = searchParams.get("id");
  const vendorFromId = watch("vendorId");
  const [debouncedVendorSearch, setDebouncedVendorSearch] = useState("");
  const location = useLocation();
  const [vendorName] = useState(location.state?.vendorName);
  const [openModal, setOpenModal] = useState(false);

  const { data: vendors, isFetching: isVendorFetching } =
    useGetVendorSelectBoxQuery(debouncedVendorSearch, {
      refetchOnMountOrArgChange: true,
    });

  const [copyVendorProducts] = useCopyVendorProductsMutation();

  const handleSearch = useMemo(
    () =>
      debounce((query) => {
        setDebouncedVendorSearch(query);
      }, 400),
    []
  );

  const onSubmit = () => {
    copyVendorProducts({ vendorFromId, vendorToId: currentVendorId });
    setOpenModal(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <BackButton
          text="Back"
          To={"/admin/vendors/vendorSetup"}
          iconDisabled
        />
      </Grid>
      <Grid item xs={12}>
        <Typography fontSize={"18px"} fontWeight={500}>
          Choose Vendor to copy products to {vendorName}.
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <BaseSelect
          name="vendorId"
          id="vendorId"
          label="Vendor to Copy From"
          control={control}
          errors={errors}
          options={vendors || []}
          loading={isVendorFetching}
          handleSearch={handleSearch}
          validationProps={{
            required: REQUIRED_ERROR("Vendor to Copy From"),
          }}
        />
        <Grid item xs={7} />
        <Grid item xs={3}>
          <BaseButton
            sx={{ width: 90, height: 38, marginTop: "15px" }}
            text={"Copy"}
            onClick={async () => {
              const validated = await trigger("vendorId");
              if (validated === true) {
                setOpenModal(true);
              }
            }}
          />
        </Grid>
        <Grid item xs={9} />
      </Grid>
      {openModal === true ? (
        <VendorProductCopyModal
          vendorToId={currentVendorId}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          open={openModal}
          close={() => {
            setOpenModal(false);
          }}
        />
      ) : (
        ""
      )}
    </Grid>
  );
}

function VendorProductCopyModal({
  vendorToId,
  handleSubmit,
  onSubmit,
  open,
  close,
}) {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`delete-modal-title-${vendorToId}`}
      aria-describedby={`delete-modal-description-${vendorToId}`}>
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "var(--whiteColor)",
            boxShadow: 24,
            width: "30%",
            borderRadius: "4px",
            border: "none",
          }}>
          <FlexBox
            alignItems={"center"}
            gap={0.5}
            justifyContent={"space-between"}>
            <>
              <Box
                sx={{
                  flexGrow: 1,
                }}>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#DE6D00",
                    color: "#FFFFFF",
                    fontSize: "18px",
                    fontWeight: "600",
                    height: "60px",
                    padding: "16px",
                  }}>
                  <>Review Vendor Products Copy</>
                  <CloseIcon onClick={close} sx={{ cursor: "pointer" }} />
                </Typography>
                <Typography
                  id={`delete-modal-title-${vendorToId}`}
                  sx={{
                    padding: "16px",
                    paddingBottom: "0px",
                  }}>
                  Confirming this action will permanently overwrite existing
                  vendor products. This action cannot be reverted.
                </Typography>
                <Typography
                  id={`delete-modal-description-${vendorToId}`}
                  sx={{ mt: 1, padding: "16px" }}>
                  Do you wish to continue?
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    margin: "15px 0px 10px 25%",
                    width: "50%",
                  }}>
                  <BaseButton
                    sx={{
                      width: 90,
                      height: 38,
                      backgroundColor: "#FFFFFF",
                    }}
                    fontColor={"#262626"}
                    border={"1px solid #262626"}
                    text={"Cancel"}
                    onClick={close}
                  />
                  <BaseButton
                    sx={{ width: 90, height: 38, backgroundColor: "#DE6D00" }}
                    fontColor={"#FFFFFF"}
                    text={"Confirm"}
                    onClick={handleSubmit(onSubmit)}
                  />
                </Box>
              </Box>
            </>
          </FlexBox>
        </Box>
      </Fade>
    </Modal>
  );
}
