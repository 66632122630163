import {
  Box,
  CircularProgress,
  Divider,
  Fade,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  useAeCopyToCorporateGroupMutation,
  useFacilityAdaptiveEquipmentsQuery,
  useGetFacilityByIdQuery,
} from "../../../../../../store/apis/FacilityApis";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { CopyAll, EditNote, Report } from "@mui/icons-material";

export default function CommunityAdaptiveEqipments() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [openCopyModal, setOpenCopyModal] = useState(false);

  const { data: community, isSuccess: communitySuccess } =
    useGetFacilityByIdQuery(id, { skip: !id });

  const {
    data: facilityAEs,
    error: hasError,
    isLoading: fetching,
  } = useFacilityAdaptiveEquipmentsQuery(id);

  const facilityAdaptiveEquipmentColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
    },
  ];

  return (
    <>
      <FlexBox
        my={"8px"}
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={"space-between"}
      >
        <BaseButton
          sx={{ width: { xs: "100%", sm: "auto" } }}
          text={"Edit Community Adaptive Equipments"}
          To={`editCommunityAdaptiveEquipments?id=${id}`}
          endIcon={<EditNote />}
        />

        <BaseButton
          sx={{ width: { xs: "100%", sm: "auto" } }}
          text={" Copy to Corporate Group"}
          endIcon={<CopyAll />}
          onClick={() => setOpenCopyModal(true)}
          colors="error"
          marginLeft={"0px !important"}
        />
      </FlexBox>

      {openCopyModal && (
        <CopyToCorporateGroupModal
          open={openCopyModal}
          close={() => setOpenCopyModal(false)}
          cgName={communitySuccess && community?.corporateGroup}
          facilityName={communitySuccess && community?.name}
          id={id}
        />
      )}

      <BaseDataGrid
        rows={facilityAEs?.facilityAdaptiveEquipments}
        columns={facilityAdaptiveEquipmentColumns}
        error={hasError}
        loading={fetching}
      />
    </>
  );
}

function CopyToCorporateGroupModal({ facilityName, cgName, id, open, close }) {
  const [startCopy, { isLoading, isSuccess }] =
    useAeCopyToCorporateGroupMutation();

  useEffect(() => {
    if (isSuccess) {
      close();
    }
  }, [isSuccess]);

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`copy-to-corporate-group-modal-title-${id}`}
      aria-describedby={`copy-to-corporate-group-modal-description-${id}`}
    >
      <Fade in={open}>
        <Box className={"deleteModal"}>
          <FlexBox
            display={"flex"}
            alignItems={"center"}
            gap={"4px"}
            paddingX={"24px"}
            justifyContent={"space-between"}
          >
            <Report color="error" sx={{ fontSize: "90px" }} />
            <Box>
              <Typography
                id={`copy-to-corporate-group-modal-title-${id}`}
                variant="h6"
                component="h2"
                className="modalTitle"
                sx={{
                  textAlign: "center",
                }}
              >
                {`Adaptive Equipments for "${facilityName}"`}
              </Typography>
              <Typography
                id={`copy-to-corporate-group-modal-description-${id}`}
                sx={{
                  textAlign: "center",
                  mt: 2,
                }}
              >
                {`This will remove the adaptive equipments for all communities that are linked to the "${cgName}" Corporate Group, and replace it with the adaptive equipments that are currently setup for this community. Are you sure you want to continue?`}
              </Typography>
            </Box>
          </FlexBox>
          <Divider sx={{ my: 0.5 }} />
          <FlexBox my={"10px"} px="24px" justifyContent={"end"}>
            <BaseButton
              sx={{ marginLeft: "0px !important" }}
              text="Cancel"
              onClick={close}
              colors="warning"
            />
            <BaseButton
              text="Continue"
              sx={{ marginLeft: "0px !important" }}
              startIcon={
                isLoading ? <CircularProgress color="white" size={18} /> : ""
              }
              colors="error"
              onClick={() => {
                startCopy(id);
              }}
            />
          </FlexBox>
        </Box>
      </Fade>
    </Modal>
  );
}
