import { Box, Grid2 } from "@mui/material";
import HeaderBox from "components/consultant/shared/HeaderBox";
import { BackButton } from "components/shared/BackButton";
import { BaseButton } from "components/shared/BaseButton";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ADD_DOCTOR_FIELDS, addDoctor_ScreenNames } from "./constants";
import { FormProvider, useForm } from "react-hook-form";
import {
  useGetFacilityDoctorByIdQuery,
  useInsertFacilityDoctorMutation,
  useUpdateFacilityDoctorMutation,
} from "store/apis/NutritionalCareAPIs";
import { useGetVisitDetailsQuery } from "store/apis/ConsultantApis";
import { setErrorsForProperties } from "components/shared/utils/helpers";
import { EditSkeleton } from "components/admin/admin.overlays";

export const AddDoctor = () => {
  // Local States
  const [searchParams] = useSearchParams();
  const visitPlanId = searchParams.get("visitPlanId");
  const visitReportId = searchParams.get("visitReportId");
  const reportId = searchParams.get("id");
  const doctorId = searchParams.get("doctorId");
  const navigate = useNavigate();

  const { data: visitData } = useGetVisitDetailsQuery(visitPlanId);
  const { facilityId } = visitData || [];

  // UseForm Hooks
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  // deStructure of useForm Hooks
  const {
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = methods;

  // Api section

  // Get Api
  const {
    data: doctorData,
    isLoading,
    isSuccess,
  } = useGetFacilityDoctorByIdQuery(doctorId, {
    skip: !doctorId,
  });

  // Mutation Api
  // Api to create a new Doctor
  const [addDoctor, { isLoading: addLoading, isSuccess: addSuccess }] =
    useInsertFacilityDoctorMutation();
  // api to update existing Doctor
  const [updateDoctor, { isLoading: updateLoading, isSuccess: updateSuccess }] =
    useUpdateFacilityDoctorMutation();
  const loading = addLoading || updateLoading;
  const success = addSuccess || updateSuccess;
  const isEditLoading = !!doctorId && isLoading;
  // UseEffects Section
  success ? navigate(-1) : null;

  useEffect(() => {
    if (errors) {
      setErrorsForProperties({ setError: setError, responseError: errors });
    }
  }, [errors]);

  useEffect(() => {
    isSuccess ? reset(doctorData) : null;
  }, [isSuccess]);

  // Onsubmit Function
  const onSubmit = (data) => {
    const dataSent = {
      ...data,
      id: doctorId ? data.id : 0,
      facilityId,
    };
    doctorId ? updateDoctor(dataSent) : addDoctor(dataSent);
  };

  // Modify the screenName based on the isEdit value
  const updated_ScreenNames = addDoctor_ScreenNames.map((screen) => {
    if (screen.id === 2) {
      return { ...screen, screenName: doctorId ? "Edit Doctor" : "Add Doctor" };
    }
    return screen;
  });

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton text={"Loading Doctor Information."} />
      ) : (
        <Box
          sx={{
            padding: "8px",
          }}
        >
          <BackButton
            To={-1}
            iconDisabled={true}
            sx={{ marginBottom: "10px" }}
          />
          <HeaderBox
            visitPlanId={visitPlanId}
            ScreensArray={updated_ScreenNames}
          />
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <BaseButton
              marginLeft={"0px !important"}
              text={"Manage Doctor"}
              To={`/Consultant/MyRosters/VisitDetails/FacilityDoctors?visitPlanId=${visitPlanId}&visitReportId=${visitReportId}&id=${reportId}`}
            />
            <BaseSubmitButton
              text={doctorId ? "Update" : "Save"}
              isSubmitting={loading}
              onClick={handleSubmit(onSubmit)}
            />
          </Box>
          <FormProvider {...methods}>
            <Grid2
              container
              spacing={2}
              sx={{
                marginTop: "10px",
                width: "50%",
              }}
            >
              <GeneratedForm oldGrid={false} list={ADD_DOCTOR_FIELDS} />
            </Grid2>
          </FormProvider>
        </Box>
      )}
    </>
  );
};
