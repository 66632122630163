/* eslint-disable */
import { Grid2 } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BaseSelect from "../../../../../../shared/baseForm/BaseSelect";

import {
  useAddFacilityDietMutation,
  useAvailableFacilityDietSelectListQuery,
  useFacilityDietByIdQuery,
  useUpdateFacilityDietMutation,
} from "../../../../../../../store/apis/FacilityApis";
import { FormProvider, useForm } from "react-hook-form";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../../admin.overlays";
import { REQUIRED_ERROR } from "utils/errorMessages";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";

import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { dietsFields } from "./form.constants";

export default function AddCommunityDiet() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const fdId = searchParams.get("fdId");

  const [newDiets, setNewDiets] = React.useState([]);
  const [queries, setQueries] = React.useState([{}]);
  const [initialValues, setInitialValues] = React.useState({});
  const [isChange, setIsChange] = React.useState(false);

  const {
    data: availableDiets,
    isLoading: availableDietsLoading,
    isSuccess: availableDietsSuccess,
  } = useAvailableFacilityDietSelectListQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });

  const {
    data: currentFD,
    isLoading: currentFDLoading,
    isSuccess: currentFDSuccess,
  } = useFacilityDietByIdQuery(fdId, {
    refetchOnMountOrArgChange: true,
    skip: !fdId,
  });

  const navigate = useNavigate();

  const [addFacilityDiet, { isLoading: addSubmitting, isSuccess: addSuccess }] =
    useAddFacilityDietMutation();

  const [
    updateFacilityDiet,
    { isLoading: updateSubmitting, isSuccess: updateSuccess },
  ] = useUpdateFacilityDietMutation();

  const isSubmitting = addSubmitting || updateSubmitting;
  const isSuccess = addSuccess || updateSuccess;

  const isEdit = !!fdId;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { dirtyFields, isDirty },
  } = methods;

  useEffect(() => {
    if (currentFDSuccess && !currentFDLoading) {
      reset(currentFD);
      setInitialValues(currentFD);
    }
  }, [currentFDSuccess, currentFD, currentFDLoading]);

  useEffect(() => {
    if (availableDietsSuccess && !availableDietsLoading) {
      if (isEdit) {
        setNewDiets([
          ...availableDiets,
          { id: currentFD?.dietId, label: currentFD?.commonDietName },
        ]);
      } else {
        setNewDiets([...availableDiets]);
      }
    }
  }, [availableDietsSuccess, availableDiets, availableDietsLoading, currentFD]);

  useEffect(() => {
    if (isSuccess) {
      navigate(-1);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (id) {
      setQueries([
        ...queries.filter((x) => x.name != "dietId"),
        {
          name: "dietId",
          query: id,
        },
      ]);
    }
  }, [id]);

  const onSubmit = (data) => {
    setIsChange(false);
    data.facilityId = id;

    if (isEdit) {
      updateFacilityDiet(data);
      return;
    }
    addFacilityDiet(data);
  };

  const isEditLoading = isEdit && currentFDLoading;

  const selectedDietId = watch("dietId");

  useEffect(() => {
    if (selectedDietId > 0) {
      const selectedDiet = availableDiets?.find(
        (diet) => diet.id === selectedDietId
      );
      if (selectedDiet) {
        setValue("dietName", selectedDiet.label);
      }
    }
  }, [selectedDietId]);

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <BaseBlockerModal
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            watch={watch}
            hasChange={isChange}
            setHasChange={setIsChange}
            getValues={getValues}
            dirtyFields={dirtyFields}
            isDirty={isDirty}
          />
          <Grid2 width={"80%"} container spacing={2}>
            <FormProvider {...methods}>
              <Grid2 size={7}>
                <BaseSelect
                  name="dietId"
                  id="dietId"
                  label="Common Diet Name"
                  validationProps={{
                    required: REQUIRED_ERROR("Common Diet Name"),
                  }}
                  options={newDiets || []}
                  loading={availableDietsLoading}
                />
              </Grid2>
              <GeneratedForm
                oldGrid={false}
                list={dietsFields}
                queries={queries}
              />

              <BaseSubmitButton
                fullWidth
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
              />
            </FormProvider>
          </Grid2>
        </>
      )}
    </>
  );
}
