import { StatesList } from "../../../../../../shared/states.constants";
import { zipCodeValidation } from "../../../../../../../utils/ValidationRegex";
import { FORM_TYPES } from "components/shared/generatedForm/GeneratedForm";
// import dayjs from "dayjs";
import {
  INVALID_US_PHONE_VALIDATION,
  REQUIRED_ERROR,
} from "utils/errorMessages";

export const SHIP_TO_FACILITY_FIELD = {
  props: {
    name: "shipToFacilityAddress",
    label: "Ship to Community Address",
  },
  type: "checkbox",
  size: 12,
};

export const SHIPPING_FIELDS = [
  {
    props: {
      name: "shipToAddress",
      label: "Shipping Address",
      // validationProps: {
      //   required: "Address field is required",
      // },
    },
    size: 3,
  },
  {
    props: {
      name: "shipToCity",
      label: "City",
    },
    // validationProps: {
    //   required: "City field is required",
    // },
    size: 3,
  },
  {
    props: {
      name: "shipToState",
      label: "State",
    },
    // validationProps: {
    //   required: "State field is required",
    // },
    size: 2.5,
    type: "select",
    options: StatesList,
  },
  {
    props: {
      name: "shipToZip",
      label: "Zip",
    },
    validationProps: {
      pattern: {
        value: zipCodeValidation,
        message: "Please enter a valid postal code",
      },
    },
    size: 1.5,
  },
  {
    props: {
      name: "phoneNumber",
      label: "Phone Number",
    },
    validationProps: {
      pattern: INVALID_US_PHONE_VALIDATION(),
    },
    size: 2,
  },
];

export const htiOrderFields = [
  {
    props: {
      name: "nameOfMenu",
      label: "Name of Menu",
    },
    size: 3,
  },
  {
    props: {
      name: "contactPerson",
      label: "Contact Person",
    },
    size: 3,
  },
  {
    props: {
      name: "menuNotes",
      id: "menuNotes",
      label: "Menu Notes",
      multiline: true,
    },
    size: 6,
  },
  {
    props: {
      name: "standardBreakfast",
      id: "standardBreakfast",
      label: "Standard Breakfast",
    },
    type: FORM_TYPES.Checkbox,
    size: 3,
  },
  {
    props: {
      name: "plannedBreakfast",
      id: "plannedBreakfast",
      label: "Planned Breakfast",
    },
    type: FORM_TYPES.Checkbox,
    size: 3,
  },
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 6,
  },
  {
    props: {
      name: "isHardCopy",
      id: "isHardCopy",
      label: "Printed Copy",
    },
    type: FORM_TYPES.Checkbox,
    size: 3,
  },
  {
    props: {
      name: "hardCopyMenuQuantity",
      id: "hardCopyMenuQuantity",
      label: "Quantity",
      type: "number",
      hideArrows: false,
      min: 0,
      max: 10000,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Minimum value should be 0",
      },
      max: {
        value: 10000,
        message: "Maximum value should be 10000",
      },
    },

    size: 2,
  },
  {
    props: {
      name: "hardCopyMenuPrice",
      id: "hardCopyMenuPrice",
      label: "Price",
      type: "number",
      unit: "$",
      unitPosition: "start",
      min: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Minimum value should be 0",
      },
    },
    size: 2,
  },

  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 5,
  },
  {
    props: {
      name: "isWebBasedMenu",
      id: "isWebBasedMenu",
      label: "Web Based Menu",
    },
    type: FORM_TYPES.Checkbox,
    size: 3,
  },
  {
    props: {
      name: "webBasedMenuQuantity",
      id: "webBasedMenuQuantity",
      label: "Quantity",
      type: "number",
      min: 0,
      max: 10000,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Minimum value should be 0",
      },
      max: {
        value: 10000,
        message: "Maximum value should be 10000",
      },
    },
    size: 2,
  },
  {
    props: {
      name: "webBasedMenuPrice",
      id: "webBasedMenuPrice",
      label: "Price",
      type: "number",
      unit: "$",
      unitPosition: "start",
      min: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Minimum value should be 0",
      },
    },
    size: 2,
  },
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 5,
  },
  {
    props: {
      name: "seasonIds",
      label: "Season",
    },
    type: FORM_TYPES.MultiSelectAPI,
    endpoint: `getAvailableSeasonSelectBox`,
    size: 6,
  },
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 6,
  },
];

export const htiOrderFields2 = [
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 6,
  },
  {
    props: {
      name: "dietIds",
      label: "Diets",
    },
    type: FORM_TYPES.MultiSelectAPI,
    endpoint: "getDietSelectBox",
    size: 10,
  },
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 2,
  },
  {
    props: {
      name: "standardRecipeQuantities",
      id: "standardRecipeQuantities",
      label: "Standard Recipe Quantities",
    },
    type: "checkbox",
    size: 12,
  },
  {
    props: {
      name: "recipeQuantity1",
      id: "recipeQuantity1",
      label: "Recipe Quantities",
      type: "number",
      min: 0,
      max: 10000,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Minimum value should be 0",
      },
      max: {
        value: 10000,
        message: "Maximum value should be 10000",
      },
    },
    size: 2,
  },
  {
    props: {
      name: "recipeQuantity2",
      id: "recipeQuantity2",
      label: "Recipe Quantities",
      type: "number",
      max: 10000,
      min: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Minimum value should be 0",
      },
      max: {
        value: 10000,
        message: "Maximum value should be 10000",
      },
    },
    size: 2,
  },
  {
    props: {
      name: "recipeQuantity3",
      id: "recipeQuantity3",
      label: "Recipe Quantities",
      type: "number",
      max: 10000,
      min: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Minimum value should be 0",
      },
      max: {
        value: 10000,
        message: "Maximum value should be 10000",
      },
    },
    size: 2,
  },

  {
    props: {},
    type: "offset",
    size: 6,
  },
  {
    props: {
      name: "includePureedRecipes",
      id: "includePureedRecipes",
      label: "Include Pureed/Dental Recipes",
    },
    type: "checkbox",
    size: 12,
  },
  {
    props: {
      name: "pureedRecipeQuantity1",
      id: "pureedRecipeQuantity1",
      label: "Pureed/Dental Recipe Quantities",
      type: "number",
      min: 0,
      max: 10000,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Minimum value should be 0",
      },
      max: {
        value: 10000,
        message: "Maximum value should be 10000",
      },
    },
    size: 2,
  },
  {
    props: {
      name: "pureedRecipeQuantity2",
      id: "pureedRecipeQuantity2",
      label: "Pureed/Dental Recipe Quantities",
      type: "number",
      min: 0,
      max: 10000,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Minimum value should be 0",
      },
      max: {
        value: 10000,
        message: "Maximum value should be 10000",
      },
    },
    size: 2,
  },
  {
    props: {
      name: "pureedRecipeQuantity3",
      id: "pureedRecipeQuantity3",
      label: "Pureed/Dental Recipe Quantities",
      type: "number",
      min: 0,
      max: 10000,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Minimum value should be 0",
      },
      max: {
        value: 10000,
        message: "Maximum value should be 10000",
      },
    },
    size: 2,
  },

  {
    props: {},
    type: "offset",
    size: 6,
  },
  {
    props: {
      name: "includeShoppingList",
      id: "includeShoppingList",
      label: "Include Shopping List",
    },
    type: "checkbox",
    size: 12,
  },
];

export const htiOrderFields3 = [
  {
    props: {
      name: "census",
      id: "census",
      label: "Census",
      type: "number",
      min: 0,
      max: 10000,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Minimum value should be 0",
      },
      max: {
        value: 10000,
        message: "Maximum value should be 10000",
      },
    },
    size: 3,
  },
  {
    props: {},
    type: "offset",
    size: 9,
  },
  {
    props: {
      name: "includePostedMenusByDay",
      id: "includePostedMenusByDay",
      label: "Menu By Day",
    },
    type: "checkbox",
    size: 4,
  },

  {
    props: {
      name: "includePostedMenusByMeal",
      id: "includePostedMenusByMeal",
      label: "Menu By Meal",
    },
    type: "checkbox",
    size: 4,
  },

  {
    props: {
      name: "includeDailySelectionSheet",
      id: "includeDailySelectionSheet",
      label: "Daily Selection Sheet",
    },
    type: "checkbox",
    size: 4,
  },
  {
    props: {
      name: "includeOther",
      id: "includeOther",
      label: "Other",
      // validationProps: {
      //   required: "Other field is required",
      // },
    },
    size: 8,
  },
];

export const htiOrderFields5 = [
  { props: {}, type: FORM_TYPES.Offset, size: 4 },
  {
    props: {
      name: "vendorId",
      label: "Vendor",
    },
    validationProps: { required: REQUIRED_ERROR("Vendor") },
    type: FORM_TYPES.AutocompleteAPI,
    endpoint: "getVendorSelectBox",
    size: 6,
  },
  { props: {}, type: FORM_TYPES.Offset, size: 6 },
  {
    props: {
      name: `billToName`,
      label: `Bill To Name`,
    },
    size: 6,
  },
  { props: {}, type: FORM_TYPES.Offset, size: 6 },
  {
    props: { name: `billToTypeId`, label: `Bill To Type` },
    validationProps: { required: REQUIRED_ERROR("Bill to type") },
    size: 6,
    type: FORM_TYPES.SelectAPI,
    endpoint: `getBillToTypesSelectList`,
  },
  { props: {}, type: FORM_TYPES.Offset, size: 6 },
  {
    props: {
      name: `soldByUserId`,
      label: `Sold By`,
    },
    type: FORM_TYPES.SelectAPI,
    endpoint: `getCorporateGroupHTISelect`,
    size: 6,
  },
  { props: {}, type: FORM_TYPES.Offset, size: 6 },
  {
    props: { name: "referredByUserId", label: `Referred By` },
    type: FORM_TYPES.SelectAPI,
    endpoint: `getCorporateGroupHTISelect`,
    size: 6,
  },
  { props: {}, type: FORM_TYPES.Offset, size: 6 },
  {
    props: {
      name: "pricing",
      id: "pricing",
      label: "Pricing",
      disabled: true,
      type: "number",
      unit: "$",
      unitPosition: "start",
      min: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Minimum value should be 0",
      },
    },
    size: 4,
  },
  {
    props: {},
    type: "offset",
    size: 8,
  },
  {
    props: {
      name: "implementationFee",
      id: "implementationFee",
      label: "Implementation Fee",
      type: "number",
      unit: "$",
      unitPosition: "start",
      min: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Minimum value should be 0",
      },
    },
    size: 4,
  },
  {
    props: {},
    type: "offset",
    size: 8,
  },
  {
    props: {
      name: "additionalCharges",
      id: "additionalCharges",
      label: "Additional Charges",
      type: "number",
      unit: "$",
      unitPosition: "start",
      min: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Minimum value should be 0",
      },
    },
    size: 4,
  },
  {
    props: {
      name: "additionalChargesDescription",
      id: "additionalChargesDescription",
      label: "Details for Additional Charges",
    },
    size: 7,
  },
  {
    props: {},
    type: "offset",
    size: 1,
  },
  {
    props: {
      name: "shipping",
      id: "shipping",
      label: "Shipping",
      type: "number",
      unit: "$",
      unitPosition: "start",
      min: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Minimum value should be 0",
      },
    },
    size: 4,
  },
  {
    props: {},
    type: "offset",
    size: 8,
  },
  {
    props: {
      name: "total",
      id: "total",
      label: "Total",
      disabled: true,
      type: "number",
      unit: "$",
      unitPosition: "start",
      min: 0,
    },
    validationProps: {
      min: {
        value: 0,
        message: "Minimum value should be 0",
      },
    },
    size: 4,
  },
  {
    props: {},
    type: "offset",
    size: 8,
  },

  {
    props: {
      name: "dateOrdered",
      id: "dateOrdered",
      label: "Date Ordered",
      disabled: true,
      // defaultValue: dayjs(new Date()).format("MM-DD-YYYY"), //new Date().toISOString().substring(0, 10),
    },
    validationProps: {
      required: REQUIRED_ERROR("Date Ordered"),
    },
    size: 4,
    type: FORM_TYPES.DatePicker,
  },
  {
    props: {},
    type: "offset",
    size: 8,
  },
  {
    props: {
      name: "dateShipped",
      id: "dateShipped",
      label: "Date Shipped",
    },
    type: FORM_TYPES.DatePicker,
    size: 4,
  },
  {
    props: {},
    type: "offset",
    size: 8,
  },

  {
    props: {
      name: "upstrackingNumber",
      id: "upstrackingNumber",
      label: "UPS Tracking Number",
      // validationProps: {
      //   required: "UPSTrackingNumber field is required",
      // },
    },
    size: 4,
  },
  {
    props: {},
    type: "offset",
    size: 8,
  },
  {
    props: {
      name: "readyToBeInvoiced",
      id: "readyToBeInvoiced",
      label: "Submit For Invoicing",
    },
    type: "checkbox",
    size: 4,
  },
];

export const PRODUCT_FIELDS = ({ name, partialName }) => [
  {
    props: {
      name: `${partialName}isSelected`,
      label: name,
    },
    validationProps: {},
    size: 6,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {
      name: `${partialName}price`,
      label: `Price`,
      type: `number`,
      unit: `$`,
      unitPosition: `start`,
      min: 0,
    },
    validationProps: {},
    size: 3,
  },
  {
    props: {
      name: `${partialName}quantity`,
      label: `Quantity`,
      type: `number`,
      min: 0,
      max: 1000,
    },
    validationProps: {},
    size: 3,
  },
];

export const HTI_ORDER_FIELDS_DEFAULTS = {
  shipToFacilityAddress: false,
  shipToAddress: "",
  shipToCity: "",
  shipToState: null,
  shipToZip: "",
  phoneNumber: "",
  nameOfMenU: "",
  standardBreakfast: false,
  plannedBreakfast: false,
  isHardCopy: false,
  isWebBasedMenu: false,
  webBasedMenuPrice: 0,
  seasonIds: [],
  dietIds: [],
  standardRecipeQuantities: false,
  includePureedRecipes: false,
  includeShoppingList: false,
  includePostedMenusByDay: false,
  includePostedMenusByMeal: false,
  includeDailySelectionSheet: false,
  vendorId: 387,
  billToTypeId: 1,
  pricing: "0.00",
  total: "0.00",
  dateOrdered: "2025-01-06",
  dateShipped: null,
  readyToBeInvoiced: false,
  // selectedProducts: [],
  facilityId: "218",
  nameOfMenu: "",
  contactPerson: "",
  menuNotes: "",
};
