import React from "react";
import Grid from "@mui/material/Grid2";
import { Box, Divider, Typography } from "@mui/material";
import BaseCheckBox from "components/shared/baseForm/BaseCheckBox";
import { useFieldArray, useFormContext } from "react-hook-form";
import Comment from "./Comment";

export default function MealObservationAccordionDetails({
  config,
  sectionIndex,
  configIndex,
}) {
  const { control } = useFormContext();
  const { fields: items } = useFieldArray({
    control: control,
    name: `sections.${sectionIndex}.items.${configIndex}.configs`,
  });

  const prefix = `sections.${sectionIndex}.items.${configIndex}.configs`;

  return (
    <>
      <Box>
        <Box className="visitReportsAccordian">
          <Typography
            component="p"
            sx={{
              color: "black",
              fontWeight: "700",
              background: "#C4E1E2",
              p: "0.8rem 1rem",
            }}
          >
            {config.name}
          </Typography>

          <Divider />
          <Box>
            <Box sx={{ padding: "1rem" }}>
              {items?.map((item, index) => (
                <Box key={item.id} className="configBox">
                  <Box
                    sx={{
                      width: {
                        md: "35vw",
                        xl: "25vw",
                      },
                    }}
                  >
                    {!item.isSubItem ? (
                      <Typography
                        component="p"
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                        }}
                      >
                        {item.name}
                      </Typography>
                    ) : (
                      <Typography
                        component="p"
                        sx={{
                          fontWeight: "600",
                          fontSize: "18px",
                        }}
                      >
                        {item.name}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      width: "95%",
                      marginTop: "5px",
                    }}
                  >
                    <Grid container className="gridStyling" spacing={0.5}>
                      {item.hasCompleted ? (
                        <Grid>
                          <BaseCheckBox
                            name={`${prefix}.${index}.completed`}
                            id={`${prefix}.${index}.completed`}
                            label="Completed"
                            size="small"
                          />
                        </Grid>
                      ) : null}
                      {item.hasMeetsStandard ? (
                        <Grid>
                          <BaseCheckBox
                            name={`${prefix}.${index}.meetsStandard`}
                            id={`${prefix}.${index}.meetsStandard`}
                            label="Meets Standard"
                            size="small"
                          />
                        </Grid>
                      ) : null}
                      {item.hasCompleteNext ? (
                        <Grid>
                          <BaseCheckBox
                            name={`${prefix}.${index}.completeNextConsult`}
                            id={`${prefix}.${index}.completeNextConsult`}
                            label="Future Consult"
                            size="small"
                          />
                        </Grid>
                      ) : null}
                      {item.hasNeedsCorrection ? (
                        <Grid>
                          <BaseCheckBox
                            name={`${prefix}.${index}.needsCorrection`}
                            id={`${prefix}.${index}.needsCorrection`}
                            label="Action Required"
                            size="small"
                          />
                        </Grid>
                      ) : null}
                      {item.hasSeeRecommendation ? (
                        <Grid>
                          <BaseCheckBox
                            name={`${prefix}.${index}.seeRecommendation`}
                            id={`${prefix}.${index}.seeRecommendation`}
                            label="See Recommend"
                            size="small"
                          />
                        </Grid>
                      ) : null}
                      <Comment
                        prefix={`${prefix}.${index}`}
                        hasComment={item.hasComment}
                      />
                    </Grid>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
