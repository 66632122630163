import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormControl, IconButton, InputAdornment } from "@mui/material";
import dayjs from "dayjs";
import { isUndefined } from "lodash";
import { TimePicker } from "@mui/x-date-pickers";
import "./BaseForm.css";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import useSafeFormContext from "hooks/useSafeFormContext";
import { getNestedProperty } from "utils/helperFunctions";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseDatePicker.md)
 */
export default function BaseTimePicker({
  control,
  errors,
  // Control and Errors should be avoided for future cases, enclose the GenerateForm inside a FormProvider instead

  defaultValue,
  validationProps,
  ...rest
}) {
  const { control: controlMethod, errors: formErrors } = useSafeFormContext({
    control,
    errors,
  });

  const error = getNestedProperty(formErrors, rest.name);

  const [isOpen, setIsOpen] = useState(false);
  const validateDate = (value, formValues) => {
    const selectedDate = dayjs(value);
    const isValidDate = selectedDate.isValid();
    const isTimeType = rest.type === "time";

    if (validationProps?.required && isTimeType && !value) {
      return validationProps.required;
    }

    if (isTimeType) {
      const timeValue = dayjs(`2022-04-17T${value}`);
      if (!timeValue.isValid() && !isValidDate) {
        return `Selected time is Invalid`;
      }
    }

    if (validationProps?.validate) {
      return validationProps.validate(value, formValues);
    }

    return true;
  };
  const handleIconClick = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <FormControl
      sx={{
        ...rest.sx,
      }}
      fullWidth
      variant="outlined"
    >
      <Controller
        control={controlMethod}
        name={rest.name || rest.id}
        defaultValue={
          !isUndefined(defaultValue)
            ? rest.type === "time"
              ? dayjs(`2022-04-17T${defaultValue}`)
              : defaultValue
            : null
        }
        rules={{
          validate: validateDate,
        }}
        render={({ field: { onChange, value } }) => {
          let timeValue;
          if (rest.type === "time" && typeof value === "string") {
            timeValue = dayjs(`2022-04-17T${value}`);
          } else {
            timeValue = false;
          }
          const slotProps = {
            textField: {
              size: rest.size,
              error: !!error,
              helperText: error?.message,
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleIconClick}>
                      <QueryBuilderIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                readOnly: true,
                onClick: () => setIsOpen(true),
              },
            },
            field: { clearable: true },
            PopoverProps: {
              disableRestoreFocus: true,
              onClose: () => {
                setIsOpen(false);
              },
            },
          };
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker,TimePicker"]}>
                <TimePicker
                  sx={{
                    width: "100%",
                  }}
                  timeSteps={{ minutes: 15 }}
                  label={rest.label || rest.name}
                  value={value ? (timeValue ? timeValue : value) : null}
                  onClose={() => setIsOpen(false)}
                  onChange={(date) => {
                    onChange(
                      rest.timeFormat
                        ? dayjs(date).format(rest.timeFormat)
                        : date
                    );
                  }}
                  open={isOpen}
                  {...rest}
                  slotProps={slotProps}
                />
              </DemoContainer>
            </LocalizationProvider>
          );
        }}
      />
    </FormControl>
  );
}
