import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const {
  MENUS,
  MENUS_MANAGER,
  ORDER_TRANSMISSIONS,
  DIET,
  DIETS,
  ALLERGY_FOODS,
  BULK_VENDOR_PRODUCTS,
  MENU_PRINT_STYLES,
} = API_ENDPOINTS;

export const MenuApis = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMenuCategoriesSelectBox: builder.query({
      query: () => `${MENUS}/getmenucategories`,
      transformResponse: (response) =>
        response?.data?.map((res) => ({
          id: res.id,
          label: res.name,
        })),
    }),
    getMenuItems: builder.query({
      query: ({ menuId, mealId, day }) =>
        `${MENUS}/GetMenuItems?menuId=${menuId}&mealId=${mealId}&day=${day}`,
      transformResponse: (response) => response?.data,
      // providesTags: (response) => {
      //   return response ? [{ type: MENUS, id: response.id }] : [MENUS];
      // },
      keepUnusedDataFor: 10,
    }),
    getMenusBySeasonSelectBox: builder.query({
      query: (seasonId) => `${MENUS}/GetMenusBySeason?seasonId=${seasonId}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [...response.map(({ id }) => ({ type: MENUS, id })), MENUS]
          : [MENUS],
    }),
    getMenusBySeasonSelect: builder.query({
      query: (seasonId) => `${MENUS}/GetMenusBySeason?seasonId=${seasonId}`,
      transformResponse: (res) => res.data,
      providesTags: (response) =>
        response?.data
          ? [...response.map(({ id }) => ({ type: MENUS, id })), MENUS]
          : [MENUS],
    }),
    getHepContributionsByRecipe: builder.query({
      query: (id) => `${MENUS}/HEPContributionsByRecipe?recipeId=${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response?.data
          ? [...response.map(({ id }) => ({ type: MENUS, id })), MENUS]
          : [MENUS],
    }),
    getMenusForActiveSeason: builder.query({
      query: (searchTerm) =>
        `${MENUS}/GetMenusForActiveSeasons?searchText=${searchTerm}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [...response.map(({ id }) => ({ type: MENUS, id })), MENUS]
          : [MENUS],
    }),
    getMenusForUser: builder.query({
      query: ({ userId, searchTerm }) =>
        `${MENUS}/GetMenusUserCanAccess?userId=${userId}&searchText=${searchTerm}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response?.data
          ? [...response.map(({ id }) => ({ type: MENUS, id })), MENUS]
          : [MENUS],
    }),
    getMenusForTableSide: builder.query({
      query: ({ seasonId, facilityId }) =>
        `${MENUS}/GetMenusForTableSide?seasonId=${seasonId}&facilityId=${facilityId}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      providesTags: (response) =>
        response?.data
          ? [...response.map(({ id }) => ({ type: MENUS, id })), MENUS]
          : [MENUS],
    }),
    getMenuById: builder.query({
      query: (id) => `${MENUS}/${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response ? [{ type: MENUS, id: response.id }] : [MENUS];
      },
    }),
    getMenuFooterById: builder.query({
      query: (id) => `${MENUS}/GetMenuFooter?id=${id}`,
      transformResponse: (response) => response?.data,
      providesTags: [MENUS],
    }),
    getMenuDaysById: builder.query({
      query: (id) => `${MENUS}/MealDays?menuId=${id}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response ? [{ type: MENUS, id: response.id }] : [MENUS];
      },
    }),
    getChangeHistory: builder.query({
      query: (id) => `${MENUS}/ChangeHistories?menuId=${id}`,
      transformResponse: (response) => response?.data,
      providesTags: [MENUS, MENUS_MANAGER],
    }),
    updateMenu: builder.mutation({
      query: (data) => {
        return {
          url: `${MENUS}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: MENUS, id: arg.id }],
    }),
    addMenu: builder.mutation({
      query: (data) => {
        return {
          url: `${MENUS}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: MENUS, id: arg.id }],
    }),
    addMenuDays: builder.mutation({
      query: (data) => {
        return {
          url: `${MENUS}/MealDays`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: MENUS, id: arg.id }],
    }),
    //Menus apis for Main Base Menus
    getMenuList: builder.query({
      query: ({ facilityId, seasonId = 0, searchTerm }) =>
        `${MENUS}?facilityId=${facilityId}&seasonId=${seasonId}&searchText=${searchTerm}&skip=0&take=1000`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: MENUS, id })), MENUS]
          : [MENUS];
      },
    }),
    deleteMenu: builder.mutation({
      query: (id) => ({
        url: `/${MENUS}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [{ type: MENUS, id: arg.id }],
    }),
    copyMenu: builder.mutation({
      query: (data) => {
        return {
          url: `/${MENUS}/Copy`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: (result) => [{ type: MENUS, id: result?.id }],
    }),
    //Menus apis for RecipeReprint screen
    getMenuRecipeReprintList: builder.query({
      query: ({ menuId, searchTerm }) =>
        `${MENUS}/RecipeReprintList?menuId=${menuId}&searchText=${searchTerm}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: MENUS, id })), MENUS]
          : [MENUS];
      },
    }),
    //Menus apis for Corporate Group Access
    shareCorporategroupAccess: builder.mutation({
      query: (data) => {
        return {
          url: `/${MENUS_MANAGER}/ShareWithCorporateGroup`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (response) => response,
      invalidatesTags: (result) => [{ type: MENUS, id: result?.id }],
    }),
    //Menus apis for OrderTransmission
    getOrderTransmissionsList: builder.query({
      query: ({ menuId }) => `${MENUS}/${ORDER_TRANSMISSIONS}?menuId=${menuId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({ type: ORDER_TRANSMISSIONS, id })),
              ORDER_TRANSMISSIONS,
            ]
          : [ORDER_TRANSMISSIONS];
      },
    }),
    ReviewOrder: builder.mutation({
      query: (data) => {
        return {
          url: `/${ORDER_TRANSMISSIONS}`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (response) => response,
      invalidatesTags: (result, error, arg) => [
        { type: ORDER_TRANSMISSIONS, id: arg.id },
      ],
    }),
    ChangeQuantityOrder: builder.mutation({
      query: (data) => {
        return {
          url: `/${ORDER_TRANSMISSIONS}/ChangeQuantities`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (response) => response,
      invalidatesTags: (result, error, arg) => [
        { type: ORDER_TRANSMISSIONS, id: arg.id },
      ],
    }),
    TransmitOrder: builder.mutation({
      query: (data) => {
        return {
          url: `/${ORDER_TRANSMISSIONS}/TransmitOrder`,
          method: "POST",
          body: data,
        };
      },
      transformResponse: (response) => response,
      invalidatesTags: (result, error, arg) => [
        { type: ORDER_TRANSMISSIONS, id: arg.id },
      ],
    }),
    //Menus apis for Missing Venodr screen
    getMenuMissingVendorList: builder.query({
      query: ({ menuId }) => `${MENUS}/MissingVendorItems?menuId=${menuId}`,
      transformResponse: (response) => response?.data?.missingVendorItems,
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: MENUS, id })), MENUS]
          : [MENUS];
      },
    }),

    //Menus apis for Print iTems Screen
    getDietlistPrint: builder.query({
      query: ({ menuId, facilityId }) =>
        `/${DIETS}/getmenudiets?menuId=${menuId}&facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: DIET, id })), DIET]
          : [DIET];
      },
    }),
    //TempOtherPostedMenu api for Print iTems Screen
    getTempOtherPostedMenuList: builder.query({
      query: (menuId) => `${MENUS}/TempOtherPostedMenu?menuId=${menuId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: MENUS, id })), MENUS]
          : [MENUS];
      },
    }),
    //TempPostedMenu api for Print iTems Screen for Standard posted menu selection
    getTempPostedMenuList: builder.query({
      query: ({ menuId, startDay, endDay, includeOther }) =>
        `${MENUS}/TempPostedMenu?menuId=${menuId}&startDay=${startDay}&endDay=${endDay}&includeOther=${includeOther}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: MENUS, id })), MENUS]
          : [MENUS];
      },
    }),
    getAllergenslistPrint: builder.query({
      query: () => `/${ALLERGY_FOODS}?skip=0&take=1000`,
      transformResponse: (response) => response?.data,
    }),
    PrintItemReport: builder.mutation({
      query: (data) => ({
        url: `${MENUS}/Print`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: MENUS, id: result?.id }],
    }),
    shareMenu: builder.mutation({
      query: ({ menuId, facilityId }) => {
        return {
          url: `${MENUS}/Share?menuId=${menuId}&facilityId=${facilityId}`,
          method: "POST",
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: (result) => [{ type: MENUS, id: result?.data?.id }],
    }),
    getMenuFacilities: builder.query({
      query: (menuId) => `${MENUS}/MenuFacilities?menuId=${menuId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: MENUS,
                id,
              })),
              MENUS,
            ]
          : [MENUS];
      },
    }),
    deleteMenuFacility: builder.mutation({
      query: (id) => ({
        url: `/${MENUS}/DeleteMenuFacility/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [{ type: MENUS, id: arg.id }],
    }),
    getMenuBulkVendorProducts: builder.query({
      query: ({ menuId, searchText }) =>
        `/${MENUS}/${BULK_VENDOR_PRODUCTS}?menuId=${menuId}&searchText=${searchText}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response?.data
          ? [
              ...response.map(({ id }) => ({
                type: BULK_VENDOR_PRODUCTS,
                id,
              })),
              BULK_VENDOR_PRODUCTS,
            ]
          : [BULK_VENDOR_PRODUCTS],
    }),
    updateMenuBulkVendorProduct: builder.mutation({
      query: (data) => ({
        url: `/${MENUS}/${BULK_VENDOR_PRODUCTS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: BULK_VENDOR_PRODUCTS, id: arg.id },
      ],
    }),
    getMenuPrintStyles: builder.query({
      query: (menuId) =>
        `${MENU_PRINT_STYLES}/${MENU_PRINT_STYLES}?menuId=${menuId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: MENU_PRINT_STYLES,
                id,
              })),
              MENU_PRINT_STYLES,
            ]
          : [MENU_PRINT_STYLES];
      },
    }),
    deleteMenuPrintStyle: builder.mutation({
      query: ({ menuId, mealId, day }) => ({
        url: `/${MENU_PRINT_STYLES}/${MENU_PRINT_STYLES}?menuId=${menuId}&mealId=${mealId}&day=${day}`,
        method: "DELETE",
      }),
      invalidatesTags: [MENU_PRINT_STYLES],
    }),
    signMenu: builder.mutation({
      query: ({ menuId, signedByName, signedByCredentials }) => {
        return {
          url: `/${MENUS}/Sign`,
          method: "POST",
          body: { menuId, signedByName, signedByCredentials },
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: (result) => [{ type: MENUS, id: result?.data?.id }],
    }),
    menuPrintStyles: builder.mutation({
      query: (data) => {
        return {
          url: `${MENU_PRINT_STYLES}/${MENU_PRINT_STYLES}`,
          method: "PUT",
          body: data,
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: [MENU_PRINT_STYLES],
    }),
    getMenuCensus: builder.query({
      query: ({ facilityId }) =>
        `/${MENUS}/GetMealCardCensus?facilityId=${facilityId}`,
      transformResponse: (response) => response?.data,
      keepUnusedDataFor: 0,
    }),
    CopyOtherMenu: builder.mutation({
      query: ({ fromMenuId, toMenuId }) => {
        return {
          url: `${MENUS}/CopyOtherMenuSelections?fromMenuId=${fromMenuId}&toMenuId=${toMenuId}`,
          method: "POST",
        };
      },
      transformResponse: (response) => response?.data,
      invalidatesTags: (result) => [{ type: MENUS, id: result?.data?.id }],
    }),
    //HEP INFO HEPContributions?menuId=72393&week=1&maxWeeks=1
    getHEPContributions: builder.query({
      query: ({ menuId, week, maxWeeks = 1 }) =>
        `${MENUS}/HEPContributions?menuId=${menuId}&week=${week}&maxWeeks=${maxWeeks}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: MENUS, id })), MENUS]
          : [MENUS];
      },
    }),
        //useGetNutrientAnalysisMenuContributionsQuery
        getNutrientAnalysisMenuContributions: builder.query({
          query: ({ menuId, facilityId }) =>
            `${MENUS}/NutrientAnalysisForMenu?menuId=${menuId}&facilityId=${facilityId}`,
          transformResponse: (response) => response?.data,
          providesTags: (response) => {
            return response
              ? [...response.map(({ id }) => ({ type: MENUS, id })), MENUS]
              : [MENUS];
          },
        }),   
  }),
});

// export hooks for calling endpoints
export const {
  useGetMenusBySeasonSelectBoxQuery,
  useGetMenusBySeasonSelectQuery,
  useGetMenusForActiveSeasonQuery,
  useGetMenusForUserQuery,
  useGetMenusForTableSideQuery,
  useGetMenuCategoriesSelectBoxQuery,
  useGetMenuItemsQuery,
  useGetMenuCategoriesQuery,
  useGetHepContributionsByRecipeQuery,
  useGetMenuListQuery,
  useGetMenuDaysByIdQuery,
  useDeleteMenuMutation,
  useAddMenuMutation,
  useAddMenuDaysMutation,
  useGetChangeHistoryQuery,
  useGetMenuByIdQuery,
  useUpdateMenuMutation,
  useCopyMenuMutation,
  useGetMenuRecipeReprintListQuery,
  useShareCorporategroupAccessMutation,
  useGetOrderTransmissionsListQuery,
  useReviewOrderMutation,
  useTransmitOrderMutation,
  useChangeQuantityOrderMutation,
  useGetMenuMissingVendorListQuery,
  useGetDietlistPrintQuery,
  useGetTempOtherPostedMenuListQuery,
  useGetTempPostedMenuListQuery,
  useGetAllergenslistPrintQuery,
  usePrintItemReportMutation,
  useShareMenuMutation,
  useGetMenuFacilitiesQuery,
  useDeleteMenuFacilityMutation,
  useGetMenuBulkVendorProductsQuery,
  useUpdateMenuBulkVendorProductMutation,
  useGetMenuPrintStylesQuery,
  useSignMenuMutation,
  useMenuPrintStylesMutation,
  useGetMenuCensusQuery,
  useCopyOtherMenuMutation,
  useDeleteMenuPrintStyleMutation,
  useGetMenuFooterByIdQuery,
  useGetHEPContributionsQuery,
  useGetNutrientAnalysisMenuContributionsQuery,
} = MenuApis;
