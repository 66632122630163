import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { TAGS } = API_ENDPOINTS;
const TAGICONS = "TagIcons";

export const TagsAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting Tags
    getTags: builder.query({
      query: (typeId) => `/${TAGS}?typeId=${typeId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: TAGS, id })), TAGS]
          : [TAGS];
      },
    }),
    getTagsSelectList: builder.query({
      query: (typeId) => `/${TAGS}?typeId=${typeId}`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res?.id,
            label: res?.name,
          };
        }),
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: TAGS, id })), TAGS]
          : [TAGS];
      },
    }),
    getTagById: builder.query({
      query: (tagId) => `/${TAGS}/${tagId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response ? [{ type: TAGS, id: response.id }, TAGS] : [TAGS],
    }),
    deleteTag: builder.mutation({
      query: (id) => ({
        url: `/${TAGS}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [{ type: TAGS, id: arg.id }],
    }),
    addTag: builder.mutation({
      query: (data) => ({
        url: `/${TAGS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: TAGS, id: result?.id }],
    }),
    updateTag: builder.mutation({
      query: (data) => ({
        url: `/${TAGS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: TAGS, id: result?.id }],
    }),
    getTagIcons: builder.query({
      query: () => `/${TAGS}/icons`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res?.url,
            label: res?.name,
          };
        }),
      providesTags: (response) => {
        return response
          ? [...response.map(({ id }) => ({ type: TAGICONS, id })), TAGICONS]
          : [TAGICONS];
      },
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetTagsQuery,
  useGetTagsSelectListQuery,
  useGetTagByIdQuery,
  useDeleteTagMutation,
  useAddTagMutation,
  useUpdateTagMutation,
  useGetTagIconsQuery,
} = TagsAPIs;
