import React, { useState } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useFacilityAssignedUsersQuery } from "../../../../../../store/apis/FacilityApis";
import { IconButton, Tooltip, MenuItem } from "@mui/material";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import dayjs from "dayjs";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import { BaseButton } from "components/shared/BaseButton";
import { b2cPolicies, loginRequest } from "authConfig";
import { useMsal } from "@azure/msal-react";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { PersonAdd } from "@mui/icons-material";
import BaseActionBox from "components/shared/BaseActionBox";
import BaseMenu from "components/shared/BaseMenu";
import { ASSIGNED_USER_MENU_ITEMS } from "./AssignedUsers.constants";
import TempPassword from "components/admin/groupMenu/userMenu/userSetup/tempPassword/TempPassword";
import CreateBaseColumns from "components/shared/CreateBaseColumn";

export default function AssignedUsers() {
  const { instance } = useMsal();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const id = searchParams.get("id");

  const {
    data: users,
    isLoading,
    isError,
  } = useFacilityAssignedUsersQuery(id, {
    refetchOnMountOrArgChange: true,
    skip: !id,
  });
  async function handleLogOnAs(userName) {
    const request = {
      authority: b2cPolicies.authorities.logonAs.authority,
      scopes: loginRequest.scopes,
      extraQueryParameters: {
        targetEmail: userName,
        // eslint-disable-next-line no-undef
        server: process.env.REACT_APP_BASE,
      },
    };

    await instance.loginPopup(request);

    navigate("/home");
  }

  const userColumns = [
    {
      field: "fullName",
      headerName: "User",
      flex: 2,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Link
            to={`/admin/usermenu/usersetup/adduser?id=${params.row["userId"]}`}
            style={{ textDecoration: "none", color: "blue" }}
          >
            {`${params.row.firstName} ${params.row.lastName}`}
          </Link>
        );
      },
    },
    {
      field: "lastLogin",
      minWidth: 50,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerName: "Last Login",
      valueGetter: (value) => dayjs(value).toDate(),
      valueFormatter: (value) => {
        const isDateValid = dayjs(value).isValid();
        return isDateValid
          ? dayjs(value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },

    {
      field: "archived",
      minWidth: 50,
      flex: 1,
      headerName: "Archived",
      headerAlign: "center",
      align: "center",
      isBoolean: true,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        const name = `${params.row?.firstName} ${params.row?.lastName}`;
        const id = params.row?.userId;
        return (
          <>
            <BaseActionBox
              index={params.row.id}
              data={users?.users}
              hasDelete={true}
              deleteProps={{
                hintText: `Are you sure you want to delete "${name}"?`,
                apiPath: "deleteFacilityUser",
                entityName: name,
                title: "Assigned User",
              }}
              childrenLocation={"end"}
            >
              <IconButton
                aria-label={`Log on as ${params.row?.username}`}
                variant="outlined"
                color="primary"
                onClick={() => {
                  handleLogOnAs(params.row?.username);
                }}
                disableRipple
              >
                <Tooltip title={`Log on as ${params.row?.username}`}>
                  <PersonAdd />
                </Tooltip>
              </IconButton>

              {/* <BaseMenu index={params.row.id}>
                {ASSIGNED_USER_MENU_ITEMS({ id, name }).map((item, idx) => (
                  <MenuItem key={idx} component={Link} to={item.link} disableRipple>
                    {item.label}
                  </MenuItem>
                ))}
                <MenuItem
                  onClick={() => {
                    setOpenModalPass(true);
                  }}
                  disableRipple>
                  Reset Password
                </MenuItem>          
              </BaseMenu>
              {openModalPass && (
                <TempPassword
                  id={id}
                  fullname={name}
                  username={params.row?.username}
                  open={openModalPass}
                  close={() => setOpenModalPass(false)}
                />
              )} */}

              <UserOptions
                index={params.row.id}
                id={id}
                name={name}
                username={params.row?.username}
              />
            </BaseActionBox>
          </>
        );
      },
    },
  ];

  return (
    <>
      <FlexBox justifyContent={"start"} my={"8px"}>
        <BaseButton
          text={"Add User"}
          To={`addassigneduser?id=${id}`}
          endIcon={<PersonAdd />}
        />
      </FlexBox>
      <BaseDataGrid
        rows={users?.users}
        columns={CreateBaseColumns(userColumns)}
        error={isError}
        loading={isLoading}
        defaultPageSize={100}
      />
    </>
  );
}

const UserOptions = ({ index, id, name, username }) => {
  const [openModalPass, setOpenModalPass] = useState(false);

  return (
    <>
      <BaseMenu index={index}>
        {ASSIGNED_USER_MENU_ITEMS({ id, name }).map((item, idx) => (
          <MenuItem key={idx} component={Link} to={item.link} disableRipple>
            {item.label}
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => {
            setOpenModalPass(true);
          }}
          disableRipple
        >
          Reset Password
        </MenuItem>
      </BaseMenu>
      {openModalPass && (
        <TempPassword
          id={id}
          fullname={name}
          username={username}
          open={openModalPass}
          close={() => setOpenModalPass(false)}
        />
      )}
    </>
  );
};
