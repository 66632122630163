/* eslint-disable */
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import React, { useEffect, useState } from "react";
import { userInformation } from "./forms.constants";
import { useFormContext } from "react-hook-form";
import { Grid, Grid2 } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export const UserInformationV2 = ({ defaultValues }) => {
  const [searchparams] = useSearchParams();
  const [hiddenList, setHiddenList] = useState([]);
  const editId = searchparams.get("id");

  useEffect(() => {
    if (!!editId) {
      setHiddenList([
        {
          name: "password",
        },
      ]);
    }
  }, [editId]);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid container spacing={2}>
      <GeneratedForm
        list={userInformation}
        control={control}
        errors={errors}
        hiddenList={hiddenList}
        defaultOptions={defaultValues}
      />
    </Grid>
  );
};
