import { Grid, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import GeneratedForm from "../../../shared/generatedForm/GeneratedForm";
import BaseSubmitButton from "../../../shared/baseSubmitButton/BaseSubmitButton";
import { useForm } from "react-hook-form";
import { MenuDaysFields } from "./form.constant";
import {
  useAddMenuDaysMutation,
  useGetMenuByIdQuery,
  useGetMenuDaysByIdQuery,
} from "../../../../store/apis/MenuApis";
import { WarningText } from "../../../shared/WarningText";
import { useSnackbar } from "notistack";
import BaseContent from "../../../shared/baseContent/BaseContent";

export const MenuDays = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("menuId");
  const { enqueueSnackbar } = useSnackbar();
  const [defaultValues, setDefaultValues] = useState([]);
  //query for a specific id menu
  const {
    data: MenuData,
    // isLoading: currentMenuDataLoading,
    // isSuccess: currentMenuDataSuccess,
  } = useGetMenuByIdQuery(id, { skip: !id });
  const {
    data: menuDaysData,
    isLoading: MenudaysDataLoading,
    isSuccess: MenudaysDataSuccess,
  } = useGetMenuDaysByIdQuery(id, { skip: !id });
  const [
    addMenuDays,
    {
      data: menudaysData,
      isLoading: menuDaysLoading,
      isSuccess: menuDaysSuccess,
    },
  ] = useAddMenuDaysMutation();
  console.log(menudaysData);
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const breakfast = watch("breakfast");
  const lunch = watch("lunch");
  const supper = watch("supper");
  const morningSnack = watch("morningSnack");
  const afternoonSnack = watch("afternoonSnack");
  const eveningSnack = watch("eveningSnack");

  const isAnyMealSelected =
    breakfast ||
    lunch ||
    supper ||
    morningSnack ||
    afternoonSnack ||
    eveningSnack;

  useEffect(() => {
    if (MenudaysDataSuccess && !MenudaysDataLoading) {
      reset(menuDaysData);
      setDefaultValues([...defaultValues]);
    }
  }, [menuDaysData, MenudaysDataSuccess, MenudaysDataLoading]);
  useEffect(() => {
    if (menuDaysSuccess) {
      enqueueSnackbar(
        `Days/Meals: 
          Save
         successfully.`,
        { variant: "success" }
      );
      navigate(-1);
    }
  }, [menuDaysSuccess]);
  // function for getting data from form
  const onSubmit = (data) => {
    const formData = {
      ...data,
      menuId: id,
      menuName: MenuData?.menuName,
    };
    addMenuDays(formData);
  };
  return (
    <>
      <BaseContent
        headerText={"Menu Days"}
        backLink={"/plateFul/Menus"}
        disableHeight={true}
      >
        <Box
          sx={{
            height: { md: "73vh", xl: "80vh" },
            margin: "20px",
          }}
        >
          <Typography
            variant="h2"
            component="h1"
            sx={{
              fontSize: "30px",
            }}
          >
            {`Menu: `}
            {MenuData?.menuName}
          </Typography>
          <WarningText
            margin={"15px 0"}
            text={`WARNING: If you enter a value that is lower than the number of days
          currently in the menu, then the extra days/meals will be deleted from
          the database!`}
            color="warning"
            width="fitContent"
          />
          <Grid
            container
            spacing={2}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <GeneratedForm
              list={MenuDaysFields}
              control={control}
              setValue={setValue}
              errors={errors}
              defaultOptions={defaultValues}
            />
          </Grid>

          <Grid item md={6} sx={{ width: "100%" }}>
            <BaseSubmitButton
              onClick={handleSubmit(onSubmit)}
              sx={{ width: "48%", margin: "20px 0" }}
              isSubmitting={menuDaysLoading}
              text={"Save"}
              disabled={!isAnyMealSelected}
            />
          </Grid>
        </Box>
      </BaseContent>
    </>
  );
};
