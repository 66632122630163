/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import {
  NavigationType,
  useBlocker,
  useNavigate,
  useNavigationType,
} from "react-router-dom";
import BlockerModal from "./BlockerModal";
import { isEmpty } from "lodash";

const BaseBlockerModal2 = ({
  text,
  isDirty,
  dirtyFields,
  setHasChange,
  hasChange,
}) => {
  const [blockerModal, setBlockerModal] = useState(false);
  const [navigateBack, setNavigateBack] = useState(false);
  const navigationType = useNavigationType();

  const navigate = useNavigate();

  const areFieldsDirty = isDirty && !isEmpty(dirtyFields);

  // UnSaved Changes Feature here
  // Block navigating elsewhere when data has been entered into the input
  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) => {
      if (navigateBack) return false;
      return hasChange && currentLocation.pathname !== nextLocation.pathname;
    },
    [hasChange, navigateBack, navigationType]
  );

  useEffect(() => {
    setHasChange(areFieldsDirty);
  }, [areFieldsDirty]);

  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (navigationType === NavigationType.Pop && navigateBack) {
        blocker.proceed();
      } else setBlockerModal(true);
    }

    if (
      blocker.state === "unblocked" &&
      navigationType === NavigationType.Pop &&
      hasChange
    ) {
      navigate(-1);
    }
  }, [blocker, navigationType]);

  // Page Reload and navigation alert implementations
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasChange) {
        event.preventDefault();
        event.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChange]);

  const handleOnLeave = () => {
    setNavigateBack(true);
    if (navigationType === NavigationType.Pop) {
      navigate(-1);
    }
    blocker.proceed();
    setBlockerModal(false);
  };
  const handleOnStay = () => {
    blocker.reset();

    setBlockerModal(false);
  };
  return (
    <>
      {blockerModal ? (
        <BlockerModal
          text={
            text ||
            `Hold up! You've got unsaved changes. Are you sure you want to leave?`
          }
          open={blockerModal}
          onStay={handleOnStay}
          onLeave={handleOnLeave}
        />
      ) : null}
    </>
  );
};

export default BaseBlockerModal2;
