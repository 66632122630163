import { MAX_LENGTH_VALIDATION, REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../../shared/generatedForm/GeneratedForm";
export const RecipeIngredientsFields = [
  {
    title: "Ingredient",
    props: {
      name: "ingredientId",
      id: "ingredientId",
      placeholder: "Select an ingredient",
      // label: "Ingredient",
    },
    validationProps: {
      required: REQUIRED_ERROR("Ingredient"),
    },
    size: 8,
    type: FORM_TYPES.AutocompleteAPI,
    endpoint: "getRecipeIngredientsName",
  },
  {
    props: {},
    size: 4,
    type: FORM_TYPES.Offset,
  },
];

export const RecipeIngredientsFields2 = [
  {
    title: "Preparation Note",
    props: {
      name: "description",
      id: "description",
      // label: "Preparation",
    },
    validationProps: {
      maxLength: MAX_LENGTH_VALIDATION("Preparation Note", 100),
    },
    size: 8,
  },
  {
    props: {},
    size: 4,
    type: FORM_TYPES.Offset,
  },
  {
    title: "Sort",
    props: {
      name: "sortOrder",
      id: "sortOrder",
      // label: "Sort",
      type: "number",
      defaultValue: 0,
    },
    validationProps: {
      min: { value: 0, message: "Value must be equal or greater than 0 " },
      max: { value: 99999, message: "Value must be 5 digits or fewer" },
    },
    size: 8,
  },
  {
    props: {},
    size: 4,
    type: FORM_TYPES.Offset,
  },
  {
    title: "Percentage Yield",
    props: {
      name: "percentToIncludeInAnalysis",
      id: "percentToIncludeInAnalysis",
      // label: "% to include",
      type: "number",
      defaultValue: 100,
    },
    validationProps: {
      min: { value: 0, message: "Value must be equal or greater than 0 " },
      max: { value: 99999, message: "Value must be 5 digits or fewer" },
    },
    size: 6,
  },
  {
    props: {
      name: "addSpaceAfter",
      id: "addSpaceAfter",
      label: "Include Space After Ingredient",
      defaultValue: false,
    },
    size: 12,
    type: FORM_TYPES.Checkbox,
  },
];
