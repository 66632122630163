import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

/**
 * @see [Documentation](../../../Wiki/Documentations/FrontEnd/Components/BaseButton.md)
 */
export const BaseButton = ({
  colors = "primary",
  variant,
  To,
  state,
  endIcon,
  startIcon,
  text,
  onClick,
  isSubmitting,
  target,
  fontColor,
  border,
  disabled = false,
  zIndex,
  marginLeft,
  sx,
  loadingColor,
  ...rest
}) => {
  const renderIcon = (icon, isSubmitting, loadingColor) => {
    if (isSubmitting) {
      return (
        <CircularProgress
          color={loadingColor ? "primary" : "white"}
          size={18}
        />
      );
    } else if (icon) {
      return typeof icon === "string" ? (
        <span className="material-icons">{icon}</span>
      ) : (
        icon
      );
    }
    return null;
  };

  const StartIcon = renderIcon(startIcon, isSubmitting, loadingColor);
  const EndIcon = renderIcon(endIcon, false, loadingColor);

  return (
    <Button
      {...rest}
      state={state}
      component={Link}
      to={To}
      target={target ?? ""}
      rel={target ? "noopener noreferrer" : ""}
      color={colors}
      disabled={isSubmitting || disabled}
      sx={{
        zIndex: zIndex || "",
        color: fontColor,
        border,
        cursor: "pointer",
        marginLeft,
        backgroundColor:
          variant === "outlined" && isSubmitting ? "#E8E8E8 !important" : "",
        ...sx,
      }}
      variant={variant || "contained"}
      startIcon={StartIcon}
      endIcon={EndIcon}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};
