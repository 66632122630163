import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../shared/baseDataGrid/BaseDataGrid";
import { useGetHEPContributionsQuery } from "../../../../store/apis/MenuApis";
import { Box, Typography } from "@mui/material";
import CreateBaseColumns from "components/shared/CreateBaseColumn";
import BaseContent from "components/shared/baseContent/BaseContent";

export default function HepResults() {
  const [searchParams] = useSearchParams();
  const menuId = searchParams.get("menuId");
  const week = searchParams.get("week");
  const [rowData, setRowData] = useState([]);

  const {
    data: hepData,
    isFetching: hepLoading,
    isSuccess,
  } = useGetHEPContributionsQuery(
    { menuId: menuId, week: week, maxWeeks: 1 },
    {
      refetchOnMountOrArgChange: true,
      skip: !menuId, // Skip the initial request
    }
  );

  useEffect(() => {
    if (hepData?.length > 0) {
      setRowData(hepData);
    }
  }, [isSuccess]);

  const HEPColumns = [
    {
      field: "mealTimeText",
      headerName: "Meal Time",
      flex: 1,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "hepTextName",
      headerName: "HEP Category",
      flex: 1.8,
      headerAlign: "center",
      align: "left",
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepSundayServ",
      headerName: "Sunday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepMondayServ",
      headerName: "Monday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepTuesdayServ",
      headerName: "Tuesday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepWednesdayServ",
      headerName: "Wednesday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepThursdayServ",
      headerName: "Thursday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepFridayServ",
      headerName: "Friday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepSaturdayServ",
      headerName: "Saturday",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
    {
      field: "hepServingContributionAvg",
      headerName: "AVG",
      headerAlign: "center",
      align: "center",
      flex: 1,
      minWidth: 10,
      rowSpanValueGetter: () => null,
    },
  ];

  return (
    <>
      <BaseContent headerText={"HEP Calculations"} disableHeight={true}>
        <Box padding={1}>
          <Box paddingY={1} display="flex" justifyContent="end">
            <div className="MenuBulkHeaderRight">
              <div className="MenuBulkHeaderRightRow">
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Within Range : Yes
                </div>
                <div className="MenuBulkBlueSample"></div>
              </div>
              <div className="MenuBulkHeaderRightRow">
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Within Range : No
                </div>
                <div className="MenuBulkYellowSample"></div>
              </div>
            </div>
          </Box>
          <Box>
            <Typography variant="h6">
              <span style={{ fontWeight: "bold" }}>
                HEP Calculations Week :{" "}
              </span>
              {rowData[0]?.menuWeekId}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "auto",
              width: "100%",
              "& .MuiInputBase-root": {
                "& input[type=number]": {
                  textAlign: "right",
                },
              },
              "& .super-app-theme--NotVendorItem": {
                backgroundColor: "#FFEFB6",
                "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
                  backgroundColor: "#FFEFB6",
                },
                ":hover": {
                  backgroundColor: "rgba(255, 239, 182, 0.5) !important",
                },
              },
              "& .super-app-theme--NotBackfeedItem": {
                backgroundColor: "#AEE4E5",
                "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
                  backgroundColor: "#AEE4E5",
                },
                ":hover": {
                  backgroundColor: "rgba(174, 228, 229, 0.5) !important",
                },
              },
            }}
          >
            <BaseDataGrid
              rows={hepData || []}
              columns={CreateBaseColumns(HEPColumns)}
              unstable_rowSpanning={true}
              loading={hepLoading}
              height="70vh"
              getRowClassName={(params) =>
                params.row.withinRange == undefined
                  ? ""
                  : params?.row?.withinRange == true
                  ? "super-app-theme--NotBackfeedItem"
                  : "super-app-theme--NotVendorItem"
              }
            />
          </Box>
        </Box>
      </BaseContent>
    </>
  );
}
