import React from "react"; //{ useEffect }
import "../Ingredient.css";
import { useSearchParams, Link } from "react-router-dom";
import { useGetUnitConversionQuery } from "../../../../../../store/apis/IngredientAPIs";
import { Typography, Button } from "@mui/material";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { AddBusiness } from "@mui/icons-material";

export default function UnitConversion() {
  const [searchParams] = useSearchParams();
  const ingredientId = searchParams.get("id");
  const ingredientName = searchParams.get("name");

  const {
    data: unitConversionList,
    isError: isError,
    isFetching: isLoading,
  } = useGetUnitConversionQuery(ingredientId);

  function actionDisabled(unit1, unit2) {
    return (
      (unit1 === 32 && (unit2 === 13 || unit2 === 14)) ||
      (unit2 === 32 && (unit1 === 13 || unit1 === 14))
    );
  }

  const UnitConversionColumns = [
    {
      field: "quantity",
      headerName: "Quantity 1",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            variant="body1"
            component="div"
          >{`${params.row.quantity} ${params.row.unitName}`}</Typography>
        );
      },
    },
    {
      field: "convertToQuantity",
      headerName: "Quantity 2",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Typography
            variant="body1"
            component="div"
          >{`${params.row.convertToQuantity} ${params.row.convertToUnitName}`}</Typography>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={unitConversionList}
            hasEdit={true}
            editProps={{
              To: `addUnitConversion?id=${params.row.id}`,
              title: "Unit Conversion",
              disabled: actionDisabled(
                params.row.unitId,
                params.row.convertToUnitId
              ),
            }}
            hasDelete={true}
            deleteProps={{
              entityName: `${params.row.quantity} ${params.row.unitName} to ${params.row.convertToQuantity} ${params.row.convertToUnitName} conversion for ${ingredientName}`,
              apiPath: "deleteUnitConversion",
              title: "Unit Conversion",
              disabled: actionDisabled(
                params.row.unitId,
                params.row.convertToUnitId
              ),
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        justifyContent={"flex-end"}
        my={"8px"}
        flexWrap={{ xs: "wrap", md: "nowrap" }}
      >
        <Button
          component={Link}
          to={`addUnitConversion?ingredientId=${ingredientId}`}
          color={"primary"}
          variant="contained"
          endIcon={<AddBusiness />}
        >
          Add Conversion
        </Button>
      </FlexBox>
      <Typography
        variant="h5"
        className="userFacilityTitle"
        sx={{
          mb: "8px",
          color: "primary",
        }}
      >
        Conversions for {ingredientName}
      </Typography>
      <BaseDataGrid
        rows={unitConversionList}
        columns={UnitConversionColumns}
        loading={isLoading}
        error={isError}
        sx={{
          "& .MuiDataGrid-cell": {
            display: "flex",
            alignItems: "center",
          },
        }}
      />
    </>
  );
}
