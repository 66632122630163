import { MAX_LENGTH_VALIDATION, REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../../../../shared/generatedForm/GeneratedForm";

export const RESIDENT_ASSESSMENT_FIELDS = [
  {
    props: {
      name: "residentName",
      id: "residentName",
      label: "Resident Name",
      size: "small",
    },
    validationProps: {
      required: REQUIRED_ERROR("Resident Name"),
      maxLength: MAX_LENGTH_VALIDATION("Resident Name", 40),
    },
    size: 3,
  },

  {
    props: {
      name: "residentRoomNumber",
      id: "residentRoomNumber",
      label: "Room Number",
      size: "small",
    },
    validationProps: {
      required: REQUIRED_ERROR("Room Number"),
      maxLength: MAX_LENGTH_VALIDATION("Room Number", 10),
    },
    size: 2.5,
  },

  {
    props: {
      name: "residentTypeId",
      id: "residentTypeId",
      label: "Resident Type",
      size: "small",
      defaultValue: { id: 1 },
    },
    validationProps: {
      required: REQUIRED_ERROR("Resident Type"),
    },
    size: 2.5,
    type: FORM_TYPES.SelectAPI,
    endpoint: "possibleResidentTypes",
  },

  {
    props: {
      name: "assessmentTypeId",
      id: "assessmentTypeId",
      label: "Documentation Type",
      size: "small",
    },
    validationProps: {
      required: REQUIRED_ERROR("Assessment Type"),
    },
    size: 3,
    type: FORM_TYPES.SelectAPI,
    endpoint: "possibleAssessmentTypes",
  },

  {
    props: {
      name: "subTypes",
      id: "subTypes",
      label: "Assessment Sub Type",
    },
    size: 6,
    // type: FORM_TYPES.MultiSelectAPI,
    type: FORM_TYPES.MultiSelectSearchApi,
    endpoint: "possibleSubTypes",
  },
  {
    props: {
      name: "subTypesOffset",
    },
    size: 6,
    type: FORM_TYPES.Offset,
  },
  {
    props: {
      name: "summaryNutritionalCare",
      id: "summaryNutritionalCare",
      label: "Summary",
      minRows: 3,
      maxRows: 15,
      multiline: true,
    },
    validationProps: {
      required: REQUIRED_ERROR("Summary"),
    },
    size: 6,
  },
  {
    props: {},
    size: 6,
    type: FORM_TYPES.Offset,
  },
  {
    props: {
      name: "dietOrderChangeNeeded",
      id: "dietOrderChangeNeeded",
      label: "Diet Order/Change in Medical Order Change Needed",
    },
    size: 6,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {},
    size: 6,
    type: FORM_TYPES.Offset,
  },
  {
    props: {
      name: "newDiet",
      id: "newDiet",
      label: "Change Diet or Supplement To:",
    },
    validationProps: {
      required: REQUIRED_ERROR("Change Diet"),
      maxLength: {
        value: 150,
        message:
          "Change Diet or Supplement To should be less than 150 characters ",
      },
    },
    size: 6,
  },
  {
    props: { name: "newDietOffset" },
    size: 6,
    type: FORM_TYPES.Offset,
  },

  {
    props: {
      name: "changeToTrayCardNeeded",
      id: "changeToTrayCardNeeded",
      label: "Change to Meal Card Needed",
    },
    size: 6,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {},
    size: 6,
    type: FORM_TYPES.Offset,
  },
  {
    props: {
      name: "changeToTrayCardComment",
      id: "changeToTrayCardComment",
      label: "Change MealCard Comment:",
    },
    validationProps: {
      required: REQUIRED_ERROR("Change MealCard Comment"),
      maxLength: MAX_LENGTH_VALIDATION("Change MealCard Comment", 250),
    },
    size: 6,
  },
  {
    props: { name: "changeToTrayCardCommentOffset" },
    size: 6,
    type: FORM_TYPES.Offset,
  },

  {
    props: {
      name: "changeToCarePlanNeeded",
      id: "changeToCarePlanNeeded",
      label: "Change to Care Plan Needed",
    },
    size: 6,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {},
    size: 6,
    type: FORM_TYPES.Offset,
  },
  {
    props: {
      name: "changeToCarePlanComment",
      id: "changeToCarePlanComment",
      label: "Change Care Plan Comment:",
    },
    validationProps: {
      required: REQUIRED_ERROR("Change Care Plan Comment"),
      maxLength: MAX_LENGTH_VALIDATION("Change Care Plan Comment", 250),
    },
    size: 6,
  },
  {
    props: { name: "changeToCarePlanCommentOffset" },
    size: 6,
    type: FORM_TYPES.Offset,
  },
  {
    props: {
      name: "otherNeeded",
      id: "otherNeeded",
      label: "Other",
    },
    size: 6,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {},
    size: 6,
    type: FORM_TYPES.Offset,
  },
  {
    props: {
      name: "otherComment",
      id: "otherComment",
      label: "Other Comment:",
    },
    validationProps: {
      required: REQUIRED_ERROR("Other Comment"),
      maxLength: MAX_LENGTH_VALIDATION("Other Comment", 250),
    },
    size: 6,
  },
  {
    props: { name: "otherCommentOffset" },
    size: 6,
    type: FORM_TYPES.Offset,
  },
  {
    props: {
      name: "noChangeNeeded",
      id: "noChangeNeeded",
      label: "No Change Needed",
    },
    size: 6,
    type: FORM_TYPES.Checkbox,
  },
  {
    props: {},
    size: 6,
    type: FORM_TYPES.Offset,
  },
];

export const RESIDENT_ASSESSMENT_DEFAULTS = {
  visitReportTypeId: 4,
  assessmentTypeId: "",
};
