import { Box } from "@mui/material";
import { EditSkeleton } from "components/admin/admin.overlays";
import BaseBanner from "components/shared/baseBanner/BaseBanner";
import { BaseButton } from "components/shared/BaseButton";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useGetVisitReportTypeByIdQuery } from "store/apis/VisitReportAPIs";
import { DMStyles } from "styles/Styles";
import { theme } from "styles/theme";
import SectionGroup from "./SectionGroup";
import { FormProvider, useForm } from "react-hook-form";

export default function PreviewVisitReportType() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const { data, isLoading } = useGetVisitReportTypeByIdQuery(id);

  const methods = useForm();

  function onSubmit(data) {
    console.log(data);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
      }}
    >
      {isLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <BaseBanner
            width={"100%"}
            fontWeight={"600"}
            titleProps={{
              variant: "h4",
            }}
            bgColor={DMStyles.Teal}
            color={DMStyles.White}
            title="Preview Visit Report Type"
          />
          <BaseBanner
            width={"100%"}
            fontWeight={"600"}
            titleProps={{
              variant: "h5",
            }}
            bgColor={theme.palette[data?.color].dark}
            color={DMStyles.White}
            title="PDMP"
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <BaseButton
              variant={"outlined"}
              colors="white"
              To={"/admin/dietitian/visitReportBuilder/addReportType?id=" + id}
              text={"Edit"}
            />
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <BaseButton
                text={"Printable Version"}
                variant={"outlined"}
                colors="primary"
              />
              <BaseButton
                text={"Save Report"}
                variant={"contained"}
                onClick={() => methods.handleSubmit(onSubmit)()}
                colors="primary"
              />
            </Box>
          </Box>
          <FormProvider {...methods}>
            {data?.sectionGroups?.map((sectionGroup) => {
              return (
                <SectionGroup
                  key={sectionGroup.id}
                  sectionGroup={sectionGroup}
                />
              );
            })}
          </FormProvider>
        </>
      )}
    </Box>
  );
}
