import { Box, Grid2, Skeleton, Typography } from "@mui/material";
import isEqual from "lodash/isEqual";
import React from "react";
import {
  innerLogsData,
  innerQuantityData,
  logsHeaders,
  mileageHeaders,
  totalLogData,
  finishedSubmittedDates,
} from "../constants";
import size from "lodash/size";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import { theme } from "styles/theme";

const VisitHoursTable = ({
  hasLoading,
  visitHours,
  nextConsult,
  totalMileage,
  totalBillableHour,
  totalOtherQuantity,
  submittedDate,
  finishedDate,
}) => {

  const visitType0 = visitHours?.filter((visit) => visit.consultingRateType === 0);
  const visitType1 = visitHours?.filter((visit) => visit.consultingRateType === 1);

  const renderHeader = (headers) => {
    return(
      headers?.map((item) => {
        return (
          <Grid2
            key={item.id}
            item
            size={{ xs: item.size }}
            sx={{
              textAlign: item?.textAlign,
            }}
          >
            <Typography
              variant="h7"
              sx={{
                lineHeight: "24px",
                fontSize: "15px",
                fontWeight: "700",
                color: "white",
              }}
            >
              {item.name}
            </Typography>
          </Grid2>
        );
      })
    );
  }

  return (
    <>
      {!hasLoading ? (
        <Box>
          <Box>
            <Grid2
              sx={{
                padding: "5px",
                backgroundColor: "#F6F6F6",
                alignItems: "center",
                borderTop: "1px solid #00000033",
              }}
              container
            >
              {renderHeader(logsHeaders)}
            </Grid2>
          </Box>

          {visitType0?.map((visit, index) => {
            
            return (
              <Box key={index}>

                <Grid2
                    container
                    sx={{
                      borderTop: "1px solid #00000033",
                      alignItems: "center",
                    }}
                  >
                    <Grid2
                      item
                      size={{ xs: 3 }}
                      sx={{
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant="h7"
                        sx={{
                          lineHeight: "18px",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "white",
                        }}
                      >
                        {visit?.consultingTypeName}
                      </Typography>
                    </Grid2>
                    <Grid2
                      item
                      size={{ xs: 9 }}
                      sx={{
                        borderLeft: "1px solid #00000033",
                      }}
                    >
                      <Grid2 container direction="column">
                        <Grid2
                          container
                          sx={{
                            borderRight: "1px solid #00000033",
                            height: "100%",
                          }}
                        >
                          {innerLogsData.map((itemData) => {
                            const visitData = visit[itemData.name];
                            return (
                              <Grid2
                                key={itemData.id}
                                item
                                size={{ xs: itemData.size }}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textAlign: itemData.textAlign,
                                  padding: "5px",
                                  borderRight: itemData.border
                                    ? undefined
                                    : "1px solid #00000033",
                                }}
                              >
                                <Typography
                                  variant="h7"
                                  sx={{
                                    lineHeight: "18px",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "white",
                                  }}
                                >
                                  {visitData}
                                </Typography>
                              </Grid2>
                            );
                          })}
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </Grid2>

              </Box>
            );
          })}

          <Box>
            <Grid2
              sx={{
                padding: "5px",
                backgroundColor: "#F6F6F6",
                alignItems: "center",
                borderTop: "1px solid #00000033",
              }}
              container
            >
              {renderHeader(mileageHeaders)}
            </Grid2>
          </Box>

          {visitType1?.map((visit, index) => {
            
            return (
              <Box key={index}>
                
                <Grid2
                    container
                    sx={{
                      borderTop: "1px solid #00000033",
                      alignItems: "center",
                    }}
                  >
                    <Grid2
                      item
                      size={{ xs: 3 }}
                      sx={{
                        padding: "5px",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant="h7"
                        sx={{
                          lineHeight: "18px",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "white",
                        }}
                      >
                        {visit?.consultingTypeName}
                      </Typography>
                    </Grid2>
                    <Grid2
                      item
                      size={{ xs: 9 }}
                      sx={{
                        borderLeft: "1px solid #00000033",
                      }}
                    >
                      <Grid2 container direction="column">
                        <Grid2
                          container
                          sx={{
                            borderRight: "1px solid #00000033",
                            height: "100%",
                          }}
                        >
                          {innerQuantityData.map((itemData) => {
                            const visitData = visit[itemData.name];
                            const isMileage = visit["consultingTypeId"] === 1;
                            return (
                              <Grid2
                                key={itemData.id}
                                item
                                size={{ xs: itemData.size }}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textAlign: itemData.textAlign,
                                  padding: "5px",
                                  borderRight: itemData.border
                                    ? undefined
                                    : "1px solid #00000033",
                                }}
                              >
                                <Typography
                                  variant="h7"
                                  sx={{
                                    lineHeight: "18px",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    color: "white",
                                  }}
                                >
                                  { itemData.name == "quantity" ? isMileage ? (`${visitData} miles`) : (`$ ${visitData}`) : visitData }
                                </Typography>
                              </Grid2>
                            );
                          })}
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </Grid2>

              </Box>
            );
          })}

          {!isEqual(size(visitHours), 0) ? (
            <Grid2
              sx={{
                backgroundColor: theme.palette.yellow.main,
                alignItems: "center",
                borderTop: "1px solid black",
              }}
              container
            >
              {totalLogData?.map((item, index) => {
                const visitTotals = {
                  nextConsult: nextConsult
                    ? dayjs(nextConsult).format(SCREEN_CONSTANTS.Date_Format)
                    : "",
                  totalMileage,
                  totalOtherQuantity,
                  totalBillableHour,                  
                };
                const totalLogsValue = visitTotals[item?.id];
                return (
                  <Grid2
                    key={index}
                    item
                    size={{ xs: item.size }}
                    sx={{
                      paddingLeft: "5px",
                      borderRight: item?.border
                        ? undefined
                        : "1px solid #00000033 ",
                      textAlign: item?.textAlign,
                    }}
                  >
                    <Typography
                      variant="h7"
                      sx={{
                        lineHeight: "24px",
                        fontSize: "15px",
                        fontWeight: "700",
                        color: "white",
                      }}
                    >
                      {item.name} {totalLogsValue}
                    </Typography>
                  </Grid2>
                );
              })}
              {finishedSubmittedDates?.map((item, index) => {
                const visitDates = {
                  submittedDate: submittedDate
                    ? dayjs(submittedDate).format(
                        SCREEN_CONSTANTS.DateTime_Format
                      )
                    : "",
                  finishedDate: finishedDate
                    ? dayjs(finishedDate).format(
                        SCREEN_CONSTANTS.DateTime_Format
                      )
                    : "",
                };
                const visitDatesValue = visitDates[item?.id];
                return (
                  <Grid2
                    key={index}
                    item
                    size={{ xs: item.size }}
                    sx={{
                      paddingLeft: "5px",
                      borderRight: item?.border
                        ? undefined
                        : "1px solid #00000033 ",
                      borderTop: "3px solid #00000033 ",
                      textAlign: item?.textAlign,
                    }}
                  >
                    <Typography
                      variant="h7"
                      sx={{
                        lineHeight: "24px",
                        fontSize: "15px",
                        fontWeight: "700",
                        color: "white",
                      }}
                    >
                      {item.name} {visitDatesValue}
                    </Typography>
                  </Grid2>
                );
              })}
            </Grid2>
          ) : null}
        </Box>
      ) : (
        <Box>
          {[...Array(3)].map((_, index) => (
            <Box
              key={index}
              sx={{
                padding: "10px",
                backgroundColor: "#F6F6F6",
                marginBottom: "10px",
              }}
            >
              <Grid2 container spacing={2}>
                <Grid2 size={{ xs: 3 }}>
                  <Skeleton variant="rectangular" width="100%" height={24} />
                </Grid2>
                <Grid2 size={{ xs: 9 }}>
                  <Skeleton variant="rectangular" width="100%" height={24} />
                </Grid2>
              </Grid2>
              <Grid2 container spacing={2} sx={{ marginTop: "10px" }}>
                <Grid2 size={{ xs: 12 }}>
                  <Skeleton variant="rectangular" width="100%" height={24} />
                </Grid2>
                <Grid2 size={{ xs: 12 }}>
                  <Skeleton variant="rectangular" width="100%" height={24} />
                </Grid2>
              </Grid2>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default VisitHoursTable;
