import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

export default function CalculatedTotal() {
  const { control, setValue } = useFormContext();

  const watchedValues = useWatch({
    control,
    name: [`additionalCharges`, `implementationFee`, `shipping`, `pricing`],
  });
  const { total } = calculateTotals({
    watchedValues,
  });

  setValue("total", total);

  return <></>;
}

function calculateTotals({ watchedValues }) {
  const [additionalCharges, shipping, implementationFee, pricing] =
    watchedValues;

  let total = 0;

  total = parseFloat(pricing) || 0;

  total += parseFloat(additionalCharges) || 0;
  total += parseFloat(implementationFee) || 0;
  total += parseFloat(shipping) || 0;

  return { total };
}
