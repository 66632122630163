import { Box, Divider, Link, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";

export const InfoBanner = ({ currentDietitianData }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        background: theme.palette[`lightBlue`].light,
        width: "100%",
        color: `${theme.palette[`lightBlue`].contrastText} !important`,
        paddingY: 0.5,
        paddingX: 2,
      }}
    >
      <Grid container spacing={0.5} alignItems="center">
        <Grid
          size={{
            xs: 12,
            md: 3,
            lg: 3.5,
            xl: 3.5,
          }}
          sx={{
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography
            variant="h6"
            component="h6"
            fontWeight="bold"
            fontSize="17px"
          >
            {currentDietitianData.fullName}
          </Typography>
        </Grid>
        <Grid
          size={{
            xs: 12,
            md: 9,
            lg: 8.5,
            xl: 8.5,
          }}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "center", md: "space-between" },
            alignItems: "center",
            order: { xs: 0, md: 1 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            <Link
              href={`mailto:${currentDietitianData.email}`}
              color="lightBlue.contrastText"
              sx={{
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {currentDietitianData.email}
            </Link>

            <Divider
              orientation="vertical"
              color="white"
              sx={{ height: "100%", mx: 1 }}
            />
            <Typography
              variant="p"
              component="p"
              fontWeight="bold"
              fontSize="14px"
              sx={{ marginX: { xs: 0, md: 1 } }}
            >
              {currentDietitianData.phoneNumber}
            </Typography>
            <Divider
              orientation="vertical"
              color="white"
              sx={{ height: "100%", mx: 1 }}
            />
            <Typography
              variant="p"
              component="p"
              fontWeight="bold"
              fontSize="14px"
              sx={{ marginX: { xs: 0, md: 1 } }}
            >
              {currentDietitianData.city}, {currentDietitianData.state}
            </Typography>
            <Divider
              orientation="vertical"
              color="white"
              sx={{ height: "100%", mx: 1 }}
            />
            <Typography
              variant="p"
              component="p"
              fontWeight="bold"
              fontSize="14px"
              sx={{ marginX: { xs: 0, md: 1 } }}
            >
              Region: {currentDietitianData.primaryRegion}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
