import React, { useMemo } from "react";
import { FormControl, InputAdornment, Input } from "@mui/material";
import { debounce } from "lodash";
import styles from "./BaseSearch.module.css";
import { Search } from "@mui/icons-material";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseSearch.md)
 */
export default function BaseSearch({
  value,
  width,
  setSearchValue,
  setDebouncedValue,
  setSearchParams = () => {},
  SEARCH_PARAM,
  placeholder,
  isArchived,
  corporateGroupId,
  showArchived = false,
  sx,
  isId,
  Id,
  isNameID,
  name,
  isRecipeId,
  isMenuId,
  setPaginationModel,
  ...rest
}) {
  const debounceHandler = useMemo(
    () =>
      debounce((searchTerm) => {
        if (setDebouncedValue) setDebouncedValue(searchTerm);
        if (isArchived || corporateGroupId) {
          setSearchParams(
            isArchived && corporateGroupId
              ? {
                  search: searchTerm,
                  archived: showArchived,
                  corporateGroupId: corporateGroupId,
                }
              : { search: searchTerm, archived: showArchived }
          );
        } else if (isId) {
          setSearchParams({ search: searchTerm, id: Id });
        } else if (isNameID) {
          setSearchParams({ search: searchTerm, id: Id, name });
        } else if (isRecipeId) {
          setSearchParams({ search: searchTerm, recipeId: Id });
        } else if (isMenuId) {
          setSearchParams({ search: searchTerm, menuId: Id });
        } else {
          setSearchParams({
            [SEARCH_PARAM]: searchTerm,
          });
        }
        if (setPaginationModel) {
          setPaginationModel({
            page: 0,
            pageSize: 100,
          });
        }
      }, 300),
    []
  );

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      const searchTerm = event.target.value;
      if (!searchTerm.startsWith(" ")) {
        if (searchTerm.includes("  ") || event.nativeEvent.data === ". ") {
          return null;
        } else {
          setSearchValue(searchTerm);
          debounceHandler(searchTerm);
        }
      }
    }
  };

  const handleSearch = (searchTerm) => {
    if (!searchTerm.startsWith(" ")) {
      if (searchTerm.includes("  ") || searchTerm.includes(". ")) {
        return null;
      } else {
        setSearchValue(searchTerm);
        debounceHandler(searchTerm);
      }
    }
  };

  return (
    <FormControl sx={{ width }}>
      <Input
        className={styles.basicInput}
        sx={{
          marginLeft: "0 !important",
          ...sx,
        }}
        value={value}
        disableUnderline={true}
        type="text"
        placeholder={placeholder || "Search..."}
        endAdornment={
          <InputAdornment
            position="end"
            style={{ cursor: "pointer" }}
            onClick={() => handleSearch(value)}
          >
            <Search color="primary" />
          </InputAdornment>
        }
        onChange={(event) => {
          const { value } = event.target;
          handleSearch(value);
        }}
        onKeyPress={handleKeyPress}
        {...rest}
      />
    </FormControl>
  );
}
