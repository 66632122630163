import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useGetDocumentListQuery } from "../../store/apis/DocumentApi";
import { useIsAuthenticated } from "@azure/msal-react";
import _, { parseInt } from "lodash";
import BaseContent from "../shared/baseContent/BaseContent";
import { Box } from "@mui/system";
import programTrainingImage from "../../assets/imgs/ProgramTrainingAndSupport.png";
import menuGuidebookImage from "../../assets/imgs/MenuGuidebook.png";
import formsAndInfoSheetsImage from "../../assets/imgs/FormsAndInformationSheets.png";
import { EditSkeleton } from "../admin/admin.overlays";
import { DMStyles } from "../../styles/Styles";
import useMsalAccount from "utils/useMsalAccount";

export default function FrontPageDocument() {
  const { account } = useMsalAccount();
  const [searchParams] = useSearchParams();
  const [documentTypeId] = useState(searchParams.get("documentTypeId") || "");
  const [groupName] = useState(searchParams.get("groupName") || "");

  const isAuthenticated = useIsAuthenticated();

  const { data: documentList, isLoading } = useGetDocumentListQuery({
    skip: !account || isAuthenticated === false,
  });

  function filterDocListByGroupName() {
    const filteredDocs = documentList?.filter(
      (docs) => docs.groupName === groupName
    );

    return filteredDocs?.map((doc) => (
      <Link
        key={doc.id}
        to={doc.url}
        target={"_blank"}
        rel="noreferrer"
        style={{
          margin: "0px 10px",
          color: "black",
        }}
      >
        <div style={{ margin: "10px" }} key={doc.id}>
          {doc.name}
        </div>
      </Link>
    ));
  }
  function filterDocListByTypeId() {
    const filteredDocs = documentList?.filter(
      (docs) => docs.documentTypeId === parseInt(documentTypeId)
    );

    const noGroup = filteredDocs?.filter(
      (document) => document.groupName === ""
    );

    const hasGroup = _.uniqBy(
      filteredDocs?.filter((document) => document.groupName !== ""),
      "groupName"
    );

    const usableDocList = noGroup?.concat(hasGroup);

    return usableDocList?.map((doc) => (
      <Link
        key={doc.id}
        to={
          doc.groupName
            ? `/home/frontPageDocument?groupName=${doc.groupName}`
            : doc.url
        }
        target={doc.groupName ? "" : "_blank"}
        rel="noreferrer"
        reloadDocument
        style={{
          margin: "0px 10px",
          color: "black",
        }}
      >
        <div style={{ margin: "10px" }} key={doc.id}>
          {doc.groupName || doc.name}
        </div>
      </Link>
    ));
  }

  const HeaderImage = () => {
    switch (parseInt(documentTypeId)) {
      case 1:
        return (
          <div
            className="generalSectionButton"
            style={{
              backgroundImage: `url(${programTrainingImage})`,
              boxShadow: DMStyles.TealBoxShadow,
            }}
          >
            Program <br /> Training
          </div>
        );
      case 2:
        return (
          <div
            className="generalSectionButton"
            style={{
              backgroundImage: `url(${menuGuidebookImage})`,
              boxShadow: DMStyles.TealBoxShadow,
            }}
          >
            Menu <br /> Guide Book
          </div>
        );
      case 3:
        return (
          <div
            className="generalSectionButton"
            style={{
              backgroundImage: `url(${formsAndInfoSheetsImage})`,
              boxShadow: DMStyles.TealBoxShadow,
            }}
          >
            Forms & <br /> Information Sheets
          </div>
        );
      default:
        return (
          <div style={{ margin: "20px", fontSize: "25px", fontWeight: "bold" }}>
            {groupName}
          </div>
        );
    }
  };

  return (
    <BaseContent disableHeight={true}>
      <Box
        sx={{
          height: { md: "78vh", xl: "82vh" },
        }}
      >
        <Box
          sx={{
            margin: "20px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <HeaderImage />
          {isLoading ? (
            <EditSkeleton height={"20vh"} text={"Loading documents..."} />
          ) : (
            <>
              {documentTypeId
                ? filterDocListByTypeId()
                : filterDocListByGroupName()}
            </>
          )}
        </Box>
      </Box>
    </BaseContent>
  );
}
