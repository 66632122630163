import {
  baseNameValidation,
  baseNameValidationWithEHRLiquidSpecialCharactersAndNumbers,
  baseNameValidationWithSpecialCharactersAndNumbers,
  emailValidation,
  multipleEmailValidation,
  numberWithHyphen,
  positiveDecimalInteger,
  positiveIntegerNumber,
  usPhoneNumberValidation,
} from "./ValidationRegex";

export const REQUIRED_ERROR = (fieldName) =>
  `${fieldName || "This field"} is required`;
export const INVALID_ERROR = (fieldName) => `Invalid ${fieldName || "Entry"}`;

export const MIN_LENGTH_VALIDATION = (fieldName, length) => ({
  value: length || 3,
  message: `${fieldName || "This field"} should be at least ${
    length || "3"
  } characters long.`,
});

export const MAX_LENGTH_VALIDATION = (fieldName, length) => ({
  value: length,
  message: `${
    fieldName || "This field"
  } should be less than or equal to ${length} characters.`,
});

// Pattern Matching Validations
export const INVALID_EMAIL_VALIDATION = (fieldName) => ({
  value: emailValidation,
  message: `Invalid ${fieldName || "email address"}`,
});
export const NUMBER_WITH_HYPHEN_VALIDATION = () => ({
  value: numberWithHyphen,
  message: `Please enter a valid Fax Number in the format 123-123-1234 `,
});

export const INVALID_MULTIPLE_EMAIL_VALIDATION = (fieldName) => ({
  value: multipleEmailValidation,
  message: `Invalid ${fieldName || "email addresses"}`,
});

export const INVALID_NAME_VALIDATION = (fieldName) => ({
  value: baseNameValidationWithSpecialCharactersAndNumbers,
  message: `${fieldName || "Name"} should be alpha-numeric.`,
});

export const INVALID_EHR_LIQUID_NAME_VALIDATION = (fieldName) => ({
  value: baseNameValidationWithEHRLiquidSpecialCharactersAndNumbers,
  message: `${fieldName || "Name"} should be alpha-numeric.`,
});

export const INVALID_PERSON_NAME_VALIDATION = (fieldName) => ({
  value: baseNameValidation,
  message: `${fieldName || "Name"} should be alphanumeric.`,
});

export const INVALID_US_PHONE_VALIDATION = (fieldName) => ({
  value: usPhoneNumberValidation,
  message: `${fieldName || "Phone Number"} is invalid.`,
});
export const POSITIVE_INTEGER_NUMBER = () => ({
  value: positiveIntegerNumber,
  message: "Please enter a positive whole number.",
});
export const POSITIVE_DECIMAL_NUMBER = () => ({
  value: positiveDecimalInteger,
  message: "Please enter a positive number.",
});
export const MIN_VALUE_VALIDATION = (fieldName, minValue) => ({
  value: minValue,
  message: ` ${
    fieldName || "This input"
  } value must be greater than ${minValue}.`,
});
export const MAX_VALUE_VALIDATION = (fieldName, maxValue, customMsg) => ({
  value: maxValue,
  message:
    customMsg ||
    `${fieldName || "This input"} value must be less than ${maxValue}.`,
});
