import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Controller } from "react-hook-form";
import useSafeFormContext from "hooks/useSafeFormContext";
import { getNestedProperty } from "utils/helperFunctions";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseRadio.md)
 */
export default function BaseRadio({
  control,
  errors,
  // Control and Errors and setValue should be avoided for future cases, enclose the GenerateForm inside a FormProvider instead

  name,
  label,
  rows,
  options,
  ...rest
}) {
  const { control: controlMethod, errors: formErrors } = useSafeFormContext({
    control,
    errors,
  });

  const error = getNestedProperty(formErrors, name);

  return (
    <FormControl disabled={rest.disabled} fullWidth>
      <FormLabel>{label}</FormLabel>
      {controlMethod ? (
        <Controller
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              row={rows === "column" ? false : true}
              value={value}
              onChange={onChange}
              error={error}
              sx={{ width: "100% !important" }}
            >
              {options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </RadioGroup>
          )}
          control={controlMethod}
          name={name}
          defaultValue={rest.defaultValue}
          rules={rest.validationProps}
        />
      ) : null}
    </FormControl>
  );
}
