import React, { useState } from "react";
import { useGetCorporateFoodTypesQuery } from "../../../../../../store/apis/CorporateGroupFoodTypeApis";
import { Button } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../../shared/baseSearch/BaseSearch";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { Fastfood, KeyboardArrowLeft } from "@mui/icons-material";

export default function CorporateAutomaticSnack() {
  const SEARCH_PARAM = "search";

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const currentId = searchParams.get("id");

  const {
    data: corporateGroups,
    isError: corporateGroupsError,
    isFetching: corporateGroupsLoading,
  } = useGetCorporateFoodTypesQuery(
    { corporateGroupId: currentId, searchText: debouncedSearchValue || "" },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const corporateGroupColumns = [
    {
      field: "dietName",
      headerName: "Diet",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "mealName",
      minWidth: 100,
      flex: 1,
      headerName: "Meal",
    },
    {
      field: "foodTypeName",
      minWidth: 100,
      flex: 1,
      headerName: "Snack",
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={corporateGroups}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `AddautomaticSnack?corporateId=${currentId}&&id=${params.row.id}`,
              title: "Automatic Snack",
            }}
            deleteProps={{
              entityName: params.row?.dietName,
              apiPath: "deleteCorporateFoodTypes",
              title: "Automatic Snack",
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        justifyContent={"space-between"}
        my={"8px"}
        flexWrap={{ xs: "wrap", md: "nowrap" }}>
        <Button
          component={Link}
          to={"/admin/communities/corporateGroups"}
          color={"primary"}
          variant="contained"
          startIcon={<KeyboardArrowLeft />}>
          {"Back to Corporate Group List"}
        </Button>
        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          sx={{ ml: 1 }}
          SEARCH_PARAM={SEARCH_PARAM}
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedSearchValue}
          setSearchParams={setSearchParams}
          isId={true}
          Id={currentId}
        />
        <Button
          component={Link}
          to={`AddautomaticSnack?corporateId=${currentId}`}
          color={"primary"}
          className="baseButton"
          variant="contained"
          endIcon={<Fastfood />}>
          Add Snack
        </Button>
      </FlexBox>

      <BaseDataGrid
        rows={corporateGroups}
        columns={corporateGroupColumns}
        error={corporateGroupsError}
        loading={corporateGroupsLoading}
        autoHeight={false}
        height={{ md: "50dvh", xl: "55dvh" }}
      />
    </>
  );
}
