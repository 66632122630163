/* eslint-disable */
import React, { useState } from "react";
import {
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  Grid2,
} from "@mui/material";

import BaseCheckBox from "../../../../../../shared/baseForm/BaseCheckBox";
import {
  AccessPermissionFields,
  securityFields,
  userMenuAccess,
} from "./forms.constants";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import { useFormContext } from "react-hook-form";

export default function AccessPermissionsForm() {
  const { SecurityCheckBoxes, MenuCheckBoxes } = AccessPermissionFields;
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const [menuChecked, setMenuChecked] = useState(
    MenuCheckBoxes.map((item) => {
      return getValues(item.props?.name);
    })
  );

  function handleMenuChange(event, index) {
    const { checked } = event.target;

    if (index === -1) {
      setMenuChecked(
        MenuCheckBoxes.map((item) => {
          setValue(item.props?.name, checked);
          return checked;
        })
      );
      return;
    }

    const newMenuChecked = [...menuChecked];
    newMenuChecked[index] = checked;
    setMenuChecked(newMenuChecked);
    setValue(MenuCheckBoxes[index].props?.name, checked);
  }

  return (
    <Grid2 container spacing={5}>
      <Grid2 item size={4}>
        <Grid2 container spacing={0.5}>
          <GeneratedForm
            list={userMenuAccess}
            control={control}
            errors={errors}
          />
        </Grid2>
      </Grid2>
      <Grid2 item size={4}>
        <GeneratedForm
          list={securityFields}
          control={control}
          errors={errors}
        />
      </Grid2>
      <Grid2 item size={4}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5">Menus</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  Object.values(menuChecked).filter((value) => value !== true)
                    .length < 1
                }
                indeterminate={
                  !menuChecked.every((value) => value === true) &&
                  !menuChecked.every((value) => value === false) &&
                  !menuChecked.every((value) => value === undefined)
                }
                onChange={() => handleMenuChange(event, -1)}
              />
            }
            label="Select All"
          />
        </Box>
        <Box>
          {MenuCheckBoxes.map((data, index) => {
            const props = data?.props;
            return (
              <BaseCheckBox
                key={props?.name}
                control={control}
                {...props}
                checked={menuChecked[index] || false}
                onChange={() => handleMenuChange(event, index)}
                name={props?.name}
                validationProps={data?.validationProps}
                errors={errors}
              />
            );
          })}
        </Box>
      </Grid2>
    </Grid2>
  );
}
