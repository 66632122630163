/* eslint-disable */
import React, { useState, useMemo, useEffect } from "react";
import "./AddUser.css";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Stepper, Step, StepLabel, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import AccessPermissionsForm from "./forms/AccessPermissions";

import {
  useAddUserMutation,
  useGetCurrentUserQuery,
  useUpdateUserMutation,
} from "../../../../../../store/apis/UserApis";
import { useGetRecipeSharingGroupsQuery } from "../../../../../../store/apis/RecipeSharingGroupAPIs";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../admin.overlays";
import { BaseButton } from "components/shared/BaseButton";
import { BackButton } from "components/shared/BackButton";
import { UserInformationV2 } from "./forms/UserInformationV2";
import { RecipeSettingsFormV2 } from "./forms/RecipeSettingsFormV2";

// import dayjs from "dayjs";

function AddUser() {
  // States Declaration
  const [activeStep, setActiveStep] = useState(0);
  const [defaultValues, setDefaultValues] = useState([]);

  // Hooks Declaration
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [searchParams] = useSearchParams();
  const currentUserIdSearch = searchParams.get("id");
  const currentUserId = currentUserIdSearch
    ? parseInt(currentUserIdSearch)
    : undefined;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  // Destructing
  const { handleSubmit, reset, trigger } = methods;
  // Get Api Section

  const {
    data: currentUser,
    isSuccess: userSuccess,
    isError: userError,
    isFetching: userFetching,
  } = useGetCurrentUserQuery(currentUserId, {
    skip: !currentUserId || !isAuthenticated,
  });

  const isEdit = !!currentUserId;

  // Mutation Section
  const [
    addUser,
    {
      isError: isAddError,
      isSuccess: addSuccess,
      isLoading: addLoading,
      error: addError,
    },
  ] = useAddUserMutation();

  const [
    updateUser,
    {
      isError: isUpdateError,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
      error: updateError,
    },
  ] = useUpdateUserMutation();
  // Veriables Declaration
  const isError = isAddError || isUpdateError;
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitting = addLoading || updateLoading;
  const error = addError || updateError;

  // useEffect Section
  useEffect(() => {
    if (userSuccess && !userError && currentUser) {
      reset(currentUser);
      setDefaultValues([
        ...defaultValues,
        {
          name: "facilityId",
          option: [
            {
              id: currentUser?.facilityId,
              label: currentUser?.facilityName || "",
            },
          ],
        },
      ]);
    }
  }, [currentUser]);

  useEffect(() => {
    !isSubmitting && isSuccess ? navigate("/admin/usermenu/usersetup") : null;
  }, [isSuccess, isSubmitting]);

  function assignNullIfEmpty(data, propertyNames) {
    propertyNames.map((res) => {
      if (data[res] === "") {
        data[res] = null;
      }
    });
    return data;
  }

  function assignZeroIfEmpty(data, propertyNames) {
    propertyNames.map((res) => {
      if (data[res] === "") {
        data[res] = 0;
      }
    });
    return data;
  }
  // function will submit Form
  const onSubmit = (data) => {
    data.emailAddress = data.userName;
    if (!isEdit) {
      data["company"] = "Not specified";
    }

    data = assignNullIfEmpty(data, [
      "menuExpirationDate",
      "tableSideExpirationDate",
      "dietitianExpirationDate",
      "trayCardExpirationDate",
    ]);

    data = assignZeroIfEmpty(data, [
      "customServings1",
      "customServings2",
      "customServings3",
    ]);
    isEdit ? updateUser(data) : addUser(data);
  };

  const handleNext = async () => {
    const isStepValid = await trigger();
    if (isStepValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <UserInformationV2 defaultValues={defaultValues} />;
      case 1:
        return <RecipeSettingsFormV2 />;
      case 2:
        return <AccessPermissionsForm />;
      default:
        return "Unknown step";
    }
  };

  const steps = ["User Information", "Recipe Settings", "Access Permissions"];

  const isEditLoading = isEdit && userFetching;

  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
            }}
          >
            <BackButton To={-1} />
          </Box>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              return (
                <Step key={label + index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
            </>
          ) : (
            <>
              <FormProvider {...methods}>
                <form>
                  {getStepContent()}

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <BaseButton
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                      text={"Back"}
                    />

                    {activeStep === steps.length - 1 ? (
                      <BaseSubmitButton
                        color="primary"
                        variant="contained"
                        text="Submit"
                        isSubmitting={isSubmitting}
                        onClick={handleSubmit(onSubmit)}
                      />
                    ) : (
                      <BaseButton onClick={handleNext} text={"Next"} />
                    )}
                  </Box>
                  {isError && (
                    <Typography
                      sx={{
                        color: "error",
                      }}
                    >
                      {error?.data?.messages}
                    </Typography>
                  )}
                </form>
              </FormProvider>
            </>
          )}
        </>
      )}
    </>
  );
}

export default AddUser;
