import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

export default function CalculatedPricing() {
  const { control, setValue } = useFormContext();
  const selectedProducts = useWatch({ control, name: "selectedProducts" });
  const watchedValues = useWatch({
    control,
    name: [
      "isPrintedCopy",
      "printedQuantity",
      `printedPrice`,
      `isWebBased`,
      `webBasedMenuQuantity`,
      `webBasedMenuPrice`,
    ],
  });

  const { pricing } = calculateTotals({
    selectedProducts,
    watchedValues,
  });

  setValue("pricing", pricing);

  return <></>;
}

function calculateTotals({ selectedProducts, watchedValues }) {
  const [
    isPrintedCopy,
    printedQuantity,
    printedPrice,
    isWebBased,
    webBasedMenuQuantity,
    webBasedMenuPrice,
  ] = watchedValues;

  let pricing = 0;
  if (isPrintedCopy) pricing += printedQuantity * printedPrice;
  if (isWebBased) pricing += webBasedMenuQuantity * webBasedMenuPrice;

  selectedProducts?.forEach((_, index) => {
    if (selectedProducts[index].isSelected) {
      pricing +=
        selectedProducts[index].quantity * selectedProducts[index].price;
    }
  });

  return { pricing };
}
