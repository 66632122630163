import React, { useEffect } from "react";
import {
  useGetPackingSlipListQuery,
  usePrintPackingSlipMutation,
} from "../../../../../../store/apis/PackingSlipAPIs";
import { useSearchParams } from "react-router-dom";
import { MenuItem, Typography } from "@mui/material";
import { DownloadLinkHelper } from "../../../../../shared/utils/helpers";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { SCREEN_CONSTANTS } from "components/shared/constants";
import dayjs from "dayjs";
import { isNull } from "lodash";
import { BaseButton } from "components/shared/BaseButton";
import FlexBox from "components/shared/styledComponents/FlexBox";
import BaseActionBox from "components/shared/BaseActionBox";
import BaseMenu from "components/shared/BaseMenu";
// import "./CommunityHTIOrders.css";

export default function CommunityHTIOrders() {
  const [searchParams] = useSearchParams();

  const facilityId = searchParams.get("id");
  const {
    data: htiOrders,
    isLoading,
    isError,
  } = useGetPackingSlipListQuery(facilityId, { skip: !facilityId });

  const htiColumns = [
    {
      field: "dateOrdered",
      headerName: "Date Ordered",
      flex: 1,
      minWidth: 100,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      align: "center",
      valueGetter: (value) => dayjs(value).toDate(),
      valueFormatter: (value) => {
        const isDateValid = dayjs(value).isValid();
        return isDateValid
          ? dayjs(value).format(SCREEN_CONSTANTS.Date_Format)
          : "--";
      },
    },
    {
      field: "lastUpdatedBy",
      headerName: "Last Updated By",
      flex: 1,
      minWidth: 100,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "dateInvoiced",
      headerName: "Date Invoiced",
      flex: 1,
      minWidth: 100,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      align: "center",
      valueGetter: (value) =>
        !isNull(value)
          ? dayjs(value).format(SCREEN_CONSTANTS.Date_Format)
          : "--",
    },

    {
      field: "billTypeName",
      headerName: "Bill To",
      flex: 1,
      minWidth: 100,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "total",
      headerName: "Order Total",
      flex: 1,
      minWidth: 100,
      cellClassName: "cellCenterAlign",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              height: "100%",
              textAlign: "center",
            }}
          >
            $ {params.row.total}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={htiOrders}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `addHtiOrders?id=${facilityId}&orderId=${params.row.id}`,
              title: "Community HTI Order",
            }}
            deleteProps={{
              apiPath: "deletePackingSlip",
              entityName: params.row?.lastUpdatedBy,
              title: "Community HTI Order",
            }}
            childrenLocation={"end"}
          >
            <OptionsButton index={params.row.id} name={params.row.name} />
          </BaseActionBox>
        );
      },
    },
  ];

  return (
    <>
      <FlexBox justifyContent={"space-between"} my={1}>
        <BaseButton
          variant={"outlined"}
          To={`addHtiOrders?id=${facilityId}`}
          text={"Add HTI invoice"}
        />
      </FlexBox>
      <BaseDataGrid
        rows={htiOrders}
        loading={isLoading}
        columns={htiColumns}
        error={isError}
      />
    </>
  );
}

function OptionsButton({ index }) {
  const [printPackingSlip, { isLoading, isSuccess, data }] =
    usePrintPackingSlipMutation();

  useEffect(() => {
    if (isSuccess && data && !isLoading) {
      DownloadLinkHelper(data);
    }
  }, [isSuccess, data, isLoading]);

  return (
    <>
      <BaseMenu index={index}>
        <MenuItem
          onClick={() => {
            printPackingSlip({ id: index, printPrice: true });
          }}
          disableRipple
        >
          Print w/ Pricing
        </MenuItem>
        <MenuItem
          onClick={() => {
            printPackingSlip({ id: index, printPrice: false });
          }}
          disableRipple
        >
          Print w/o Pricing
        </MenuItem>
      </BaseMenu>
    </>
  );
}
