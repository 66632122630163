import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { CORPORATE_GROUP_TAGS } = API_ENDPOINTS;

export const CorporateTagAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting CorporateBeverage
    getCorporateTags: builder.query({
      query: ({ corporateGroupId, searchText }) =>
        `/${CORPORATE_GROUP_TAGS}?corporateGroupId=${corporateGroupId}&searchText=${searchText}&skip=0&take=200`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: CORPORATE_GROUP_TAGS,
                id,
              })),
              CORPORATE_GROUP_TAGS,
            ]
          : [CORPORATE_GROUP_TAGS];
      },
    }),
    getCorporateTagById: builder.query({
      query: (tagId) => `/${CORPORATE_GROUP_TAGS}/${tagId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [
              {
                type: CORPORATE_GROUP_TAGS,
                id: response.id,
              },
              CORPORATE_GROUP_TAGS,
            ]
          : [CORPORATE_GROUP_TAGS],
    }),
    deleteCorporateTag: builder.mutation({
      query: (id) => ({
        url: `/${CORPORATE_GROUP_TAGS}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: CORPORATE_GROUP_TAGS, id: arg.id },
      ],
    }),
    addCorporateTag: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUP_TAGS}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: CORPORATE_GROUP_TAGS, id: result?.id },
      ],
    }),
    updateCorporateTag: builder.mutation({
      query: (data) => ({
        url: `/${CORPORATE_GROUP_TAGS}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [
        { type: CORPORATE_GROUP_TAGS, id: result?.id },
      ],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetCorporateTagsQuery,
  useGetCorporateTagByIdQuery,
  useDeleteCorporateTagMutation,
  useAddCorporateTagMutation,
  useUpdateCorporateTagMutation,
} = CorporateTagAPIs;
