import React from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Divider,
  Fade,
  CircularProgress,
} from "@mui/material";
import "@fontsource/material-icons";
import "./TempPassword.css";
import { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import BaseInput from "../../../../../shared/baseForm/BaseInput";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { useResetPasswordMutation } from "store/apis/UserApis";

export default function TempPassword({ id, fullname, username, open, close }) {
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [resetPassword, { isLoading, isSuccess }] = useResetPasswordMutation();

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const { handleSubmit } = methods;

  const props = {
    label: "New Password",
    type: "password",
    id: `password-${id}`,
    name: "password",
  };
  const validationProps = {
    required: REQUIRED_ERROR("Password"),
    minLength: {
      value: 8,
      message: "Password should be at least 8 characters long.",
    },
    maxLength: {
      value: 30,
      message: "Password should be less than 30 characters.",
    },
    pattern: {
      value:
        /^(?=(.*[A-Z])(?=.*[a-z])|(?=.*[A-Z])(?=.*\d)|(?=.*[A-Z])(?=.*[^\w\d\s])|(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*[^\w\d\s])|(?=.*\d)(?=.*[^\w\d\s]))[A-Za-z\d\W]{8,}$/,
      message:
        "Password must contain at least 2 of the following: upper case, lower case, numeric, symbol. ",
    },
  };

  const onSubmit = (data) => {
    resetPassword({
      id: id,
      userName: username,
      password: data?.password,
    });
  };

  useEffect(() => {
    if (isSuccess)
      setTimeout(() => {
        close(true);
      }, 5000);
  }, [isSuccess]);

  useEffect(() => {
    timeRemaining > 0 &&
      setTimeout(() => setTimeRemaining(timeRemaining - 1), 1000);
  }, [timeRemaining]);

  const stopPropogationForTab = (e) => {
    if (e.key === "Tab") {
      e.stopPropagation();
    }
  };

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`password-modal-title-${id}`}
      aria-describedby={`password-modal-description-${id}`}
    >
      <Fade in={open} onKeyDown={stopPropogationForTab}>
        <Box
          className={
            isSuccess ? "passwordModal passwordModalSuccess" : "passwordModal"
          }
        >
          {isSuccess ? (
            <Box className="modalContent">
              <p>
                <span
                  className="material-icons modalIconTeal"
                  color={"success"}
                >
                  check_circle
                </span>
              </p>
              <Box>
                <Typography
                  id={`password-modal-title-${id}`}
                  variant="h6"
                  component="h2"
                  className="modalTitle"
                >
                  {`"${fullname}"'s password updated successfully!`}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box className="modalContent">
              <p>
                <span className="material-icons modalIcon" color={"primary"}>
                  report
                </span>
              </p>
              <Box
                sx={{
                  width: "100%",
                  marginRight: "24px",
                }}
              >
                <FormProvider {...methods}>
                  <BaseInput
                    {...props}
                    name={props?.name}
                    validationProps={validationProps}
                    color={"warning"}
                  />
                </FormProvider>
              </Box>
            </Box>
          )}
          <Divider sx={{ my: 0.5 }} />
          {isSuccess ? (
            <Box
              className="passwordActions"
              sx={{
                flex: true,
              }}
            >
              <Button
                className="baseButton"
                variant="contained"
                onClick={close}
                color={"success"}
              >{`Close (${timeRemaining})`}</Button>
            </Box>
          ) : (
            <Box
              className="passwordActions"
              sx={{
                flex: true,
              }}
            >
              <Button
                className="baseButton"
                variant="contained"
                onClick={close}
                color={"warning"}
              >
                Cancel
              </Button>
              <Button
                sx={{ marginLeft: "10px" }}
                color="primary"
                className="baseButton"
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                startIcon={
                  isLoading ? <CircularProgress color="white" size={18} /> : ""
                }
              >
                Save
              </Button>
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}
