/* eslint-disable */
import React from "react";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import GeneratedForm from "../../../../shared/generatedForm/GeneratedForm";
import { ConsultantFields } from "../consultantDetails/consultantDetails/formConstants";
import { isUndefined } from "lodash";
import DeleteModal from "../../../../shared/deleteModal/DeleteModal";
import { useGetFacilityByIdQuery } from "../../../../../store/apis/FacilityApis";
import {
  useGetVisitDetailsQuery,
  useGetVisitPlanEditDTOQuery,
  useUpdateFacilitiesMutation,
} from "../../../../../store/apis/ConsultantApis";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
// import ValidationErrorSummary from "components/shared/validationErrorSummary/ValidationErrorSummary";
import { setVisitFacilityId } from "store/slices/consultantSlice/Facility";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";
import { LogHoursForm } from "./LogHoursForm";
import { FormProvider } from "react-hook-form";

export const VisitDetailsForm = ({
  isEdit,
  isFutureNA,
  hasChange,
  setHasChange,
  methods,
}) => {
  // stats collection and constants
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [defaultValues, setDefaultValues] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  // const [isLogsChange, setIsLogsChange] = useState(false);
  const [hiddenList, setHiddenList] = useState([]);
  const [initialValues, setInitialValues] = useState({});

  const VISIT_PLAN_ID_QUERY = "visitPlanId";
  const [searchParams] = useSearchParams();
  const currentPlanId = searchParams.get(VISIT_PLAN_ID_QUERY);
  const visitFacilityId = useSelector(
    (state) => state.facilityId.visitFacilityId
  );
  const dispatch = useDispatch();

  const {
    setValue,
    getValues,
    reset,
    watch,
    formState: { dirtyFields },
  } = methods;

  const dateOfVisit = watch("dateOfVisit");
  const communityId = watch("facilityId");

  // Api section
  const { data: currentFacility } = useGetFacilityByIdQuery(communityId, {
    skip: !communityId || !communityId.id,
  });

  // APi for Logged Data to show
  const {
    data: isVisitData,
    isLoading: isEditLoading,
    // isError: hasError,
  } = useGetVisitDetailsQuery(
    currentPlanId,

    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: preData,
    isLoading: isPreDataLoading,
    // isError: hasError,
  } = useGetVisitPlanEditDTOQuery(
    currentPlanId,

    {
      refetchOnMountOrArgChange: true,
    }
  );

  // Mutation
  // Api to add log record into list

  // This api is used to update the community/facilidyId against VisitId and PlanID
  const [updateFacility] = useUpdateFacilitiesMutation();

  // used to show the existing value in order to edit
  useEffect(() => {
    if (isEdit && !isEditLoading) {
      reset(isVisitData);
      setInitialValues(isVisitData);

      setDefaultOptions([
        ...defaultOptions,
        {
          name: "facilityId",
          option: [
            {
              id: isVisitData?.facilityId,
              label: isVisitData?.community,
            },
          ],
        },
      ]);
    }
  }, [isVisitData]);
  useEffect(() => {
    if (!isEdit && !isPreDataLoading) {
      reset(preData);
      setInitialValues(preData);

      setDefaultOptions([
        {
          name: "facilityId",
          option: [
            {
              id: preData?.facilityId,
              label: preData?.facilityName,
            },
          ],
        },
      ]);
      setDefaultValues([
        {
          name: "scheduled",
          value: preData?.scheduled,
        },
      ]);
      setValue("dateOfVisit", dayjs(preData?.planDate).format("YYYY-MM-DD"));
      setValue(
        "nextConsult",
        dayjs(preData?.currentVisit?.nextConsult).format("YYYY-MM-DD")
      );
    }
  }, [preData]);
  // its used to Hide input in GenerateForm list on the basis of milage
  useEffect(() => {
    isFutureNA
      ? setHiddenList([{ name: "nextConsult" }])
      : setHiddenList([{ name: "nextVisitNotes" }]);
  }, [isFutureNA]);

  useEffect(() => {
    if (
      !isUndefined(communityId) &&
      !isUndefined(communityId?.id) &&
      visitFacilityId?.facilityId !== communityId
    ) {
      updateFacility({
        facilityId: communityId,
        planId: currentPlanId,
        visitId: isVisitData?.id,
      });
      dispatch(
        setVisitFacilityId({
          facilityId: communityId,
          communityName: currentFacility?.name,
        })
      );
    }
  }, [communityId, currentFacility]);

  return (
    <>
      <BaseBlockerModal
        initialValues={initialValues}
        watch={watch}
        getValues={getValues}
        hasChange={hasChange}
        setHasChange={setHasChange}
        setInitialValues={setInitialValues}
        dirtyFields={dirtyFields}
      />
      <FormProvider {...methods}>
        <Grid container spacing={1.5} sx={{ width: "40%" }}>
          <GeneratedForm
            oldGrid={false}
            list={ConsultantFields}
            defaultOptions={defaultOptions}
            defaultValues={defaultValues}
            hiddenList={hiddenList}
          />
        </Grid>
      </FormProvider>

      <LogHoursForm setIsLogsChange={setHasChange} dateOfVisit={dateOfVisit} />
      {/* <ValidationErrorSummary errors={errors} /> */}
      {openDeleteModal ? (
        <DeleteModal
          To={"/Consultant/MyRosters"}
          id={currentPlanId}
          apiPath={"deleteVisitPlane"}
          entityName={"This Visit"}
          open={openDeleteModal}
          close={() => {
            setOpenDeleteModal(false);
          }}
        />
      ) : null}
    </>
  );
};
