import React, { useEffect } from "react";
import "./Home.css";
import logo from "../../assets/imgs/DiningRD-Logo-Color_Tag.png";
import {
  UnauthenticatedTemplate,
  AuthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Link, useSearchParams } from "react-router-dom";
import { useGetFacilityLogoQuery } from "../../store/apis/FacilityApis";
import { useGetCorporateGroupDocumentListQuery } from "../../store/apis/DocumentApi";
import BaseContent from "../shared/baseContent/BaseContent";
import programTrainingImage from "../../assets/imgs/ProgramTrainingAndSupport.png";
import menuGuidebookImage from "../../assets/imgs/MenuGuidebook.png";
import formsAndInfoSheetsImage from "../../assets/imgs/FormsAndInformationSheets.png";
import corporateDocsImage from "../../assets/imgs/CorporateDocs.png";
import { EditSkeleton } from "../admin/admin.overlays";
import { DMStyles } from "../../styles/Styles";
import Button from "@mui/material/Button";
import { loginRequest } from "authConfig";
import isEqual from "lodash/isEqual";
import useMsalAccount from "utils/useMsalAccount";
import { LinearProgress } from "@mui/material";

export default function Home() {
  const { facilityId, accountFromDB, isDBLoading } = useMsalAccount();
  const [searchParams] = useSearchParams();
  const { instance } = useMsal();

  useEffect(() => {
    async function checkDomainLogin() {
      const domainHint = searchParams.get("domain_hint");
      const itemKey = "msal.interaction.status";
      //prevent BrowserInteractionInProgress error on automated login
      if (sessionStorage.getItem(itemKey) || localStorage.getItem(itemKey)) {
        sessionStorage.removeItem(itemKey);
        localStorage.removeItem(itemKey);
      }

      if (
        !!domainHint &&
        !instance.getAllAccounts()?.length &&
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_SSO_DOMAIN_HINTS.split(",").includes(domainHint)
      ) {
        localStorage.setItem("domain_hint", domainHint);

        const domainHintLoginRequest = {
          ...loginRequest,
          extraQueryParameters: {
            server: loginRequest?.extraQueryParameters?.server,
            domain_hint: domainHint,
          },
        };

        await instance.loginRedirect({
          ...domainHintLoginRequest,
          prompt: "login",
        });
        localStorage.setItem("loginAsUsed", "NO");
      } else if (!!domainHint && !!instance.getAllAccounts()?.length) {
        window.location.href = "/"; //remove domain_hint on redirect post-login
      }
    }
    setTimeout(() => {
      checkDomainLogin();
    }, 1000); //Time before checking for auth redirect, we can adjust but 0 causes errors for MSAL
  }, []);

  const { data: facilityLogo, isLoading: logoLoading } =
    useGetFacilityLogoQuery(facilityId, {
      skip: !facilityId || isDBLoading,
    });

  const { data: corporateGroupDocumentList, isLoading } =
    useGetCorporateGroupDocumentListQuery(facilityId, {
      skip: !facilityId || isDBLoading, // Waiting for DB load to confirm
    });

  const handleLogin = async () => {
    const domainHint = localStorage.getItem("domain_hint");

    if (domainHint) {
      const domainHintLoginRequest = {
        ...loginRequest,
        extraQueryParameters: {
          server: loginRequest?.extraQueryParameters?.server,
          domain_hint: domainHint,
        },
      };

      await instance.loginRedirect({
        ...domainHintLoginRequest,
        prompt: "login",
      });
    } else {
      await instance.loginRedirect({ ...loginRequest, prompt: "login" });
    }
    localStorage.setItem("loginAsUsed", "NO");
  };

  const isLogoLoading = logoLoading || isLoading || isDBLoading;

  return (
    <BaseContent disableBack={true}>
      <AuthenticatedTemplate>
        <div className="authenticatedHomePage">
          <div className="logoHeader">
            {isLogoLoading ? (
              <LinearProgress sx={{ width: "300px" }} color="primary" />
            ) : null}

            <img className="centerLogo" src={facilityLogo || logo} />

            <div className="loginMessage">
              Welcome to the DiningRD Dining Manager
            </div>
          </div>

          {isLoading ? (
            <EditSkeleton text={"Loading documents..."} />
          ) : (
            <Documents
              corporateGroupDocumentList={corporateGroupDocumentList}
              showFrontPageDocuments={accountFromDB?.showFrontPageDocuments}
            />
          )}
        </div>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="unauthenticatedHomePage">
          <div className="logoHeader">
            <img className="centerLogo" src={logo} />
            <div className="loginMessage">
              <Button
                component={Link}
                color={"primary"}
                className="baseButton"
                variant="contained"
                onClick={handleLogin}
              >
                Click here to login
              </Button>
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </BaseContent>
  );
}

function Documents({ showFrontPageDocuments, corporateGroupDocumentList }) {
  useEffect(() => {}, [showFrontPageDocuments, corporateGroupDocumentList]);

  return (
    <>
      {showFrontPageDocuments ? (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Link
              to={"/home/frontPageDocument?documentTypeId=1"}
              className="generalSectionButton"
              style={{
                backgroundImage: `url(${programTrainingImage})`,
                boxShadow: DMStyles.TealBoxShadow,
              }}
            >
              Program <br /> Training
            </Link>
            <Link
              to={`/home/frontPageDocument?documentTypeId=2`}
              className="generalSectionButton"
              style={{
                backgroundImage: `url(${menuGuidebookImage})`,
                boxShadow: DMStyles.TealBoxShadow,
              }}
            >
              Menu <br /> Guide Book
            </Link>
            <Link
              to={"/home/frontPageDocument?documentTypeId=3"}
              className="generalSectionButton"
              style={{
                backgroundImage: `url(${formsAndInfoSheetsImage})`,
                boxShadow: DMStyles.TealBoxShadow,
              }}
            >
              Forms & <br /> Information Sheets
            </Link>
          </div>
        </div>
      ) : null}
      {corporateGroupDocumentList &&
      !isEqual(corporateGroupDocumentList?.length, 0) ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Link
            to={"/home/FrontPageCorpDocument"}
            className="corpDocumentSectionButton"
            style={{
              backgroundImage: `url(${corporateDocsImage})`,
            }}
          >
            Corporate Documents
          </Link>
        </div>
      ) : null}
    </>
  );
}
