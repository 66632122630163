import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetFacilityMealExtraQuery } from "../../../store/apis/FacilityMealExtraApis";
import { Typography, Box } from "@mui/material";
import { WarningText } from "../../shared/WarningText";
import { useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../shared/baseDataGrid/BaseDataGrid";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import BaseSearch from "../../shared/baseSearch/BaseSearch";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { Fastfood, Home } from "@mui/icons-material";
import YesNoDisplay from "components/shared/YesNoDisplay";

export default function FacilityMealExtrasSelection() {
  const currentId = useSelector(selectFacilityId);
  const SEARCH_PARAM = "search";
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const [debouncedValue, setDebouncedValue] = useState("");

  useEffect(() => {
    if (searchValue) {
      setDebouncedValue(searchValue);
    }
  }, []);

  const {
    data: facilityMealExtras,
    isError: facilityMealExtrasError,
    isFetching: facilityMealExtrasLoading,
  } = useGetFacilityMealExtraQuery({
    facilityId: currentId,
    searchText: debouncedValue || "",
  });

  const facilityMealExtrasColumns = [
    {
      field: "mealExtrasName",
      headerName: "Meal Add-Ons",
      flex: 3,
    },
    {
      field: "facilitySpecific",
      headerName: "House Specific",
      flex: 1,
      minWidth: 10,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <YesNoDisplay value={params?.row?.facilitySpecific} />
      ),
    },
    {
      field: "isSupplement",
      minWidth: 10,
      flex: 1,
      headerName: "Supplement?",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <YesNoDisplay value={params?.row?.isSupplement} />
      ),
    },
    {
      field: "isFood",
      minWidth: 10,
      flex: 1,
      headerName: "Food?",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <YesNoDisplay value={params?.row?.isFood} />,
    },
    {
      field: "isBeverage",
      minWidth: 10,
      flex: 1,
      headerName: "Beverage?",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <YesNoDisplay value={params?.row?.isBeverage} />,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      flex: 0,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={facilityMealExtras}
            hasEdit={true}
            editProps={{
              disabled: !params.row?.facilitySpecific,
              To: params.row?.facilitySpecific
                ? `addMealExtras?facilityId=${currentId}&id=${params.row.mealExtraId}`
                : `AddMealExtrasSelection?facilityId=${currentId}&id=${params.row.id}`,
              title: !params.row?.facilitySpecific
                ? "Cannot be edited because of being facility specific."
                : "Meal Add-Ons",
            }}
            hasDelete={true}
            deleteProps={{
              entityName: params.row?.mealExtrasName,
              apiPath: "deleteFacilityMealExtra",
              title: "Meal Add-Ons",
            }}
          />
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: "100%",
        display: "flex",
        overflow: "hidden",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Typography
        variant="h6"
        component="h6"
        sx={{
          backgroundColor: "white",
          fontWeight: "bold",
        }}
      >
        Meal Add-Ons
      </Typography>
      <WarningText
        text={`Directions: User can add community specific Meal Add-Ons that might not be found on DiningRD's existing list.
            These new additions can be added to DiningRD's Meal Add-Ons list or user can customize their own Meal Add-Ons list.`}
        width="80%"
        sx={{
          margin: "0px !important",
        }}
      />
      <FlexBox
        justifyContent={"space-between"}
        flexWrap={{ xs: "wrap", md: "nowrap" }}
      >
        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          SEARCH_PARAM={SEARCH_PARAM}
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedValue}
          setSearchParams={setSearchParams}
        />
        <BaseButton
          text="Add House Meal Add-On"
          To={`addMealExtras?facilityId=${currentId}`}
          endIcon={<Home />}
        />
        <BaseButton
          text="Build Custom List"
          To={`AddMealExtrasSelection?facilityId=${currentId}`}
          endIcon={<Fastfood />}
        />
      </FlexBox>
      <Box sx={{ height: "100%", flexGrow: 1, overflowY: "auto", pb: 1 }}>
        <BaseDataGrid
          rows={facilityMealExtras}
          columns={facilityMealExtrasColumns}
          error={facilityMealExtrasError}
          loading={facilityMealExtrasLoading}
          height={"100%"}
        />
      </Box>
    </Box>
  );
}
