import { Box, Grid2 } from "@mui/material";
import React from "react";

import GeneratedForm from "components/shared/generatedForm/GeneratedForm";

import { DETAIL_FIELDS } from "./forms.constants";

export default function DetailFields() {
  return (
    <Box
      sx={{
        paddingTop: "8px",
      }}
    >
      <Grid2
        container
        spacing={2}
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <GeneratedForm oldGrid={false} list={DETAIL_FIELDS} />
      </Grid2>
    </Box>
  );
}
