import React from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  Typography,
  Divider,
  Fade,
} from "@mui/material";
import { Link } from "react-router-dom";
import "@fontsource/material-icons";
import "./FormModal.css";
import GeneratedForm from "../generatedForm/GeneratedForm";
import BaseInput from "../baseForm/BaseInput";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { positiveDecimalInteger } from "utils/ValidationRegex";
import BaseSelect from "../baseForm/BaseSelect";

export default function FormModal({
  list,
  list2,
  control,
  setValue,
  units,
  errors,
  defaultValues,
  id,
  open,
  close,
  isEdit,
  onClickBtn,
  HeaderTitle,
  editData,
}) {
  const renderQuantityUnitGroup = (
    quantityNum,
    defaultQty = 1,
    minValue = 0.1
  ) => (
    <>
      <Grid item xs={3} md={3} lg={3} xl={3}>
        <Typography variant="p" fontWeight="bold">
          Quantity {quantityNum}
        </Typography>
        <BaseInput
          name={`quantity${quantityNum === 2 ? "2" : ""}`}
          id={`quantity${quantityNum === 2 ? "2" : ""}`}
          type="number"
          control={control}
          errors={errors}
          defaultValue={defaultQty}
          validationProps={{
            required: REQUIRED_ERROR(`Quantity ${quantityNum}`),
            min: {
              value: minValue,
              message: `Value must be ${
                minValue === 0 ? "equal or " : ""
              }greater than ${minValue}`,
            },
            ...(quantityNum === 1 && {
              pattern: {
                value: positiveDecimalInteger,
                message: "Invalid number format",
              },
            }),
          }}
        />
      </Grid>
      <Grid item xs={5} md={5} lg={5} xl={5}>
        <Typography variant="p" fontWeight="bold">
          Unit {quantityNum}
        </Typography>
        <BaseSelect
          name={`unitId${quantityNum === 2 ? "2" : ""}`}
          id={`unitId${quantityNum === 2 ? "2" : ""}`}
          validationProps={quantityNum !== 2 ? {
            required: REQUIRED_ERROR(`Unit ${quantityNum}`),
          } : undefined}
          control={control}
          errors={errors}
          options={units}
        />
      </Grid>
      <Grid item xs={4} md={4} lg={4} xl={4}></Grid>
    </>
  );

  return (
    <Modal
      open={open}
      onClose={close}
      className="formModalBody"
      aria-labelledby={`delete-modal-title-${id}`}
      aria-describedby={`delete-modal-description-${id}`}
    >
      <Fade in={open}>
        <Box className={"formModal"}>
          <Box className="model-title">
            <Typography variant="h6" component="h2" className="modalTitle">
              {HeaderTitle}
            </Typography>
            <Link onClick={() => close(true)}>
              <span className="modalTitle material-icons">{"close"}</span>
            </Link>
          </Box>

          <Box className="formModalContent">
            <p className="modalTitle">{editData?.ingredient || ""}</p>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <GeneratedForm
                list={list}
                control={control}
                setValue={setValue}
                errors={errors}
                defaultOptions={defaultValues}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {renderQuantityUnitGroup(1)}
              {renderQuantityUnitGroup(2, 0, 0)}
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <GeneratedForm
                list={list2}
                control={control}
                setValue={setValue}
                errors={errors}
                defaultOptions={defaultValues}
              />
            </Grid>
          </Box>

          <Divider sx={{ my: 0.5 }} />

          <Box
            className="formActions"
            sx={{
              flex: true,
            }}
          >
            <Button
              className="cancelButton"
              variant="contained"
              onClick={() => close(false)}
            >
              Cancel
            </Button>
            <Button
              className="submitButton"
              variant="contained"
              onClick={onClickBtn}
            >
              {isEdit ? "Save" : "Create"}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
