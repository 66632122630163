// MUI Imports
import { Box, Fade, Grid2, Modal, Typography } from "@mui/material";
// Hooks Declaration
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
//  Custom Component Import
import GeneratedForm from "../../../../shared/generatedForm/GeneratedForm";
import { RecipesFields } from "../form.constant";
import { BaseButton } from "../../../../shared/BaseButton";
import { Link, useSearchParams } from "react-router-dom";
import BaseSelect from "../../../../shared/baseForm/BaseSelect";
import { useGetSubstituteRecipesQuery } from "../../../../../store/apis/RecipeAPIs";
import { isEqual } from "lodash";
import { setIsChangeTrue } from "store/slices/menuPlannerSlice/LeaveNavigation";
import { useDispatch } from "react-redux";

export const RecipesViewModal = ({
  open,
  close,
  recipeData,
  setMenuPlannerData,
  menuPlannerData,
  rows,
  column,
  isOtherMenu,
}) => {
  // States Declaration
  const [defaultValues, setDefaultValues] = useState([]);
  // declaration of useForm
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  const dispatch = useDispatch();
  // Destructuring of Method Variable
  const { watch, reset } = methods;
  // values of Input Fields
  const [searchParams] = useSearchParams();
  const menuId = searchParams.get("menuId");
  const menuCategoryId = watch("menuCategoryId") || null;
  const substituteRecipe = watch("substituteRecipeId") || -1;
  const percentage = watch("percentage") || 0;
  const guestPrice = watch("guestPrice") || 0;
  const maxNumberOfTablesideServings =
    watch("maxNumberOfTablesideServings") || 0;
  const isSubstituteRecipe = watch("isSubstituteRecipe") || false;
  const hideFromPrintedMenu = watch("hideFromPrintedMenu") || false;
  const isHepEnabled = watch("isHepEnabled") || false;
  const { finalData, index } = recipeData;

  const { recipeName, menuCategory, menuRecipeId, recipeId } = finalData;

  const mealId = menuPlannerData?.resultSet[rows]?.mealId;
  // Query Section
  const {
    data: SubstituteData,
    isFetching: loading,
    // isError: hasError,
  } = useGetSubstituteRecipesQuery(
    {
      menuId: menuId,
      menuCategoryName: menuCategory,
      currentRecipeId: recipeId,
      mealId: mealId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !menuCategory || !recipeId || !mealId || !menuId,
    }
  );
  // useEffect Section
  useEffect(() => {
    reset(finalData);
    setDefaultValues([
      ...defaultValues,
      {
        name: "menuCategory",
        option: [
          {
            id: menuRecipeId,
            label: menuCategory,
          },
        ],
      },
    ]);
  }, []);
  useEffect(() => {
    const menuPlanner = structuredClone(menuPlannerData);
    let updatedRecipes = structuredClone(
      menuPlannerData?.resultSet[rows]?.days[column]?.recipes
    );

    let updatedRecipesData = structuredClone(
      menuPlannerData?.resultSet[rows]?.days[column]?.recipes[index]
    );

    const data = {
      ...updatedRecipesData,
      percentage,
      menuCategoryId: menuCategoryId,
      guestPrice: guestPrice >= 0 ? guestPrice : 0,
      maxNumberOfTablesideServings,
      isSubstituteRecipe,
      hideFromPrintedMenu,
      substituteRecipeId: substituteRecipe,
      isHepEnabled,
    };
    updatedRecipes.splice(index, 1);
    updatedRecipes.splice(index, 0, data);
    !isEqual(data, menuPlanner.resultSet[rows].days[column].recipes[index])
      ? dispatch(setIsChangeTrue(true))
      : dispatch(setIsChangeTrue(false));

    menuPlanner.resultSet[rows].days[column].recipes = updatedRecipes;
    setMenuPlannerData(menuPlanner);
  }, [
    menuCategoryId,
    percentage,
    guestPrice,
    maxNumberOfTablesideServings,
    isSubstituteRecipe,
    hideFromPrintedMenu,
    substituteRecipe,
    isHepEnabled,
  ]);

  //  ui Section
  return (
    <>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ invisible: true }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
              height: "60%",
              backgroundColor: "var(--whiteColor)",
              boxShadow: "var(--darkBoxShadow)",
              borderRadius: "var(--borderRadius)",
              p: 4,
            }}
          >
            <Link
              to={`/plateFul/Recipes/RecipeDetail?recipeId=${recipeId}&menuId=${menuId}`}
              variant="contained"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "underlined",
                color: "var(--tealColor)",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                {recipeName}
              </Typography>
            </Link>
            <span
              onClick={close}
              style={{
                color: "grey",
                position: "absolute",
                top: "5px",
                right: "5px",
                cursor: "pointer",
              }}
              className="material-icons"
            >
              close
            </span>
            <Grid2
              container
              spacing={2}
              sx={{
                width: "100%",
                display: "flex",
                marginTop: "20px",
              }}
            >
              <FormProvider {...methods}>
                {isOtherMenu ? (
                  <>
                    <Grid2 item size={{ xs: 12 }}>
                      <BaseSelect
                        name="substituteRecipeId"
                        id="substituteRecipeId"
                        label="Substitution"
                        loading={loading}
                        options={SubstituteData || []}
                      />
                    </Grid2>
                  </>
                ) : null}
                <GeneratedForm
                  oldGrid={false}
                  list={RecipesFields}
                  defaultOptions={defaultValues}
                />
              </FormProvider>
            </Grid2>
            <Box
              sx={{
                position: "absolute",
                bottom: "30px",
                right: "30px",
              }}
            >
              <BaseButton onClick={close} text="Close" />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};
