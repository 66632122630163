import React, { useCallback, useState } from "react";
import { BaseApi } from "../../../store/apis/BaseApi";
import { debounce } from "lodash";
import BaseAutoComplete from "../baseForm/BaseAutoComplete";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseAutoCompleteBox.md)
 */
export default function BaseAutoCompleteBox({
  control,
  errors,
  // Control and Errors should be avoided for future cases, enclose the GenerateForm inside a FormProvider instead
  name,
  label,
  endpoint,
  validationProps,
  defaultOptions,
  ...rest
}) {
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const { data, isLoading } = BaseApi.endpoints[endpoint].useQuery(
    debouncedSearch,
    {
      skip: !debouncedSearch,
    }
  );
  const handleSearch = useCallback(
    debounce((query) => {
      setDebouncedSearch(query);
    }, 400),
    []
  );
  return (
    <BaseAutoComplete
      formSelectProps={{
        props: {
          name: name,
          id: name,
          label: label,
        },
        validationProps: validationProps,
      }}
      control={control}
      errors={errors}
      options={data || []}
      loading={isLoading}
      defaultOptions={defaultOptions}
      handleSearch={handleSearch}
      {...rest}
    />
  );
}
