import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useEffect, useMemo, useState } from "react";

import {
  HTI_ORDER_FIELDS_DEFAULTS,
  htiOrderFields,
  htiOrderFields2,
  htiOrderFields3,
  htiOrderFields5,
  SHIP_TO_FACILITY_FIELD,
} from "./forms.constants";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";

import {
  useAddPackingSlipMutation,
  useGetPackingSlipToEditQuery,
  useUpdatePackingSlipMutation,
} from "../../../../../../../store/apis/PackingSlipAPIs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetProductListQuery } from "../../../../../../../store/apis/ProductAPIs";
import { useGetFacilityByIdQuery } from "../../../../../../../store/apis/FacilityApis";
import BaseSubmitButton from "../../../../../../shared/baseSubmitButton/BaseSubmitButton";
import ValidationErrorSummary from "../../../../../../shared/validationErrorSummary/ValidationErrorSummary";
// add stylesheet
import "./AddCommunityHTIOrder.css";
// import dayjs from "dayjs";
import BaseBlockerModal2 from "components/shared/blockerModal/BaseBlockerModalv2";
import GeneratedForm, {
  FORM_TYPES,
} from "components/shared/generatedForm/GeneratedForm"; // FORM_TYPES,
import CalculatedPricing from "./forms/CalculatedPricing";
import CalculatedTotal from "./forms/CalculatedTotal";
import dayjs from "dayjs";
import ShippingInformation from "./forms/ShippingInformation";
// import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";

export default function AddCommunityHTIOrder() {
  // Search Params
  const [searchParams] = useSearchParams();
  const facilityId = searchParams.get("id");
  const orderId = searchParams.get("orderId");
  const isEdit = !!orderId;

  // States
  const [defaultVendorOption, setDefaultVendorOption] = useState([]);
  const [hasChange, setHasChange] = useState(false);
  const navigate = useNavigate();

  // API Queries
  const { data: currentFacility } = useGetFacilityByIdQuery(facilityId, {
    skip: !facilityId,
  });

  const {
    data: products,
    isFetching: isProductFetching,
    isSuccess: isProductSuccess,
  } = useGetProductListQuery();

  const areProductsLoaded = useMemo(() => {
    return products && isProductSuccess && !isProductFetching;
  }, [products, isProductFetching, isProductSuccess]);

  const {
    data: htiOrder,
    isLoading: htiOrderLoading,
    isSuccess: htiOrderSuccess,
  } = useGetPackingSlipToEditQuery(orderId, {
    skip: !isEdit || !areProductsLoaded,
  });

  // API Mutations

  const [
    addHTIOrder,
    {
      isError: isAddError,
      isSuccess: addSuccess,
      isLoading: addLoading,
      error: addError,
    },
  ] = useAddPackingSlipMutation();

  const [
    updateHTIOrder,
    {
      isError: isUpdateError,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
      error: updateError,
    },
  ] = useUpdatePackingSlipMutation();

  const isError = isAddError || isUpdateError;
  const error = addError || updateError;
  const isSuccess = addSuccess || updateSuccess;
  const isSubmitting = addLoading || updateLoading;

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
    defaultValues: HTI_ORDER_FIELDS_DEFAULTS,
  });
  const {
    control,
    formState: { isDirty, errors, dirtyFields },
    reset,
    setValue,
    handleSubmit,
  } = methods;

  const { fields: productFields, replace: replaceProducts } = useFieldArray({
    control: control,
    name: "selectedProducts",
  });

  useEffect(() => {
    if (isSuccess) navigate(-1);
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (htiOrder && !htiOrderLoading && htiOrderSuccess) {
      const htiOrderData = structuredClone(htiOrder);

      const mergedArray = [
        ...htiOrder.selectedProducts,
        ...products
          .map((product) => ({
            name: product.name,
            id: product.id,
            isSelected: false,
            price: 0,
            quantity: 0,
          }))
          .filter(
            (product) =>
              !htiOrder.selectedProducts.some(
                (orderProduct) => orderProduct.id === product.id
              )
          ),
      ];
      htiOrderData.selectedProducts = mergedArray.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      reset(htiOrderData);
      setDefaultVendorOption([
        { label: htiOrder.vendorName, value: htiOrder.vendorId },
      ]);
    }
  }, [htiOrder, htiOrderLoading, htiOrderSuccess, reset]);

  useEffect(() => {
    if (areProductsLoaded) {
      if (!isEdit && currentFacility) {
        setValue("webBasedMenuPrice", currentFacility.menuCharge, {
          shouldDirty: false,
        });
      }
      if (!isEdit)
        setValue("dateOrdered", dayjs(new Date()).format("YYYY-MM-DD"), {
          shouldDirty: false,
        });
    }
  }, [areProductsLoaded, isEdit, currentFacility, setValue]);

  useEffect(() => {
    if (areProductsLoaded) {
      if (!isEdit && currentFacility) {
        const selectedProducts = products.map((product) => {
          switch (product.id) {
            case 7:
              return {
                name: product.name,
                id: product.id,
                isSelected: false,
                price: currentFacility.tableSideCharge,
                quantity: 0,
              };
            case 4:
              return {
                name: product.name,
                id: product.id,
                isSelected: false,
                price: currentFacility.mealCardCharge,
                quantity: 0,
              };
            case 10:
              return {
                name: product.name,
                id: product.id,
                isSelected: false,
                price: currentFacility.customizationCharge,
                quantity: 0,
              };
            default:
              return {
                name: product.name,
                id: product.id,
                isSelected: false,
                price: 0,
                quantity: 0,
              };
          }
        });
        replaceProducts(selectedProducts);
        reset((values) => {
          return {
            selectedProducts: selectedProducts,
            ...values,
          };
        });
      } else {
        const selectedProducts = products.map((product) => ({
          name: product.name,
          id: product.id,
          isSelected: false,
          price: 0,
          quantity: 0,
        }));
        replaceProducts(selectedProducts);
        // Resetting to set default values
        reset((values) => {
          return {
            selectedProducts: selectedProducts,
            ...values,
          };
        });
      }
    }
  }, [areProductsLoaded, isEdit, currentFacility]);

  const onSubmit = (data) => {
    setHasChange(false);

    data.facilityId = facilityId;
    if (isEdit) {
      data.id = orderId;
      updateHTIOrder(data);
    } else {
      addHTIOrder(data);
    }
  };

  const isEditLoading = (isEdit && htiOrderLoading) || !areProductsLoaded;

  return (
    <>
      <BaseBlockerModal2
        setHasChange={setHasChange}
        hasChange={hasChange}
        dirtyFields={dirtyFields}
        isDirty={isDirty}
      />
      <FormProvider {...methods}>
        <Grid container spacing={2}>
          <GeneratedForm
            oldGrid={false}
            list={[SHIP_TO_FACILITY_FIELD]}
            loading={isEditLoading}
          />
          <ShippingInformation
            loading={isEditLoading}
            currentFacility={currentFacility}
          />
          <GeneratedForm
            oldGrid={false}
            list={[...htiOrderFields, ...htiOrderFields2, ...htiOrderFields3]}
            queries={[{ name: `seasonIds`, query: { take: 2, skip: 0 } }]}
            loading={isEditLoading}
          />
          <Grid size={8}>
            <Grid
              container
              spacing={1}
              sx={{
                border: "1px solid",
                padding: 1,
                borderRadius: 1,
                margin: 1,
                borderColor: "black",
              }}
            >
              {areProductsLoaded
                ? productFields.map((item, index) => (
                    <React.Fragment key={item.id}>
                      <GeneratedForm
                        oldGrid={false}
                        list={[
                          {
                            props: {
                              name: `selectedProducts[${index}].isSelected`,
                              label: item.name,
                              value: item.Identifier,
                            },
                            validationProps: {},
                            size: 6,
                            type: FORM_TYPES.Checkbox,
                          },
                          {
                            props: {
                              name: `selectedProducts[${index}].price`,
                              label: `Price`,
                              type: `number`,
                              unit: `$`,
                              unitPosition: `start`,
                              min: 0,
                            },
                            validationProps: {},
                            size: 3,
                          },
                          {
                            props: {
                              name: `selectedProducts[${index}].quantity`,
                              label: `Quantity`,
                              type: `number`,
                              min: 0,
                              max: 1000,
                            },
                            validationProps: {},
                            size: 3,
                          },
                        ]}
                      />
                    </React.Fragment>
                  ))
                : null}
            </Grid>
          </Grid>

          <GeneratedForm
            oldGrid={false}
            defaultOptions={[{ name: `vendorId`, option: defaultVendorOption }]}
            list={htiOrderFields5}
            loading={isEditLoading}
          />

          {/* Reduces the rendering for these calculations */}
          <CalculatedPricing />
          <CalculatedTotal />
          <Grid size={12}>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <BaseSubmitButton
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
                disabled={isEditLoading}
              />
            </Box>
          </Grid>
          <Grid size={12}>
            {isError ? (
              <Typography
                sx={{
                  color: "error",
                }}
              >
                {error?.data?.messages}
              </Typography>
            ) : undefined}

            <ValidationErrorSummary errors={errors} />
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
