import {
  startingDay,
  offset6,
  endingDay,
  showDiabeticChoice,
  showHeartHealthy,
  regularQuantitiesText,
  byDayMealId,
  includeBorder,
  includeFacilityLogo,
  productionReportDateOptionId,
  firstDate,
  offset12,
  useLegalSize,
  // reportFooterText,
  // reportFooter,
  formatTypeId,
  includeDescription,
  includeNutrients,
  includeMenuMorningSnacks,
  includeMenuAfternoonSnacks,
  includeMenuEveningSnacks,
  includeMenuOtherItems,
} from "../form.constants";

export const StandardPostedMenuForms = {
  Category: true,
  DayForm: [
    startingDay,
    offset6,
    endingDay,
    offset6,
    showHeartHealthy,
    offset6,
    showDiabeticChoice,
    offset6,
  ],
  Census: [
    regularQuantitiesText,
    byDayMealId,
    offset6,
    includeBorder,
    offset6,
    includeDescription,
    offset6,
    includeNutrients,
    offset6,
    includeMenuMorningSnacks,
    offset6,
    includeMenuAfternoonSnacks,
    offset6,
    includeMenuEveningSnacks,
    offset6,
    includeMenuOtherItems,
    offset6,
    includeFacilityLogo,
    offset6,
  ],
  Diet: true,
  SnackMeal: [
    productionReportDateOptionId,
    offset6,
    firstDate,
    offset12,
    useLegalSize,
    // offset6,
    // reportFooterText,
    // offset6,
    // reportFooter,
  ],
  Report: [formatTypeId],
};
