import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { ALLERGIES, INGREDIENTS } = API_ENDPOINTS;

export const AllergyAPIs = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    // define an endpoint for getting Ingredients
    getAllergies: builder.query({
      query: ({ searchTerm, skip, take }) =>
        `/${ALLERGIES}?searchText=${searchTerm}&skip=${skip}&take=${take}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
          ? [
              ...response.items.map(({ id }) => ({
                type: ALLERGIES,
                id,
              })),
              ALLERGIES,
            ]
          : [ALLERGIES];
      },
    }),
    getAllergiesLookup: builder.query({
      query: (searchTerm) =>
        `/${ALLERGIES}?searchText=${searchTerm}&skip=0&take=1000`,
      transformResponse: (response) =>
        response?.data?.items.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: ALLERGIES,
                id,
              })),
              ALLERGIES,
            ]
          : [ALLERGIES];
      },
    }),
    getAllergiesLookupFoodList: builder.query({
      query: (ingredientId) =>
        `/${INGREDIENTS}/IngredientAllergyFoodsList?ingredientId=${ingredientId}&skip=0&take=1000`,
      transformResponse: (response) =>
        response?.data?.map((res) => {
          return {
            id: res.id,
            label: res.name,
          };
        }),
      providesTags: (response) => {
        return response
          ? [
              ...response.map(({ id }) => ({
                type: ALLERGIES,
                id,
              })),
              ALLERGIES,
            ]
          : [ALLERGIES];
      },
    }),
    getAllergiesById: builder.query({
      query: (beverageId) => `/${ALLERGIES}/${beverageId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) =>
        response
          ? [{ type: ALLERGIES, id: response.id }, ALLERGIES]
          : [ALLERGIES],
    }),
    deleteAllergies: builder.mutation({
      query: (id) => ({
        url: `/${ALLERGIES}/${id}`,
        method: "DELETE",
        body: id,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: ALLERGIES, id: arg.id },
      ],
    }),
    addAllergies: builder.mutation({
      query: (data) => ({
        url: `/${ALLERGIES}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: ALLERGIES, id: result?.id }],
    }),
    updateAllergies: builder.mutation({
      query: (data) => ({
        url: `/${ALLERGIES}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (result) => [{ type: ALLERGIES, id: result?.id }],
    }),
  }),
});

// export hooks for calling endpoints
export const {
  useGetAllergiesQuery,
  useGetAllergiesLookupQuery,
  useGetAllergiesByIdQuery,
  useGetAllergiesLookupFoodListQuery,
  useDeleteAllergiesMutation,
  useAddAllergiesMutation,
  useUpdateAllergiesMutation,
} = AllergyAPIs;
