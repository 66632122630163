// React Imports
import React, { useEffect, useState } from "react";
// Hooks Imports
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
// MUI Imports
import { Checkbox, FormControlLabel, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
// Query and Mutation Imports
import {
  useAddResidentSnackMutation,
  useGetResidentDetailQuery,
  useGetResidentSnackQuery,
  useUpdateResidentSnackMutation,
} from "../../../../../store/apis/ResidentApis";
import { useGetUnitsQuery } from "../../../../../store/apis/UnitApis";
import { useGetMealSelectQuery } from "../../../../../store/apis/PrintReportApis";
import { useGetSnackSelectBoxQuery } from "../../../../../store/apis/SnackApis";
// redux Slice Imports
import { selectFacilityId } from "../../../../../store/slices/authSlice/AuthSlice";
// Custom Component for form
import BaseSelect from "../../../../shared/baseForm/BaseSelect";
import BaseCheckBox from "../../../../shared/baseForm/BaseCheckBox";
import BaseInput from "../../../../shared/baseForm/BaseInput";
import { BaseMultiSelect } from "../../../../shared/baseForm/BaseMultiSelect";
import BaseSubmitButton from "../../../../shared/baseSubmitButton/BaseSubmitButton";
import { WarningText } from "../../../../shared/WarningText";
import { EditSkeleton } from "../../../../admin/admin.overlays";

import { DayFields } from "./forms.constants";
import { REQUIRED_ERROR } from "utils/errorMessages";
import { BackButton } from "components/shared/BackButton";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";

export default function AddResidentSnack() {
  // Declaration of Data
  const facilityId = useSelector(selectFacilityId);
  const [searchParams] = useSearchParams();
  const residentId = searchParams.get("id");
  const snackId = searchParams.get("snackId");
  const [resident, setResident] = useState({});
  const [hasChange, setHasChange] = useState(false);
  const [initialValues, setInitialValues] = useState({});

  const navigate = useNavigate();

  // useForm Declaration
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  // destructuring of method Instance
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    watch,
    formState: { errors, dirtyFields },
  } = methods;

  const snackIds = watch("foodTypeId");

  const isEdit = !!snackId;

  // Query Section
  const { data: residentDetails } = useGetResidentDetailQuery(
    {
      residentId: residentId,
      facilityId: facilityId,
    },
    { skip: !residentId || !facilityId }
  );
  const {
    data: currentSnack,
    isFetching: loadingCurrent,
    isSuccess: successCurrent,
  } = useGetResidentSnackQuery(snackId, { skip: !snackId });

  const isEditLoading = loadingCurrent && isEdit;
  const { data: units, isLoading: unitsLoading } = useGetUnitsQuery("");
  const { data: meals, isLoading: mealsLoading } = useGetMealSelectQuery({
    includeAll: false,
  });
  const { data: snacks, isLoading: snacksLoading } =
    useGetSnackSelectBoxQuery();

  // Mutation Section
  const [addSnack, { isLoading: addLoading, isSuccess: addSuccess }] =
    useAddResidentSnackMutation();
  const [updateSnack, { isLoading: updateLoading, isSuccess: updateSuccess }] =
    useUpdateResidentSnackMutation();

  const isSubmitting = addLoading || updateLoading;
  const isSuccess = addSuccess || updateSuccess;

  const { MenuCheckBoxes } = DayFields;

  const [menuChecked, setMenuChecked] = useState(
    MenuCheckBoxes.map((item) => {
      return getValues(item.props?.name);
    })
  );

  function handleMenuChange(event, index) {
    const { checked } = event.target;

    if (index === -1) {
      setMenuChecked(
        MenuCheckBoxes.map((item) => {
          setValue(item.props?.name, checked);
          return checked;
        })
      );
      return;
    }

    const newMenuChecked = [...menuChecked];
    newMenuChecked[index] = checked;
    setMenuChecked(newMenuChecked);
    setValue(MenuCheckBoxes[index].props?.name, checked);
  }

  // UseEffect Section
  useEffect(() => {
    const defaultUnit = snacks?.find((item) => item?.id === snackIds);
    setValue("unitId", defaultUnit?.defaultUnit);
  }, [snackIds]);

  useEffect(() => {
    if (successCurrent && !loadingCurrent) {
      reset(currentSnack);
      setInitialValues(currentSnack);
    }
  }, [successCurrent, loadingCurrent]);

  useEffect(() => {
    residentDetails ? setResident(residentDetails.resident) : null;
  }, [residentDetails]);

  function onSubmit(data) {
    setHasChange(false);
    data["residentId"] = residentId;
    data["foodTypeId"] !== -1 ? (data["description"] = "") : null;

    if (isEdit) {
      data["id"] = snackId;
      updateSnack(data);
      return;
    }
    addSnack(data);
  }

  !isSubmitting && isSuccess ? navigate(-1) : null;

  // Ui Section
  return (
    <>
      <BaseBlockerModal
        initialValues={initialValues}
        watch={watch}
        getValues={getValues}
        hasChange={hasChange}
        setHasChange={setHasChange}
        setInitialValues={setInitialValues}
        dirtyFields={dirtyFields}
      />
      <Box
        sx={{ padding: 1, display: "flex", flexDirection: "column", gap: 1 }}
      >
        <BackButton To={-1} />
        <Box>
          <Typography
            sx={{
              fontWeight: "700",
            }}
          >
            {resident
              ? `Resident Snack -  ${
                  resident.lastName + ", " + resident.firstName
                } `
              : null}
          </Typography>
        </Box>
        <WarningText
          width={{ lg: "50%", md: "80%" }}
          margin={"5px"}
          text={`For Printing Snack/Supplement Labels. Does NOT Print on Meal Cards `}
          color="primary.main"
          sx={{ marginLeft: "0px !important" }}
        />
        <>
          {isEditLoading ? (
            <EditSkeleton />
          ) : (
            <FormProvider {...methods}>
              <Grid container spacing={1}>
                <Grid size={6}>
                  <BaseSelect
                    name="foodTypeId"
                    id="foodTypeId"
                    label="Snack/Supplement"
                    options={snacks || []}
                    loading={snacksLoading}
                    validationProps={{
                      required: REQUIRED_ERROR("Snack/Supplement"),
                    }}
                  />
                </Grid>
                {watch("foodTypeId") === -1 && (
                  <>
                    <Grid size={6}>
                      <BaseInput
                        name="description"
                        id="description"
                        label="Description"
                        control={control}
                        validationProps={{
                          required:
                            "Description field is required when selecting Other",
                        }}
                        errors={errors}
                      />
                    </Grid>
                  </>
                )}
                <Grid size={6}>
                  <BaseInput
                    name="quantity"
                    id="quantity"
                    label="Quantity"
                    type="number"
                    validationProps={{
                      required: REQUIRED_ERROR("Quantity"),
                      min: 0,
                      message: "Quantity must be greater than 0",
                    }}
                  />
                </Grid>
                <Grid size={6}>
                  <BaseSelect
                    name="unitId"
                    id="unitId"
                    label="Unit"
                    options={units || []}
                    loading={unitsLoading}
                    validationProps={{
                      required: REQUIRED_ERROR("Unit"),
                    }}
                  />
                </Grid>
                <Grid size={6}>
                  {isEdit ? (
                    <BaseSelect
                      name="mealId"
                      id="mealId"
                      label="Meal"
                      options={meals || []}
                      loading={mealsLoading}
                      validationProps={{
                        required: REQUIRED_ERROR("Meal"),
                      }}
                    />
                  ) : (
                    <BaseMultiSelect
                      name="meals"
                      id="meals"
                      label="Meals"
                      setValue={setValue}
                      options={meals || []}
                      loading={mealsLoading}
                      validationProps={{
                        required: REQUIRED_ERROR("Meals"),
                      }}
                    />
                  )}
                </Grid>
                <Grid size={6}>
                  <FormControlLabel
                    sx={{
                      height: `1rem`,
                    }}
                    control={
                      <Checkbox
                        checked={
                          Object.values(menuChecked).filter(
                            (value) => value !== true
                          ).length < 1
                        }
                        indeterminate={
                          !menuChecked.every((value) => value === true) &&
                          !menuChecked.every((value) => value === false) &&
                          !menuChecked.every((value) => value === undefined)
                        }
                        onChange={() => handleMenuChange(event, -1)}
                      />
                    }
                    label="Select All"
                  />
                </Grid>
                <Grid offset={6}></Grid>

                {MenuCheckBoxes.map((data, index) => {
                  const props = data?.props;
                  return (
                    <>
                      <Grid size={6} paddingLeft={1}>
                        <BaseCheckBox
                          key={props?.name}
                          {...props}
                          onChange={() => handleMenuChange(event, index)}
                          name={props?.name}
                          validationProps={data?.validationProps}
                        ></BaseCheckBox>
                      </Grid>
                      <Grid offset={6}></Grid>
                    </>
                  );
                })}
                <Grid size={6}>
                  <BaseSubmitButton
                    onClick={handleSubmit(onSubmit)}
                    isSubmitting={isSubmitting}
                  />
                </Grid>
              </Grid>
            </FormProvider>
          )}
        </>
      </Box>
    </>
  );
}
