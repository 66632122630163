import React from "react";
import Grid from "@mui/material/Grid2";
import { useFormContext, useWatch } from "react-hook-form";
import BaseInput from "components/shared/baseForm/BaseInput";
import { MAX_LENGTH_VALIDATION } from "utils/errorMessages";

export default function Comment({ prefix, hasComment }) {
  const { control } = useFormContext();
  const seeRecommendations = useWatch({
    control: control,
    name: `${prefix}.seeRecommendation`,
  });

  return (
    <>
      {hasComment || seeRecommendations ? (
        <Grid size={{ md: 4 }}>
          <BaseInput
            name={`${prefix}.comment`}
            id={`${prefix}.comment`}
            label="Comment here"
            type={"text"}
            size="small"
            validationProps={{
              maxLength: MAX_LENGTH_VALIDATION("Comment", 1024),
            }}
          />
        </Grid>
      ) : null}
    </>
  );
}
