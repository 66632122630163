import { useNavigate, useSearchParams } from "react-router-dom";
import InfoFields from "./forms/InfoFields";
import { useForm, FormProvider } from "react-hook-form";
import React, { useEffect, useRef, useState } from "react";
import PermissionFields from "./forms/PermissionFields";
import { Box, Divider, Typography } from "@mui/material";
import DetailFields from "./forms/DetailFields";
import {
  useAddFacilityMutation,
  useGetFacilityByIdQuery,
  useUpdateFacilityMutation,
} from "../../../../../../store/apis/FacilityApis";
import BaseSubmitButton from "../../../../../shared/baseSubmitButton/BaseSubmitButton";
import { EditSkeleton } from "../../../../admin.overlays";
// import ValidationErrorSummary from "../../../../../shared/validationErrorSummary/ValidationErrorSummary";
import { BackButton } from "components/shared/BackButton";
import { DETAIL_FIELDS } from "./forms/forms.constants";
import BaseBlockerModal from "components/shared/blockerModal/BaseBlockerModal";

export default function AddCommunity() {
  // local States Variables
  const [searchParams] = useSearchParams();
  const currentId = searchParams.get("id");
  const [ignoreDuplicate, setIgnoreDuplicate] = useState(false);
  const [defaultVendor, setDefaultVendor] = useState([]);
  const [defaultCorporateGroup, setDefaultCorporateGroup] = useState([]);
  const [defaultBaseMenu, setDefaultBaseMenu] = useState([]);
  const [initialValues, setInitialValues] = useState({});

  // unsaved changes functionality here
  const [isChange, setIsChange] = useState(false);

  const isEdit = !!currentId;
  const navigate = useNavigate();

  // useform Hook
  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });
  // destructuring
  const {
    // setError,
    clearErrors,
    watch,
    control,
    reset,
    getValues,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = methods;
  // Watch from useform
  const consultingRDGroupId = watch("consultingDiningRdgroupId");
  const menuRDGroupId = watch("menuDiningRdgroupId");

  // Api Section
  const {
    data: currentFacility,
    isLoading: isCurrentFacilityLoading,
    isSuccess: isCurrentFacilitySuccess,
  } = useGetFacilityByIdQuery(currentId, { skip: !currentId });

  const isEditLoading = isCurrentFacilityLoading && isEdit;

  // mutation Section
  const [
    addCommunity,
    {
      isLoading: isAddSubmitting,
      isError: isAddError,
      isSuccess: isAddSuccess,
      error: addError,
    },
  ] = useAddFacilityMutation();
  const [
    updateCommunity,
    {
      isLoading: isUpdateSubmitting,
      isError: isUpdateError,
      isSuccess: isUpdateSuccess,
      error: updateError,
    },
  ] = useUpdateFacilityMutation();

  const isSuccess = isAddSuccess || isUpdateSuccess;
  const isSubmitting = isAddSubmitting || isUpdateSubmitting;
  const isError = isAddError || isUpdateError;
  const error = addError || updateError;

  function scrollErrorIntoView() {
    document.getElementsByClassName("actionsRow")[0]?.scrollIntoView();
  }
  // useEffect Section

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollErrorIntoView();
    }
  }, [errors]);
  const onError = () => {
    scrollErrorIntoView();
  };

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      if (consultingRDGroupId || menuRDGroupId) {
        DETAIL_FIELDS.length > 21
          ? (DETAIL_FIELDS[21].validationProps.required = false)
          : null;
        DETAIL_FIELDS.length > 29
          ? (DETAIL_FIELDS[29].validationProps.required = false)
          : null;
        clearErrors("consultingDiningRdgroupId");
        clearErrors("menuDiningRdgroupId");
      }

      // else {
      //     setError("menuDiningRdgroupId", {
      //       type: "required",
      //       message:
      //         "A selection must be made for one or both of the Consulting or Menu Group fields before updating is allowed.",
      //     });
      //     setError("consultingDiningRdgroupId", {
      //       type: "required",
      //       message:
      //         "A selection must be made for one or both of the Consulting or Menu Group fields before updating is allowed.",
      //     });
      //   }
    }
  }, [consultingRDGroupId, menuRDGroupId, errors]);

  useEffect(() => {
    isSuccess ? navigate(-1) : null;
    if (isError && error) {
      if (
        error?.data?.messages?.some((x) =>
          x.includes("There exists a community with a similar address")
        )
      ) {
        setIgnoreDuplicate(true);
      }
    }
  }, [isSuccess, isError, error]);

  useEffect(() => {
    if (isCurrentFacilitySuccess && !isCurrentFacilityLoading) {
      reset(currentFacility);
      setInitialValues(currentFacility);
    }
  }, [isCurrentFacilitySuccess]);

  useEffect(() => {
    if (isCurrentFacilitySuccess && !isCurrentFacilityLoading) {
      setDefaultBaseMenu([
        { id: currentFacility?.baseMenuId, label: currentFacility?.baseMenu },
      ]);
      setDefaultCorporateGroup([
        {
          id: currentFacility?.corporateGroupId,
          label: currentFacility?.corporateGroup,
        },
      ]);
      setDefaultVendor([
        {
          id: currentFacility?.vendorId,
          label: currentFacility?.vendor,
        },
      ]);
    }
  }, [isCurrentFacilitySuccess, currentFacility]);

  const onSubmit = (data) => {
    setIsChange(false);
    console.log(
      data.subscriptionRateIncrease,
      data.rateIncrease,
      data.subscriptionRateIncrease === "",
      data.rateIncrease === ""
    );

    data.menuBillTo = "";
    data.mealCardBillTo = "";
    data.customizationBillTo = "";
    data.billTo = "";

    data.effectiveDate = data.effectiveDate !== "" ? data.effectiveDate : null;
    data.termDate = data.termDate !== "" ? data.termDate : null;

    data.menuEffectiveDate =
      data.menuEffectiveDate !== "" ? data.menuEffectiveDate : null;
    data.menuTermDate = data.menuTermDate !== "" ? data.menuTermDate : null;

    data.mealcardEffectiveDate =
      data.mealcardEffectiveDate !== "" ? data.mealcardEffectiveDate : null;
    data.mealcardTermDate =
      data.mealcardTermDate !== "" ? data.mealcardTermDate : null;

    data.tablesideEffectiveDate =
      data.tablesideEffectiveDate !== "" ? data.tablesideEffectiveDate : null;
    data.tablesideTermDate =
      data.tablesideTermDate !== "" ? data.tablesideTermDate : null;

    data.cdmeffectiveDate =
      data.cdmeffectiveDate !== "" ? data.cdmeffectiveDate : null;
    data.cdmtermDate = data.cdmtermDate !== "" ? data.cdmtermDate : null;

    data.dietManualOrderDate =
      data.dietManualOrderDate !== "" ? data.dietManualOrderDate : null;
    data.guidelinesAndProceduresOrderDate =
      data.guidelinesAndProceduresOrderDate !== ""
        ? data.guidelinesAndProceduresOrderDate
        : null;

    data.ehrEffectiveDate =
      data.ehrEffectiveDate !== "" ? data.ehrEffectiveDate : null;
    data.ehrTermDate = data.ehrTermDate !== "" ? data.ehrTermDate : null;

    data.isConsultingCustomer =
      data.termDate !== null ? false : data.isConsultingCustomer;
    data.isMenuCustomer =
      data.menuTermDate !== null ? false : data.isMenuCustomer;
    data.isMealCardCustomer =
      data.mealcardTermDate !== null ? false : data.isMealCardCustomer;
    data.isTablesideCustomer =
      data.tablesideTermDate !== null ? false : data.isTablesideCustomer;
    data.isCdmconsultingCustomer =
      data.cdmtermDate !== null ? false : data.isCdmconsultingCustomer;
    data.ehrproviderId = data.ehrTermDate !== null ? null : data.ehrproviderId;
    data.subscriptionRateIncrease =
      data.subscriptionRateIncrease === "" ? 0 : data.subscriptionRateIncrease;
    data.rateIncrease = data.rateIncrease === "" ? 0 : data.rateIncrease;

    if (isEdit) {
      updateCommunity(data);
      return;
    }
    addCommunity({ data, ignoreDuplicate: ignoreDuplicate.toString() });
  };

  // eslint-disable-next-line no-unused-vars
  const { menuDiningRdgroupId, ...newObject } = errors;

  // UI Section
  return (
    <>
      {isEditLoading ? (
        <EditSkeleton />
      ) : (
        <>
          <BaseBlockerModal
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            watch={watch}
            hasChange={isChange}
            setHasChange={setIsChange}
            getValues={getValues}
            dirtyFields={dirtyFields}
          />
          <Box className="adminContainer">
            <div className="actionsRow">
              <BackButton />
              <BaseSubmitButton
                onClick={handleSubmit(onSubmit)}
                isSubmitting={isSubmitting}
              />
            </div>
            <FormProvider {...methods}>
              <form className="adminTableBody">
                {/* <ValidationErrorSummary errors={newObject} /> */}

                <InfoFields
                  control={control}
                  errors={errors}
                  isEdit={isEdit}
                  defaultBaseMenu={defaultBaseMenu}
                  defaultCorporateGroup={defaultCorporateGroup}
                  defaultVendor={defaultVendor}
                />
                <Divider sx={{ marginTop: "8px", marginBottom: "8px" }} />
                <PermissionFields control={control} errors={errors} />
                <Divider sx={{ marginTop: "8px", marginBottom: "8px" }} />
                <DetailFields control={control} errors={errors} />
              </form>
            </FormProvider>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
              }}>
              <BaseSubmitButton
                onClick={handleSubmit(onSubmit, onError)}
                isSubmitting={isSubmitting}
              />
            </Box>

            {isError ? (
              <>
                <Typography
                  sx={{
                    color: "error",
                  }}>
                  {error?.data?.messages}
                </Typography>
              </>
            ) : undefined}
          </Box>
        </>
      )}
    </>
  );
}
