import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import React, { useEffect } from "react";
import { SHIPPING_FIELDS } from "../forms.constants";
import { useFormContext, useWatch } from "react-hook-form";

export default function ShippingInformation({ currentFacility, loading }) {
  const { control, setValue } = useFormContext();
  const shipToFacility = useWatch({ control, name: "shipToFacilityAddress" });

  useEffect(() => {
    if (shipToFacility && currentFacility) {
      const { phoneNumber, address, city, state, zip } = currentFacility;

      setValue("shipToAddress", address);
      setValue("shipToCity", city);
      setValue("shipToState", state);
      setValue("shipToZip", zip);
      setValue("phoneNumber", phoneNumber);
    }
  }, [shipToFacility]);

  return (
    <GeneratedForm
      oldGrid={false}
      list={SHIPPING_FIELDS}
      disabled={!!shipToFacility}
      loading={loading}
    />
  );
}
