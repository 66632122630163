import { Box, Button, Collapse, Grid, Typography } from "@mui/material";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import GeneratedForm from "components/shared/generatedForm/GeneratedForm";
import BaseSubmitButton from "components/shared/baseSubmitButton/BaseSubmitButton";
import { DMStyles } from "styles/Styles";
import { Add, ArrowDownward, ArrowUpward, Delete } from "@mui/icons-material";
import FlexBox from "components/shared/styledComponents/FlexBox";

export default function GenericFieldForm({
  name,
  fieldPropName,
  appendObjectExample,
  nameOfObject,
  isSortOrder = false,
  fieldList,
  ChildComponent,
  childProps,
  triggerValues,
}) {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: name,
  });

  const [checked, setChecked] = React.useState(false);

  const moveUp = (index) => {
    if (index > 0) {
      updateSortOrder(index, index - 1);
      move(index, index - 1);
      setValue(name, [...fields]); // Trigger re-render
    }
  };

  const moveDown = (index) => {
    if (index < fields.length - 1) {
      updateSortOrder(index, index + 1);
      move(index, index + 1);
      setValue(name, [...fields]); // Trigger re-render
    }
  };

  const updateSortOrder = (index1, index2) => {
    const setTemp = fields[index1].sortOrder;
    const setTemp2 = fields[index2].sortOrder;
    setValue(`${fieldPropName}[${index1}].sortOrder`, setTemp2);
    setValue(`${fieldPropName}[${index2}].sortOrder`, setTemp);
    const temp = fields[index1].sortOrder;
    fields[index1].sortOrder = fields[index2].sortOrder;
    fields[index2].sortOrder = temp;
  };

  return (
    <Box
      sx={{
        p: 2,
      }}>
      <Collapse in={checked} orientation="vertical" collapsedSize={50}>
        <Grid
          container
          spacing={2}
          sx={{
            border: "1px solid black",
            borderRadius: DMStyles.BorderRadius,
            py: 0,
            paddingBottom: 1,
            pr: 0,
            my: 2,
          }}>
          <Grid
            item
            xs={12}
            sx={{
              padding: "8px !important",
              margin: "0px !important",
              borderTopLeftRadius: DMStyles.BorderRadius,
              borderTopRightRadius: DMStyles.BorderRadius,
              cursor: "pointer",
            }}
            bgcolor={"primary.main"}
            height={40}
            onClick={() => setChecked(!checked)}>
            <Typography
              sx={{
                display: "flex",
                gap: 1,
              }}
              color="primary.contrastText">
              {checked ? <ArrowUpward /> : <ArrowDownward />}
              {`${nameOfObject}`}
            </Typography>
          </Grid>
          {fields.map((item, index) => {
            let inputFields = [];
            inputFields = fieldList?.map((field) => {
              console.log(field, item);
              const newClone = {
                ...structuredClone(field),
                props: {
                  ...field.props,
                  name: `${fieldPropName}[${index}].${field.props.name}`,
                },
                options:
                  field.props.name === "triggers"
                    ? triggerValues || []
                    : field.options || [],
              };

              return newClone;
            });
            return (
              <React.Fragment key={item.id}>
                <GeneratedForm
                  list={inputFields}
                  errors={errors}
                  control={control}
                />

                <Grid item xs={12}>
                  <FlexBox justifyContent={"start"}>
                    <BaseSubmitButton
                      icon="true"
                      color="error"
                      text={<Delete />}
                      onClick={() => remove(index)}
                    />
                    {isSortOrder ? (
                      <>
                        <Button
                          variant="contained"
                          icon="true"
                          onClick={() => moveUp(index)}>
                          <ArrowUpward />
                        </Button>
                        <Button
                          variant="contained"
                          icon="true"
                          onClick={() => moveDown(index)}>
                          <ArrowDownward />
                        </Button>
                      </>
                    ) : null}
                  </FlexBox>
                </Grid>
                {ChildComponent ? (
                  <Grid item xs={12}>
                    <ChildComponent {...childProps} nestIndex={index} />
                  </Grid>
                ) : null}
              </React.Fragment>
            );
          })}
          <Grid item xs={12}>
            <FlexBox justifyContent={"start"}>
              <BaseSubmitButton
                text={`Add ${nameOfObject}`}
                startIcon={<Add />}
                onClick={() => append(appendObjectExample)}
              />
              <BaseSubmitButton
                text={`Add ${nameOfObject}`}
                startIcon={<Add />}
                onClick={() => setChecked(!checked)}
              />
            </FlexBox>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
}
