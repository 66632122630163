import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import FlexBox from "./styledComponents/FlexBox";
import { DMStyles } from "styles/Styles";
import CloseIcon from "@mui/icons-material/Close";
import { BaseButton } from "./BaseButton";
export const ArchivedModal = ({ open, close, onClick }) => {
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby={`archived-modal-title`}
      aria-describedby={`archived-modal-description`}
    >
      <Fade in={open}>
        <Box className={"archivedModal"}>
          <FlexBox
            alignItems={"center"}
            gap={0.5}
            justifyContent={"space-between"}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <Box
                width={"100%"}
                padding={"10px"}
                bgcolor={"#A80000"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <Typography
                  color={DMStyles.White}
                  fontWeight={"bold"}
                  fontSize={"20px"}
                >
                  Confirm Archive
                </Typography>
                <Box sx={{ cursor: "pointer" }} onClick={close}>
                  <CloseIcon color={"white"} />
                </Box>
              </Box>
              <Box padding={"15px"}>
                <Typography fontSize={"18px"}>
                  Confirming this action will archive this corporate and it will
                  no longer be available.
                </Typography>
                <Typography fontSize={"18px"} mt={2}>
                  Do you still wish to archive?
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"center"} gap={2} my={2}>
                <BaseButton
                  text={"Cancel"}
                  onClick={close}
                  fontColor={"#000"}
                  sx={{
                    backgroundColor: DMStyles.White,
                    border: "1px solid black",
                    // color: "black",
                  }}
                />
                <BaseButton
                  text={"Archived"}
                  onClick={onClick}
                  colors="error"
                />
              </Box>
            </Box>
          </FlexBox>
        </Box>
      </Fade>
    </Modal>
  );
};
