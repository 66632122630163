import React, { useState, useEffect } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import {
  matchPath,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import "../../../../shared/AdminTabs.css";
import { CommunityMenuElements } from "./communitymenu.constants";
import { useGetFacilityByIdQuery } from "../../../../../../store/apis/FacilityApis";
import "./CommunityMenu.css";
import { KeyboardArrowLeft } from "@mui/icons-material";
import OutletContainer from "components/shared/styledComponents/OutletContainer";

export default function CommunityMenu() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [value, setValue] = useState(1);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [menuItems, setMenuItems] = useState(CommunityMenuElements);

  const { data: community, isSuccess: communitySuccess } =
    useGetFacilityByIdQuery(id, { skip: !id });

  useEffect(() => {
    if (pathname === "/admin/communities/communitysetup/details") {
      navigate(menuItems[1].Link);
    }

    let currentLink;
    if (pathname) {
      currentLink = menuItems
        .filter((x) => x.Link != -1)
        .find((x) => matchPath({ path: x.Link, end: false }, pathname));
      if (!currentLink) {
        currentLink = menuItems[0];
      }
    }
    const currentIndex =
      CommunityMenuElements.indexOf(
        CommunityMenuElements.filter((x) => x.Link != -1).find(
          (res) =>
            matchPath({ path: res.Link, end: false }, pathname) &&
            res.Name !== "Back"
        )
      ) || 1;
    setValue(currentIndex !== -1 ? currentIndex : 1);
    setMenuItems(
      CommunityMenuElements.map((res) => {
        if (
          res.Link != -1 &&
          matchPath({ path: res.Link, end: false }, pathname)
        ) {
          res.IsActive = true;
        } else {
          res.IsActive = false;
        }
        return res;
      })
    );
  }, [pathname]);

  return (
    <Box
      id="community-outlet-container"
      sx={{
        margin: -1,
        flexGrow: 1,
        height: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Tabs
            TabIndicatorProps={{ sx: { display: "none" } }}
            sx={{
              "& .MuiTabs-flexContainer": {
                flexWrap: "wrap",
              },
            }}
            value={value}
            aria-label="Community Tabs"
          >
            {menuItems.map((data, index) => {
              return (
                <LinkTab
                  key={index}
                  value={index}
                  id={id}
                  label={`${
                    data.Name === "Details" && communitySuccess
                      ? community?.name
                      : data.Name
                  }`}
                  isTitle={data.Name === "Details"}
                  href={`${data.Link}?id=${id}`}
                  link={data.Link}
                  name={community?.name}
                  // navigate={navigate}
                />
              );
            })}
          </Tabs>
        </Box>
      </Box>

      <OutletContainer id="community-outlet">
        <Outlet />
      </OutletContainer>
    </Box>
  );
}

function LinkTab({ label, id, isTitle, link, name, ...rest }) {
  return (
    <Tab
      component={NavLink}
      className={
        label === "Back"
          ? "backTab"
          : isTitle
          ? "communityDetailTab"
          : "adminTabs"
      }
      to={label === "Back" ? link : `${link}?id=${id}&name=${name}`}
      // onClick={(event) => {
      //   if (label === "Back" && !link) {
      //     event.preventDefault();
      //     navigate(-1);
      //     return;
      //   }
      // }}
      icon={label === "Back" ? <KeyboardArrowLeft /> : null}
      label={label === "Back" ? "Back" : label}
      {...rest}
    />
  );
}
