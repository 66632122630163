import React from "react";
import { BaseApi } from "../../../store/apis/BaseApi";
import { BaseMultiSelect } from "../baseForm/BaseMultiSelect";

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseMultiSelectBox.md)
 */
export default function BaseMultiSelectBox({
  control,
  errors,
  setValue,
  // Control and Errors and setValue should be avoided for future cases, enclose the GenerateForm inside a FormProvider instead

  name,
  label,
  id,
  endpoint,
  validationProps,
  defaultOptions,
  size,
  query,
  skip,
  ...rest
}) {
  if (!endpoint) return;

  const { data, isLoading } = BaseApi.endpoints[endpoint].useQuery(query, {
    skip: skip,
  });

  return (
    <BaseMultiSelect
      control={control}
      setValue={setValue}
      errors={errors}
      name={name}
      size={size}
      id={id || name}
      label={label}
      validationProps={validationProps}
      options={data || []}
      loading={isLoading}
      defaultOptions={defaultOptions}
      {...rest}
    />
  );
}
