import { MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useFacilityEHRsQuery } from "../../../../../../store/apis/FacilityApis";
import BaseDataGrid from "../../../../../shared/baseDataGrid/BaseDataGrid";
import { BaseButton } from "components/shared/BaseButton";
import BaseActionBox from "components/shared/BaseActionBox";
import BaseMenu from "components/shared/BaseMenu";
import FlexBox from "components/shared/styledComponents/FlexBox";
import { PlaylistAdd } from "@mui/icons-material";

export default function CommunityEHRs() {
  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const [ehrType, setEHRType] = useState(0);

  const {
    data: ehrs,
    isLoading,
    isSuccess,
    isError,
  } = useFacilityEHRsQuery(id, { skip: !id });

  useEffect(() => {
    if (isSuccess && ehrs) {
      setEHRType(ehrs?.ehrType);
    }
  }, [isSuccess]);

  const eHRColumns = [
    {
      field: "ehridentifier",
      headerName: "Identifier",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "alternateEhridentifier",
      headerName: "Alt Identifier",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "Actions",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={ehrs?.ehRs}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `addcommunityehr?id=${id}&ehrId=${params.row.id}`,
              title: "Community EHR Identifier",
            }}
            deleteProps={{
              apiPath: "deleteFacilityEHR",
              entityName: params.row?.ehridentifier,
              title: "Community EHR Identifier",
            }}
            childrenLocation={"end"}
          >
            {ehrType > 0 ? (
              <OptionsButton
                index={params.row.id}
                facilityId={params.row.facilityId}
                facilityName={params.row.facilityName}
                ehridentifier={params.row.ehridentifier}
                alternateEhridentifier={params.row.alternateEhridentifier}
                ehrType={ehrType}
                id={id}
              />
            ) : undefined}
          </BaseActionBox>
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={"space-between"}
        marginBottom={1}
      >
        <BaseButton
          text={"Add EHR Identifier"}
          endIcon={<PlaylistAdd />}
          To={`addcommunityehr?id=${id}`}
        />
        <FlexBox
          width={{ xs: "100%", sm: "auto" }}
          justifyContent={{ xs: "space-between", sm: "end" }}
          marginTop={{ xs: "10px", sm: "0px" }}
        >
          <BaseButton
            text={"EHR Diet Mapping"}
            endIcon={<PlaylistAdd />}
            To={`ehrdietmapping?id=${id}`}
          />
          <BaseButton
            text={"EHR Liquid Mapping"}
            endIcon={<PlaylistAdd />}
            To={`ehrliquidmapping?id=${id}`}
          />
        </FlexBox>
      </FlexBox>

      <BaseDataGrid
        rows={ehrs?.ehRs}
        columns={eHRColumns}
        error={isError}
        loading={isLoading}
      />
    </>
  );
}

function OptionsButton({
  index,
  facilityId,
  facilityName,
  ehridentifier,
  alternateEhridentifier,
  ehrType,
  id,
}) {
  const EHR_TYPES = {
    ehr1: {
      name: "PCC Import",
      link: `./pccImport?id=${id}&facilityId=${facilityId}&ehridentifier=${ehridentifier}&alternateEhridentifier=${alternateEhridentifier}&facilityName=${facilityName}`,
    },
    ehr2: {
      name: "ALIS Import",
      link: `./alisImport?id=${id}&facilityId=${facilityId}&ehridentifier=${ehridentifier}&facilityName=${facilityName}`,
    },
    ehr3: {
      name: "Matrix Import",
      link: `./matrixImport?id=${id}&facilityId=${facilityId}&ehridentifier=${ehridentifier}&facilityName=${facilityName}`,
    },
  };

  return (
    <>
      <BaseMenu index={index}>
        {ehrType ? (
          <MenuItem
            component={Link}
            to={EHR_TYPES[`ehr${ehrType}`]?.link}
            disableRipple
          >
            {EHR_TYPES[`ehr${ehrType}`]?.name}
          </MenuItem>
        ) : null}
      </BaseMenu>
    </>
  );
}
