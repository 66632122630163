import React, { useEffect, useState } from "react";
import { useGetCorporateGroupPagedQuery } from "../../../../../store/apis/CorporateGroupApis";
import { Button, MenuItem } from "@mui/material";

import { Link, useSearchParams } from "react-router-dom";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import { useSelector } from "react-redux";
import { CORPORATE_GROUP_MENU_ITEMS } from "./CorporateGroup.constants";
import BaseActionBox from "components/shared/BaseActionBox";
import FlexBox from "components/shared/styledComponents/FlexBox";
import BaseMenu from "components/shared/BaseMenu";
import { PersonAdd } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

export default function CorporateGroups() {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 100,
  });
  const SEARCH_PARAM = "search";

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(
    searchParams.get(SEARCH_PARAM) || ""
  );

  const theme = useTheme();

  const {
    data: corporateGroupsPaged,
    isError: corporateGroupsError,
    isFetching: corporateGroupsLoading,
  } = useGetCorporateGroupPagedQuery({
    searchTerm: debouncedSearchValue,
    skip: paginationModel.pageSize * paginationModel.page,
    take: paginationModel.pageSize,
  });

  const corporateGroups = corporateGroupsPaged?.items || [];

  const corporateGroupColumns = [
    {
      field: "name",
      headerName: "Corporate Group",
      flex: 2,
      minWidth: 100,
      renderCell: (params) => {
        return (
          <Link
            to={`/admin/communities/corporateGroups/addCorporateGroups?id=${params.row["id"]}`}
            style={{
              textDecoration: "underline",
              color: theme.palette?.lightBlue?.linkBlue,
            }}
          >
            {params.row["name"]}
          </Link>
        );
      },
    },
    {
      field: "numberOfFacilities",
      headerName: "Number of Communities",
      headerAlign: "center",
      align: "center",
      flex: 2,
      minWidth: 100,
      renderCell: (params) => {
        if (params.row["numberOfFacilities"] === 0) {
          return params.row["numberOfFacilities"];
        } else
          return (
            <Link
              to={`/admin/communities/communitySetup?corporateGroupId=${params.row["id"]}`}
              style={{
                textDecoration: "underline",
                color: theme.palette?.lightBlue?.linkBlue,
              }}
            >
              {params.row["numberOfFacilities"]}
            </Link>
          );
      },
    },
    {
      field: "actions",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 50,
      flex: 1,
      disableClickEventBubbling: true,
      headerName: "",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <BaseActionBox
            index={params.row.id}
            data={corporateGroups}
            hasEdit={true}
            hasDelete={true}
            editProps={{
              To: `addCorporateGroups?id=${params.row.id}`,
            }}
            deleteProps={{
              hintText:
                params.row["numberOfFacilities"] > 0
                  ? "Corporate Groups associated with Communities cannot be deleted."
                  : "",
              entityName: params.row?.name,
              apiPath: "deleteCorporateGroup",
              disabled: params.row["numberOfFacilities"] > 0,
            }}
            childrenLocation={"end"}
          >
            <OptionsButton
              index={params.row.id}
              name={params.row.name}
              hasConsulting={params.row.hasConsulting}
            />
          </BaseActionBox>
        );
      },
    },
  ];

  return (
    <>
      <FlexBox
        justifyContent={"space-between"}
        flexWrap={{ xs: "wrap", md: "nowrap" }}
        my="3px"
      >
        <BaseSearch
          placeholder="Search..."
          disableUnderline={true}
          type="text"
          SEARCH_PARAM={SEARCH_PARAM}
          value={searchValue}
          setSearchValue={setSearchValue}
          setDebouncedValue={setDebouncedSearchValue}
          setSearchParams={setSearchParams}
        />
        <Button
          component={Link}
          to="addCorporateGroups"
          color={"primary"}
          className="baseButton"
          variant="contained"
          endIcon={<PersonAdd />}
        >
          Add Corporate Group
        </Button>
      </FlexBox>
      <BaseDataGrid
        rows={corporateGroups}
        rowCount={corporateGroupsPaged?.totalCount || 0}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        columns={corporateGroupColumns}
        error={corporateGroupsError}
        loading={corporateGroupsLoading}
        autoHeight={false}
        overflow={"none"}
      />
    </>
  );
}

function OptionsButton({ index, name, hasConsulting }) {
  const featureMealExtra = useSelector((state) => state.featureInfo.mealExtra);
  const [mapFlag, setMapFlag] = useState(true);
  useEffect(() => {
    let setActive = featureMealExtra?.enabled == "true";
    if (setActive) {
      setMapFlag(true);
    } else if (!setActive) {
      setMapFlag(false);
    }
  }, [featureMealExtra]);

  return (
    <BaseMenu index={index}>
      {CORPORATE_GROUP_MENU_ITEMS({
        mapFlag,
        hasConsulting,
        index,
        name,
      }).map((item, idx) => {
        return (
          <MenuItem key={idx} component={Link} to={item.link}>
            {item.label}
          </MenuItem>
        );
      })}
    </BaseMenu>
  );
}
