import React from "react";
import { Controller } from "react-hook-form";
import { FormControl, TextField, Typography } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import useSafeFormContext from "hooks/useSafeFormContext";
import { getNestedProperty } from "utils/helperFunctions";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline"],
    ["link"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};

/**
 * @see [Documentation](../../../../Wiki/Documentations/FrontEnd/Components/BaseRichTextEditor.md)
 */
const BaseRichTextEditor = ({ validationProps, control, errors, ...rest }) => {
  const { control: controlMethod, errors: formErrors } = useSafeFormContext({
    control,
    errors,
  });
  const nameOrId = rest?.name || rest?.id;

  const error = getNestedProperty(formErrors, nameOrId);

  const renderQuill = ({ field: { onChange, value } }) => (
    <ReactQuill
      theme="snow"
      modules={modules}
      value={value}
      onChange={onChange}
      style={{ height: "200px" }}
    />
  );

  return (
    <>
      <FormControl sx={{ width: "100%", height: "250px" }}>
        {controlMethod ? (
          <>
            <Controller
              render={renderQuill}
              control={controlMethod}
              name={nameOrId}
              defaultValue=""
              rules={validationProps}
            />
          </>
        ) : (
          <TextField sx={{ width: "100% !important" }} {...rest} />
        )}
      </FormControl>
      <Typography
        sx={{
          color: "#FF3F28",
          fontSize: "0.75rem",
          marginLeft: "14px",
        }}
      >
        {error?.message}
      </Typography>
    </>
  );
};

export default BaseRichTextEditor;
