import { REQUIRED_ERROR } from "utils/errorMessages";
import { FORM_TYPES } from "../../../../../../shared/generatedForm/GeneratedForm";
import { theme } from "styles/theme";
import { DMStyles } from "styles/Styles";

export const EmailField = [
  {
    props: {
      name: "visitReportDescription",
      id: "visitReportDescription",
      label: "Description",
      placeholder: "Description",
      size: "Small",
    },
    validationProps: {
      required: REQUIRED_ERROR("Description"),
    },
    size: 5,
  },
  {
    props: {},
    type: FORM_TYPES.Offset,
    size: 5,
  },
  {
    props: {
      name: "memoTo",
      id: "memoTo",
      label: "Memo to",
      placeholder: "Memo to",
    },
    validationProps: {
      required: REQUIRED_ERROR("Memo to"),
    },
    size: 5,
  },
  {
    props: {
      name: "isConfidential",
      id: "isConfidential",
      label: "Confidential",
    },
    validationProps: {},
    type: FORM_TYPES.Checkbox,
    size: 12,
  },
];

export const RadioGroup = [
  { value: 1, label: "Regulatory" },
  { value: 2, label: "Operational" },
  { value: 3, label: "Both" },
  { value: 4, label: "Others" },
];

export const AddMemo_ScreenNames = [
  {
    id: 1,
    screenName: false,
    bgColor: theme.palette.primary.main,
    fontWeight: "600",
    color: DMStyles.White,
    marginBottom: "5px",
  },
  {
    id: 2,
    screenName: "Add Memo",
    bgColor: theme.palette.orange.main,
    fontWeight: "500",
    color: DMStyles.White,
    marginBottom: "0px",
  },
];
