import React, { useState } from "react"; //{ useEffect }
import { useSearchParams, Link } from "react-router-dom";
import { useGetIngredientUsageQuery } from "../../../../../store/apis/IngredientAPIs";
import { Typography } from "@mui/material";
import BaseDataGrid from "../../../../shared/baseDataGrid/BaseDataGrid";
import BaseSearch from "../../../../shared/baseSearch/BaseSearch";
import YesNoDisplay from "components/shared/YesNoDisplay";

export default function IngredientUsage() {
  const SEARCH_PARAM = "search";
  const [debouncedValue, setDebouncedValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const ingredientId = searchParams.get("id");
  const ingredientName = searchParams.get("name" || "");

  const [searchValue, setSearchValue] = useState("");

  const {
    data: recipeList,
    isError: isError,
    isFetching: isLoading,
  } = useGetIngredientUsageQuery(
    {
      ingredientId: ingredientId,
      searchTerm: debouncedValue || "",
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const RecipeListColumns = [
    {
      field: "recipeName",
      headerName: "Recipe",
      flex: 2,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <>
            <Link
              target={"_blank"}
              rel="noreferrer"
              to={`/plateFul/Recipes/RecipeDetail?recipeId=${params?.row?.recipeId}`}
              style={{ textDecoration: "none", color: "blue" }}
            >
              {params?.row?.recipeName}
            </Link>
          </>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
      minWidth: 300,
    },
    {
      field: "htiRecipe",
      headerName: "HTI Recipe",
      headerAlign: "center",
      flex: 1,
      minWidth: 50,
      renderCell: (params) => {
        return <YesNoDisplay center={true} value={params?.row?.htiRecipe} />;
      },
    },
  ];

  return (
    <>
      <Typography
        variant="h5"
        className="userFacilityTitle"
        sx={{
          color: "primary",
        }}
      >
        Recipes in which {ingredientName} is used
      </Typography>
      <div className="actionsRow">
        <div className="leftActions">
          <BaseSearch
            placeholder="Search..."
            disableUnderline={true}
            type="text"
            sx={{ ml: 1 }}
            SEARCH_PARAM={SEARCH_PARAM}
            value={searchValue}
            setSearchValue={setSearchValue}
            setDebouncedValue={setDebouncedValue}
            setSearchParams={setSearchParams}
            isNameID={true}
            name={ingredientName}
            Id={ingredientId}
          />
        </div>
      </div>
      <BaseDataGrid
        autoHeight={false}
        rows={recipeList}
        columns={RecipeListColumns}
        loading={isLoading}
        error={isError}
        getRowId={(row) => row.recipeId}
        height={{ md: "55vh", xl: "65vh" }}
        sx={{
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
        }}
      />
    </>
  );
}
